.price-table {
    .table {
        border-radius: 5px 5px 0 0;
        min-width: 882px;
        td {
            padding-left: 14px;
            padding-right: 14px;
            vertical-align: middle;
            height: 113px;
        }
        .primary-header {
            th {
                border: 0;
                background-color: $primary-color;
                color: $base-color-2;
                vertical-align: middle;
                font-size: 10px;
                letter-spacing: 0.294118px;
                text-transform: uppercase;
                padding-left: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
                text-align: center;
            }
        }
        .secondary-header th {
            background: #1556c8;
            padding-top: 4px;
            padding-bottom: 4px;
            color: rgba($color: $font-secondary, $alpha: 0.8);
            border: 0;
            font-size: 12px;
            &:nth-child(2) {
                border-right: 1px solid #386dc8;
                box-shadow: inset -1px 0px 0px #1254c7;
            }
        }
        tbody {
            background-color: #fff;
            color: $font-primary;
            text-align: center;
            td {
                border-color: #f5f6f7;
                // &:first-child{
                //     width: 84px;
                // }
            }
            .table-collapse-row {
                td {
                    border: 0;
                }
            }
        }
    }
    //#COLLAPSE
    &__collapse {
        background-color: #f8f9fa;
        padding: 12px 20px;
        &-heading {
            font-size: 10px;
            letter-spacing: 0.294118px;
            text-transform: uppercase;
            font-weight: bold;
        }
        &-col {
            padding-right: 40px;
            // &-1 {
            //     width: 31%;
            //     max-width: 182px;
            //     padding-right: 12px;
            // }
            // &-2 {
            //     width: 44%;
            //     max-width: 265px;
            //     padding-left: 12px;
            //     padding-right: 12px;
            // }
            // &-3 {
            //     width: 24%;
            //     max-width: 218px;
            //     padding-left: 12px;
            //     padding-right: 12px;
            // }
            // &-4 {
            //     width: 12%;
            //     padding-left: 12px;
            //     padding-right: 12px;
            // }
        }
        &-tax {
            padding-right: 15px;
        }
        &-label {
            margin-right: 12px;
        }
        &-value {
            display: flex;
            white-space: nowrap;
            align-items: center;
        }
        &-input {
            background-color: #dfe9f3;
            width: 100%;
            padding: 3px 4px;
            border-radius: 6px;
            input {
                border: 0;
                max-width: 54px;
                font-size: 12px;
                font-weight: bold;
                background-color: transparent;
                color: $font-primary;
            }
        }
        &-data {
            margin-bottom: 4px;
            min-height: 24px;
        }
    }

    &__excess {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.352941px;
        span {
            font-size: inherit;
        }
    }

    &__action {
        display: flex;
        flex-direction: column;
    }
}

.table-col-sticky {
    thead th,
    tbody tr td {
        &:first-child {
            height: 1px;
            position: sticky;
            left: 0;
            padding: 0;
            background-color: $base-color-2;
        }
    }
    .secondary-header {
    }
    .primary-header th {
        &:first-child {
            padding: 0;
        }
        .provider-holder {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    .provider-holder {
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 0 34px 0 15px;
        font-size: inherit;
        @include md {
            padding: 0 15px 0 10px;
        }
        position: relative;
        &:after {
            content: "";
            box-shadow: 6px 0px 7px rgba(20, 46, 75, 0.09);
            display: block;
            position: absolute;
            right: 0;
            width: 10px;
            bottom: 0;
            height: 100%;
            top: 0;
        }
    }
}

.data-table {
    &__row {
        display: flex;
        flex-wrap: wrap;
    }
    //#COL
    &__col {
        padding: 0 6px;
        @include sm-min {
            max-width: 19.3%;
            width: 100%;
            &-five {
                max-width: 19%;
                width: 100%;
            }
            &-four {
                max-width: 17.3%;
                width: 100%;
            }
            &-one {
                max-width: 25%;
                width: 100%;
            }
            // &-action {
            //     margin-top: -37px;
            // }
        }
        @include sm {
            max-width: 50%;
            width: 100%;
            &-xs-100 {
                max-width: 100%;
            }
            &-five {
                order: 10;
            }
        }
    }

    &__header {
        background: $primary-color;
        padding: 13px 20px;
        border-radius: 10px 10px 0 0;
        &-title {
            color: $font-secondary;
            font-size: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        @include sm {
            display: none;
        }
    }
    &__body {
        padding: 25px 20px 15px;
        background: $bg-color;
        margin-bottom: 10px;
        .data-table__col {
            margin-bottom: 20px;
        }
        @include sm {
            border-radius: 4px;
        }
        @include xs {
            padding: 25px 10px 15px;
        }
    }

    //#LABEL
    &__label {
        font-size: 10px;
        letter-spacing: 0.833333px;
        text-transform: uppercase;
        color: $labels-font;
    }
    //#CONTENT
    &__text {
        &-primary {
            font-size: 22px;
            letter-spacing: 0.647059px;
            font-weight: bold;
            @include md {
                font-size: 18px;
            }
        }
        &-secondary {
            font-size: 18px;
            letter-spacing: 0.529412px;
            font-weight: bold;
            @include md {
                font-size: 15px;
            }
        }
        &-sub {
            font-size: 16px;
            font-weight: 500;
        }
    }
    &__action-btn {
        background: #02cd56;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 0.470588px;
        text-transform: uppercase;
        font-weight: bold;
        color: $font-secondary;
        padding: 7px 22px;
        // min-width: 120px;
        &:hover,
        &:focus,
        &:active {
            color: $font-secondary;
            background-color: darken($color: #02cd56, $amount: 5);
            box-shadow: none;
        }
        @include md {
            font-size: 12px;
        }
    }
    &__list {
        li {
            display: flex;
            letter-spacing: 0.411765px;
            font-size: 14px;
            font-weight: bold;
            align-items: baseline;
            text-align: left;
            line-height: 18px;
            margin-bottom: 7px;
            &::before {
                content: "";
                width: 6px;
                height: 6px;
                min-width: 6px;
                margin-right: 4px;
                background-color: #27426c;
                border-radius: 6px;
            }
        }
    }
    &__sub-text {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.352941px;
        font-weight: 500;
    }

    &.webquote {
        .data-table__body {
            margin-bottom: 4px;
        }
    }
}

.searched-item {
    .data-table__body {
        border: 1px solid $primary-color;
        @include sm-min {
            border-top: 0;
        }
    }
}

.primary-table {
    color: $font-primary;
    margin-bottom: 0;
    min-width: 800px;

    &-wrapper {
        box-shadow: 0px 10px 20px rgba(30, 33, 37, 0.117324);
        border-radius: 4px;
    }
    &.table {
        td,
        th {
            border: 1px solid #e4e7ed !important;
            text-align: left;
            padding-left: 30px;
            padding-right: 30px;
        }
        th {
            vertical-align: middle;
            padding-top: 15px;
            padding-bottom: 15px;
            &:first-child {
                width: 35%;
            }
        }
        td {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__header {
        background: #eff3f7;
        &-title,
        &-text {
            text-transform: uppercase;
            display: block;
        }
        &-title {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
        }
        &-text {
            font-size: 10px;
            font-weight: 500;
            line-height: 13.54px;
        }
    }
    &__body {
        &-text {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.352941px;
            display: block;
        }
    }

    &__caption {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.352941px;
        color: $font-primary;
        text-align: left;
        margin-top: 45px;
        margin-bottom: 25px;
    }
}
