/***********************************************************************************************\
#1. Gloabal 
\***********************************************************************************************/
html {
  overflow-x: hidden;
}

html,
body {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // @media (max-width:1258px) {
  //   overflow-x: hidden;
  // }
}

//#Font Rendering
body,
* {
  font-family: $site-font, sans-serif;
}

body {
  background-color: $bg-color;
  color: $font-primary;
  position: relative;
  height: 100%;
  //#SIDEBAR BTN SPACER
  padding-bottom: 40px;

  &.darkBackground {
    background: #224de5;
  }
}

//#SIDERBAR
.sideBarOpened {
  height: 100vh;
  overflow: hidden;
}

//#SITE CONTAINER
.site-container {
  max-width: 1360px;

  &--small {
    max-width: 1200px;
  }
}

.container-770 {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
}

.container-820 {
  max-width: 820px;
  margin-right: auto;
  margin-left: auto;

  @include sm {
    max-width: 440px;
  }
}

/* #BreadCrumb
=================== */
.site-breadcrumb {
  margin-bottom: 22px;
  color: #909eb9;

  .breadcrumb-item {
    font-weight: bold;
    font-size: 12px;
    background: transparent;
    border: 0;
    outline: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    line-height: 1;

    &--font-14 {
      font-size: 14px;
    }

    &--font-18 {
      font-size: 18px;

      @include xs {
        font-size: 14px;
      }
    }

    color: #909eb9;

    // &:hover,
    // &:active,
    // &:focus {
    //   font-weight: 900;
    // }
  }

  .breadcrumb-icon {
    margin-right: 8px;

    img {
      vertical-align: text-bottom;
    }
  }
}

.page-wrapper {
  @include md-min {
    padding-bottom: 60px;
  }

  @include md {
    padding-bottom: 120px;
  }
}

// @include md-min {
//   @include lg {
//     .page-col--right {
//       flex: 0 0 27% !important;
//       min-width: 360px !important;
//       width: 100% !important;
//     }

//     .page-col--left {
//       flex: 0 0 72% !important;
//       max-width: 72% !important;
//     }
//   }
// }

@include md-min {
  .page-col--right {
    flex: 0 0 27% !important;
    max-width: 360px !important;
    width: 100% !important;
    min-width: 360px;
  }

  .page-col--left {
    flex: 0 0 calc(100% - 360px) !important;
    max-width: calc(100% - 360px) !important;
  }

  .page-col--lg-29 {
    flex: 0 0 29% !important;
    max-width: 29% !important;
  }

  .page-col--lg-71 {
    flex: 0 0 71% !important;
    max-width: 71% !important;
  }
}

//#All ScrollBars
.scrollbar-hidden {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
}

.hidescrollbar .drag-scroll-content {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
}

//#Adding Vertical Scroll
.vertical-scroll-hidden {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  // padding-left: 0 !important;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.vertical-scroll-hidden::-webkit-scrollbar {
  display: none;
  height: 0;
}

//#Font Style Defination

//#Vertical Align Table
.vertical-align-table {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical-align__cell {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.events-none {
  pointer-events: none;
}

//spacers
.spacer-50 {
  height: 50px;

  @include md {
    height: 30px;
  }
}

.spacer-40 {
  height: 40px;

  @include md {
    height: 20px;
  }
}

.spacer-20 {
  height: 20px;
}

.spacer-10 {
  height: 10px;
}

.dotted-spacer {
  background-image: linear-gradient(to right, #aebedf 56%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  margin: 30px 0;
  height: 1px;
}

//#TRANSPARENT CUTS
.transparent-cuts {
  position: relative;

  &:after,
  &:before {
    content: "";
    background-color: $bg-color;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: -20px;
  }

  &:before {
    left: -47px;
  }

  &:after {
    right: -47px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

//#STATUS
%status {
  display: inline-block;
  border-radius: 2px;
  font-size: 9px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
}

.status {
  @extend %status;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 20px;
  color: $font-secondary;

  &--success {
    background-color: #42be49;
  }

  &--warning {
    background-color: #f7aa3e;
  }

  &--closed {
    background-color: #828f97;
  }
}

.page-status {
  @extend %status;
  background: $base-color-2;
  border: 1px solid #ebeff4;
  padding-top: 8px;
  padding-bottom: 8px;
}

.table-status {
  @extend .page-status;
  font-size: 12px;
}

.strict {
  //#INPUT CHECK
  position: relative;

  &:before {
    content: "";
    border-left: 11px solid transparent;
    border-bottom: 11px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.markValid {
    &:before {
      border-left-color: $base-color-1;
    }
  }

  &.markNull {
    &:before {
      border-left-color: $labels-font;
    }
  }

  &.markConfirm {
    &:before {
      border-left-color: #f7aa3e;
    }
  }

  &.markInvalid {
    &:before {
      border-left-color: #ff0000;
    }
  }

  &.valid-checker-none {
    &:before {
      display: none;
    }
  }
}

.fieldUpdated {
  background: #fff9e8;
}

.primary-bg {
  background-color: $bg-color;
}

//#FONT COLOR
.primary-font {
  color: $font-primary;
}

.color-base-2 {
  color: $labels-font;
}

//#WHITE ROUNDED BOX
.white-rounded-box {
  box-shadow: 0px 10px 15px #e6ebf0;
  border-radius: 14px;
  background-color: $base-color-2;
  padding: 18px 20px;
}

.info-box {
  border: 1px solid #e1e7ef;
  border-radius: 2px;

  &__area {
    text-align: center;

    &:first-child {
      border-right: 1px solid #e1e7ef;
    }

    &:last-child {
      border-left: 1px solid #e1e7ef;
    }
  }

  &--colored {
    background: linear-gradient(180deg, #ffffff 0%, #f1f3f6 100%);
  }
}

.gray-box {
  background-color: #f8f9fa;
}

.progress-bar {
  height: 6px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  background-color: #e7ecf3;
  position: relative;

  &__fill {
    background-color: $primary-color;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    width: 0;
    transition: width 300ms linear;
  }
}

//#SLIDER
.trade-slider {
  .ngx-slider {
    .ngx-slider-pointer {
      background: radial-gradient(100.93% 100.93% at 50% 50%, #ffffff 0%, #efefef 100%) !important;
      border: 2px solid #fff !important;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
      height: 18px !important;
      width: 18px !important;
      top: -7px !important;

      &:after {
        display: none !important;
      }
    }

    .ngx-slider-bar {
      background-color: #fff !important;
      height: 8px !important;
      border-radius: 6px !important;
      box-shadow: 0px 0px 6px rgba(134, 160, 186, 0.617788) !important;

      &.ngx-slider-selection {
        background-color: rgb(66, 190, 73) !important;
        border-radius: 6px !important;
      }
    }

    .ngx-slider-model-value,
    .ngx-slider-ceil,
    .ngx-slider-floor {
      display: none !important;
    }
  }
}

//#CUSTOM RADIO
.custom-radio {
  &__label {
    display: flex;
    align-items: center;
    font-size: 10px;

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      border: 9px solid #f8f9fa;
      background-color: $primary-color;
      border-radius: 50%;
      transition: border 200ms linear;
      margin-right: 6px;
    }
  }

  &-btn {
    input {
      display: none;

      &:checked ~ .custom-radio__label {
        &:before {
          border: 3px solid #f8f9fa;
        }
      }
    }
  }
}

//#DAY FILTER
.day-filter {
  &__btn {
    background-color: transparent;
    border: 0;
    border-radius: 15px;
    padding: 4px 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;

    &.active {
      background-color: rgba($color: $primary-color, $alpha: 0.3);
    }
  }
}

//USER DETAILS
.user {
  padding: 13px 0;
  border-top: 1px solid #eaeef5;

  &:first-child {
    border-top: 0;
  }

  &__details {
    &-rank {
      color: $primary-color;
    }
  }

  &__face-media {
    border-radius: 50%;
    background-color: $labels-font;
  }

  &-selected {
    background-color: $primary-color;
    margin-left: -22px;
    margin-right: -22px;
    padding: 8px 22px;
    box-shadow: 0px -2px 11px #aebcc9;
  }
}

//#CALENDER
//#CALENDER
.calendar-wrapper {
  border-radius: 6px;
  padding: 20px 22px;
  width: 100%;
  background-color: $base-color-2;
  margin-bottom: 35px;
  box-shadow: 0px 2px 23px #e2e9f0;

  @include xs {
    margin-bottom: 10px;
  }

  ngb-datepicker,
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday,
  .ngb-dp-month {
    width: 100%;
  }

  ngb-datepicker {
    border: 0;
    position: relative;
    margin-bottom: 35px;
  }

  .ngb-dp-header,
  .ngb-dp-weekdays {
    background-color: $base-color-2;
  }

  .ngb-dp-weekdays {
    border-bottom: 0;
    margin-bottom: 15px;
    margin-top: 22px;
  }

  .ngb-dp-weekday {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    color: rgba(52, 72, 94, 0.536062);
    font-weight: bold;
    font-size: 15.6548px;
    font-style: normal;
    visibility: hidden;
    letter-spacing: -4px;

    &:first-letter {
      visibility: visible;
    }
  }

  .btn-light {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 500;
    color: #7c86a2;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
    width: 34px;
    height: 34px;

    &.bg-primary {
      background-color: $primary-color !important;
    }
  }

  ngb-datepicker-navigation {
    display: none;
  }

  .calendar__header-text {
    color: #6b7897;
    letter-spacing: 0.59375px;
    font-weight: 500;
    font-size: 15px;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    height: 2.2rem;
  }
}

.tasklist {
  border-top: 1px solid rgba(182, 184, 189, 0.505095);

  &__title {
    color: $labels-font;
    letter-spacing: 0.59375px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 22px;
  }

  &__point {
    color: #a2abbf;
    font-size: 15px;
    letter-spacing: 0.59375px;
    font-weight: 500;
    margin-top: 10px;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      border-radius: 2px;
      width: 18px;
      height: 15px;
      display: inline-block;
      background-color: #f2f3f3;
      margin-right: 11px;
    }
  }
}

//CLAIMS AND CONVICTIONS SECTION
.claims-con__left-side {
  @include sm {
    flex-wrap: wrap;

    .claims-con__date {
      width: 50%;
    }

    .claims-con__title {
      width: 100%;
    }
  }

  @include sm-min {
    .claims-con__date {
      @include sm-min {
        width: 100%;
        max-width: 65px;
      }
    }
  }
}

.claims-con__name-box {
  min-width: 90px;
  max-height: 32px;
  padding: 3px 12px;
  border-radius: 4px;
  text-align: center;
  min-width: 93px;

  p {
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  &.claim {
    background-color: #ace4f1;
  }

  &.conviction {
    background-color: #ffcc8b;
  }

  &.mediCon {
    background-color: #fd6969;
  }
}

.claims-con {
  &__date {
    &-title {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.352941px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-text {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.352941px;
    }
  }

  &__title-text {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.470588px;
    font-weight: bold;
  }

  &__price {
    // margin-right: 60px;
    margin-right: 30px;

    // @media (max-width: 1199px) {
    //   margin-right: 30px;
    // }

    &-text {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.470588px;
      font-weight: bold;
    }
  }
}

.medical-con-box {
}

.additional-driver {
  @media (max-width: 767px) and(min-width:400px) {
    &__left-side {
      display: flex;
      flex-wrap: wrap;
    }

    &__job {
      width: 50%;
    }
  }

  @media (max-width: 399px) {
    &__job {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }

  &__title {
    font-size: 16px;
    letter-spacing: 0.470588px;
  }

  &__text {
    font-size: 12px;
    letter-spacing: 0.352941px;

    &-details {
      span {
        color: #1563ec;
        font-weight: 600;
      }
    }
  }
}

.time-line-chart {
  &-wrapper {
    @include xs {
      .col-11 {
        max-width: 88%;
      }

      .col-1 {
        max-width: 12%;
      }
    }
  }

  //#HOUR COLUMN
  &__hr-col {
    transform: translateY(32px);

    li {
      height: 54.2px;
      display: flex;
      align-items: center;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      justify-content: center;
      margin-left: -5px;
      transform: scaleY(1.02);
      background-color: #fff;
      font-size: 16px;
      color: #233f6f;
      font-weight: bold;

      &:nth-child(even) {
        background-color: #f8f9fa;
        //   border-top: 1px solid #b7b7b7;
        // border-bottom: 1px solid #b7b7b7;
      }
    }
  }

  div {
    overflow: scroll;
  }

  rect {
    stroke: transparent;
  }

  rect {
    &[fill="#e6e6e6"] {
      fill: #f8f9fa;
    }
  }

  path {
    &[stroke="#ffffff"] {
      stroke: #dfdee7;

      @include sm {
        transform: translateX(-34px);
      }
    }

    &[stroke="#e6e6e6"] {
      stroke: #dfdee7;

      @include sm {
        transform: translateX(-34px);
      }
    }

    &[stroke="#b7b7b7"] {
      stroke: transparent;
    }
  }

  g {
    transform: translateY(31px);

    &:nth-of-type(4) {
      rect {
        height: 20px;
        transform: translateY(6px);

        @include xs {
          transform: translateY(6px) translateX(-34px);
        }
      }
    }

    &:nth-of-type(2) {
      transform: translateY(-79%);
    }
  }

  :nth-child(3) {
    text {
      font-size: 10px;
      transform: translateY(-11px);

      @include xs {
        transform: translateY(-11px) translateX(-30px);
      }
    }
  }

  &--single {
    g {
      &:nth-of-type(1) {
        display: none;
      }

      transform: translateY(15px);

      &:nth-of-type(2) {
        transform: translateY(-44%);
      }
    }
  }
}

$startIndex: 1;
$textNum: 5;
$translate: 12;

//#CART LABELS
g {
  &:nth-of-type(1) {
    text {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      fill: #233f6f;

      @include sm {
        transform: rotate(-90deg);

        &:nth-of-type(3) {
          transform: rotate(-90deg) translateX(9px);
        }

        @for $i from $startIndex through $textNum {
          &:nth-of-type(#{$i}) {
            transform-origin: 4% #{$translate + 0%};
          }

          $translate: $translate + 16;
        }
      }
    }
  }
}

.timelineChart--height {
  height: 50px;
}

//#USER COMMISION BOX
.user-commision {
  &__label {
    color: $labels-font;
    font-size: 10px;
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  &__price {
    font-size: 14px;
    letter-spacing: 0.411765px;
    font-weight: bold;
    line-height: 15px;
  }
}

.overflow-handler {
  overflow: auto;
  padding-bottom: 12px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  // &--overlay{
  //   position: relative;
  //   &:after{
  //     content: '';
  //     width: 18px;
  //     height: 100%;
  //     display: block;
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     background: linear-gradient(90deg, transparent -17%,#ffffff 22%);
  //   }
  // }
}

/* ====#PAGE LOADER==== */
.page-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-221deg, #ffffff 0%, #f8f9fa 40%);
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  overflow: hidden;
}

.page-loader:before {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  background: url("../../img/ripple.svg") no-repeat center;
  background-size: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.page-loader.loaded {
  opacity: 0;
  visibility: hidden;
}

//#CONTENT BOX
.contnet-box {
  @include box-module;

  @include xs-min {
    &-sm-min {
      // @extend .box-module;
      @include box-module;
    }
  }

  @include xs {
    &-sm {
      @include box-module;
    }
  }
}

//#NO RESULT FOUND SECTION
.no-result {
  text-align: center;

  &__title {
    font-size: 20px;
    letter-spacing: 0.588235px;
    font-weight: bold;
    margin-top: 30px;
  }

  &__sub-title {
    font-size: 16px;
    letter-spacing: 0.470588px;
  }
}

//#TOOLTIP
[data-tip] {
  position: relative;
}

[data-tip]:before {
  display: none;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1a1a1a;
  position: absolute;
  top: -6px;
  left: 9px;
  width: 0;
  height: 0;
}

[data-tip]:after {
  display: none;
  content: attr(data-tip);
  position: absolute;
  top: -31px;
  left: 0px;
  padding: 5px 8px;
  background: #1a1a1a;
  color: #fff;
  z-index: 9;
  font-size: 10px;
  border-radius: 3px;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
  display: block;
}

/* ====#END PAGE LOADER==== */

/* =================================
        #POLICY BOX
  ==================================== */
.insurance-renewal-section-box {
  margin-bottom: 30px;
  /*   padding-top: 14px;
    padding-bottom: 18px; */
  padding: 28px 25px 31px;
  min-height: 129px;
  height: 100%;
  /*   margin-right: 10px;
    margin-left: 10px; */
  display: block;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 15px #b6d7ff;
  box-shadow: 0px 2px 15px #b6d7ff;
  border-radius: 10px;
}

@media (min-width: 992px) {
  .insurance-renewal-section-box {
    /*     margin-right: 0;
      margin-left: 0; */
    max-width: 360px;
    width: 100%;
    margin-right: 30px;
  }

  .insurance-renewal-section-box:nth-child(even) {
    margin-right: 0;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .insurance-renewal-section-box {
    margin-right: 10px;
    margin-bottom: 12px;
  }

  .insurance-renewal-section-box:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .insurance-renewal-section-box {
    max-width: 325px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .insurance-renewal-section-box {
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 20px;
    padding: 28px 25px 31px;
  }

  .insurance-renewal-section-box:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {
  .insurance-renewal-section-box {
    padding: 18px 15px 24px;
  }
}

.insurance-renewal-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 14px;
  background-color: #1966ff;
  position: relative;
}

.insurance-renewal-icon-media {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  max-height: 16px;
  max-width: 26px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.insurance-renewal-title {
  margin-bottom: 20px;
  margin-top: 0;
}

.insurance-renewal-title .insurance-renewal-title--main {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3px;
}

.insurance-renewal-title .insurance-renewal-title--main .insurance-renewal-title--icon {
  margin-left: 11px;
}

.insurance-renewal-title .insurance-renewal-title--main .insurance-renewal-title--icon img {
  vertical-align: baseline;
}

.insurance-renewal-title .insurance-renewal-title--main-26 {
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  color: #000;
}

.insurance-renewal-title .insurance-renewal-title--sub {
  font-size: 11px;
  font-weight: 700;
  color: #909eb9;
  margin-top: 0;
  line-height: 13px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .insurance-renewal-title .insurance-renewal-title--sub {
    font-size: 10px;
  }
}

.overview-number-main-text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #000;
  font-weight: 500;
}

@media (max-width: 767px) {
  .overview-number-main-text {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.230189px;
    text-transform: uppercase;
    color: #909eb9;
    margin-top: 4px;
    font-weight: bold;
  }
}

.overview-number-sub-text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  color: #000000;
  font-weight: bold;
  margin-top: 3px;
}

@media (max-width: 767px) {
  .overview-number-sub-text {
    color: #909eb9;
  }
}

.insurance-renewal-info-left-text {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.insurance-renewal-info-right-text {
  font-size: 18px;
  font-weight: 700;
  color: #3662ff;
}

.insurance-renewal-section-box.recent-quotes-slide {
  max-width: 100% !important;
  margin: 10px 10px;
  border-radius: 10px;
  background-color: #fff;
  min-height: 129px;
  height: 100%;
  max-height: 160px;
}

.insurance-renewal-section-box.recent-quotes-slide:focus,
.insurance-renewal-section-box.recent-quotes-slide:hover {
  outline: none !important;
}

//#LOADERS
.section-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 300ms linear;
  min-height: 200px;
  margin-bottom: 50px;
  &.loaded {
    opacity: 0;
    z-index: -1;
    position: absolute;
    left: -100%;
    height: 0;
    width: 0;
  }
}
$dot: "0.";
.section-loader__object {
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: $primary-color;
    margin: 0 3px;
    animation: dotsLoader 0.6s linear infinite;

    @for $i from 0 to 3 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$dot} + $i + s;
      }
    }
  }
}

.secondary-box {
  background: #edf0f2;
  padding: 30px 25px;
  margin: 0 -25px;
}
/* =================================
          #POLICY BOX
    ==================================== */

/*******************************************************************************************************************************************************************************************************\

                                           END OF DO NOT TOUCH THIS CODE

\********************************************************************************************************************************************************************************************************/
