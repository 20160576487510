.main-payment {
    .payment-box {
        @include lg-min {
            padding: 22px 70px;
        }
        @include xs-min {
            @include lg {
                padding: 22px 40px;
            }
        }
        @include xs {
            padding: 22px 22px;
        }
    }
    .payment-wrapper {
        &__row {
            margin-left: 0;
            margin-right: 0;
        }
        &__col {
            padding-left: 0;
            padding-right: 0;
            background-color: $base-color-2;
            @include sm-min {
                &:first-child {
                    border-right: 1px solid #e1e4ed;
                    border-radius: 14px 0 0 14px;
                }
                &:nth-child(2) {
                    border-radius: 0 14px 14px 0;
                }
            }
            @include sm {
                margin-bottom: 20px;
                border-radius: 14px;
            }
        }
    }

    .payment-header {
        margin-bottom: 32px;
        text-align: center;
        &__title {
            font-size: 24px;
            font-weight: bold;
        }
        &__sub-title {
            font-size: 14px;
            font-weight: 500;
        }
        &__footer {
            font-size: 10px;
            color: #909eb9;
        }
    }
}
/* =========================
        #PAYMENT STATUS
=========================== */
.payment-status {
    &-wrapper {
        max-width: 447px;
        margin-left: auto;
        margin-right: auto;
    }
    &-box {
        position: relative;
        padding: 29px;
        background: $base-color-2;
        box-shadow: 0px 7px 11px rgba(164, 174, 183, 0.272072);
        border-radius: 4px;
        z-index: 2;
        @include xs {
            padding: 30px 20px;
        }
    }

    //#HEADER
    &__icon {
        width: 70px;
        height: 70px;
        margin: auto;
        border: 5px solid #f8f9fa;
        border-radius: 50px;
        position: absolute;
        top: -35px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include xs {
            width: 55px;
            height: 55px;
            svg {
                width: 20px;
            }
        }
        &.success {
            background: linear-gradient(0deg, #02cd56, #02cd56),
                linear-gradient(226.75deg, #3662ff 18.26%, #1966ff 86.02%);
        }
        &.error {
            background: linear-gradient(0deg, #ff4646, #ff4646);
        }
    }
    &__message {
        &-title {
            font-size: 28px;
            font-weight: bold;
        }
        &-sub-title {
            font-size: 14px;
            font-weight: 500;
        }
    }

    &__footer {
        &-action {
            &-primary,
            &-secondary {
                @include xs-min {
                    width: 48%;
                }
                color: $font-secondary !important;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: bold;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.152808);
                border-radius: 3px;
                min-height: 50px;
                padding: 9px;
            }
            &-primary {
                background: #1966ff;
                &:hover,
                &:active,
                &:focus {
                    background: darken($color: #1966ff, $amount: 5);
                }
            }
            &-secondary {
                background: #02cd56;
                background: darken($color: #02cd56, $amount: 5);
            }
        }
    }
}

//#COMMISION BOX
.payment-commision {
    &-box {
        padding: 30px 0;
        background: #d3e2ff;
        border-radius: 3px;
    }
    &__right,
    &__left {
        width: 50%;
        padding: 0 30px;
        @include xs {
            padding: 0 20px;
        }
    }
    &__right {
        border-left: 1px solid #fff;
    }
    &__left {
        border-right: 1px solid #b6ccf6;
    }
}

.payment-label {
    font-size: 14px;
    color: #909eb9;
    font-weight: 500;
}

.payment-container {
    min-height: 100vh;
}
