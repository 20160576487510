/************************************************************************************************************\
Accordion Plus Minus Icons
\************************************************************************************************************/

//#INDIDUAL STATS COLLAPSE
.user-individual-stats {
    &__header {
        @include lg {
            background-color: $primary-color;
            padding: 12px 16px;
            border-radius: 4px;
            * {
                color: $font-secondary;
            }
        }

        @media (max-width: 360px) {
            .user__face-media {
                width: 30px;
                height: 30px;
            }
        }

        .dropdown-toggle {
            transform: rotate(180deg);
            transition: transform 300ms linear;
        }
        &[aria-expanded="true"] {
            .dropdown-toggle {
                transform: rotate(0deg);
            }
            .header-chart {
                display: none !important;
            }
        }
        &[aria-expanded="false"] {
            .user-individual-stats__box-wrapper {
                display: none !important;
            }
        }
    }
    &__item {
        @include lg-min {
            background-color: $base-color-2;
            padding: 9px 23px 20px;
            border-radius: 8px;
        }
    }

    &__body {
        background-color: #fff;
        border-radius: 0 0 6px 6px;
        .fullIndividualsStats-wrapepr {
            @include lg-min {
                padding-top: 60px;
            }
            @include lg {
                padding-top: 15px;
            }
        }
    }

    //#STATS BOX
    &__box {
        padding: 16px;
        border-radius: 2px;
        @include lg {
            background-color: $bg-color;
            border-radius: 2px;
            width: calc(50% - 10px);
            margin-bottom: 20px;
        }

        &-wrapper {
            @include lg-min {
                background-color: $bg-color;
                border-radius: 2px;
            }
            @include lg {
                max-width: 400px;
                flex-wrap: wrap;
                margin-left: auto;
                margin-right: auto;
                padding: 16px;
            }
        }
        &-label {
            color: $labels-font;
            font-size: 9px;
            line-height: 11px;
            text-transform: uppercase;
        }
        &-text {
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
        }
    }
}

.primary-tab {
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(24, 29, 59, 0.122);
    // overflow: hidden;
    &__btn {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        min-width: 120px;
        padding: 10px;
        border-radius: 0;
        &:first-child {
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
        }
        &:not(.active) {
            color: $font-primary;
            // box-shadow: 0px 2px 15px rgba(24, 29, 59, 0.174388);
            border-right: 1px solid #f1f4f7;
            background-color: $font-secondary;
        }
        &:active,
        &:hover,
        &:focus {
            color: $font-primary;
            // box-shadow: 0px 2px 15px rgba(24, 29, 59, 0.154388);
            background-color: #e7eff7;
            box-shadow: none;
        }
        &.active {
            color: rgba($color: $font-secondary, $alpha: 0.8);
            background-color: $primary-color;
        }
    }
}

.filter-buttons-wrapper {
    overflow-x: scroll;
    display: flex;
    padding: 10px;
    @include md {
        .primary-tab {
            flex-wrap: nowrap;
        }
    }
}
