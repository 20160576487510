.site-box {
  padding: 20px 22px;
}

.site-box--rounded {
  border-radius: 10px;
}

.primary-box {
  @extend .site-box;

  @media (max-width: 360px) {
    padding: 20px 13px;
  }

  background-color: #fff;
}

.secondary-box {
  @extend .site-box;
  background-color: #f6faff;
}

.primary-box-title {
  font-size: 30px;
  color: #3e537e;
  font-weight: bold;
  margin-bottom: 0;

  @media (max-width: 575px) {
    font-size: 21px;
  }
}

.primary-box-title-icons {
  margin-right: 17px;
  align-items: center;
  display: flex;

  @media (max-width: 575px) {
    max-width: 36px;
    margin-right: 13px;

    svg {
      width: 100%;
    }
  }
}

.primary-box-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.primary-box-title-sub {
  font-size: 12px;
  color: #909eb9;
}

.primary-box-bottom-buttons {
  margin-top: 21px;

  @media (max-width: 479px) {
    .custom-back {
      display: inline-block !important;
    }
  }
}

.primary-box-bottom-buttons--right {
  @media (min-width: 480px) {
    display: flex;
    justify-content: flex-end;
  }
}

//INPUTS
.update-reg-input-fields {
  border: 1px solid #f3f5f7 !important;
}

.primary-box--bottom-padding {
  padding-bottom: 30px;

  @media (max-width: 360px) {
    padding: 20px 13px;
  }
}

@media (max-width: 479px) {
  .cancelation-buttons .custom-back {
    display: inline-block !important;
  }
}

.confirm-vehicle-options {
  padding-top: 0;
  height: auto;

  .options {
    top: 0;
  }

  label {
    margin-bottom: 0;
  }
}

.effective-date-select {
  border: 1px solid #f3f5f7 !important;
  box-shadow: 4px 6px 13px rgba(65, 96, 132, 0.0745301);
}

.mta-forms {
  margin-top: 45px;
  padding-bottom: 180px;

  .table-claims {
    td,
    th {
      border: 0;
      padding: 0;
    }
  }

  .table-claims.form-group thead tr th {
    background: transparent;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    line-height: 26px;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #182546;
  }

  .table-claims.form-group thead {
    border-bottom: 0;
  }

  .table-claims {
    background: rgba(255, 255, 255, 0.370726);
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.0303442);
    padding: 20px;

    table {
      margin-bottom: 0;
    }
  }

  .table-claims.form-group th {
    font-size: 13px;
    font-weight: 900px;
  }

  .table-claims.form-group td {
    background: transparent;
    padding: 0 10px;
    border-bottom: none;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #182546;

    .price_claims {
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 15px;
      letter-spacing: 0.501428px;
      margin-right: 10px;
      color: #182546;
    }
  }

  td.table-action-btn span {
    width: 30px;
    height: 16px;
  }

  td.table-action-btn.action-btns-table {
    float: right;
  }

  .edit-table-btn {
    margin-right: 20px;
    line-height: 22px;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #1563ec;
  }

  .action-btns-table img {
    height: 16px;
    cursor: pointer;
  }

  .white-date-select {
    border: 1px solid #f3f5f7;
    box-shadow: 4px 6px 13px rgba(65, 96, 132, 0.0745301);
    display: flex;
    position: relative;

    > * {
      flex: 1;
    }

    .bootstrap-select .btn.dropdown-toggle {
      min-height: 63px;
      padding-left: 20px;
      padding-right: 30px;
      box-shadow: none !important;
      border-radius: 0;

      &:before {
        content: "";
        display: block;
        pointer-events: none;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 5px solid #a6aec2;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -4px;
        z-index: 3;
      }

      &:after {
        content: "";
        display: block;
        pointer-events: none;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid #a6aec2;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: 4px;
        z-index: 3;
      }
    }

    .bootstrap-select {
      border-right: 1px solid #e6eff7;
      width: 100%;
      position: static;
    }

    .filter-option {
      text-align: left;
      float: left;
      width: 100%;
      font-weight: 500;
    }

    .remove-border-right {
      border-right: none !important;
    }
  }

  .white-select-box {
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.071);
    border-radius: 3px;
    padding: 17px !important;
    border: 1px solid #f3f5f7;

    min-height: 65px;
    background: #fff;
    color: #182546;
    font-weight: 500;
    font-size: 15px;
    text-transform: none;
    white-space: nowrap;
    text-align: left;

    &:after {
      content: "";
      background-image: url("../../img/dropdown-icon-solid.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border: 0;
      padding: 7px;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(180deg);
      transition: transform 300ms linear;
      position: absolute;
      right: 20px;
      width: 0;
      height: 0;
      display: block;
      pointer-events: none;
    }

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(0deg) !important;
      }
    }

    &--border {
      border: 1px solid #f3f5f7 !important;
    }

    .filter-option {
      text-align: left;
    }

    &-wrapper {
      width: 100%;
    }

    &-label {
      margin-bottom: 10px;
    }
  }

  .white-auto-complete {
    .white-select-box {
      font-size: 15px;
      font-weight: 500;
    }

    .auto-complete-box {
      li {
        a {
          font-weight: 500;
        }
      }
    }
  }

  .bs-placeholder {
    color: #a6aec2;
  }

  .font-bold {
    font-weight: bold;
  }

  .color-dark-gray {
    color: #3e537e;
  }

  .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-right: 5px;
    border: none;
    box-shadow: 4px 14px 32px 0 #4160842e;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #e6eff7;
    overflow: scroll;
    max-height: 306px;

    &.open {
      display: block;
    }

    ul {
      li {
        a {
          color: #182546;
          padding: 5px 20px 5px 25px;
          font-size: 15px;
          margin: 5px;
          border-radius: 5px;
          display: block;
          border-radius: 5px;
          font-weight: 500;
        }

        cursor: pointer;
        display: block;

        &.selected,
        &:hover,
        &:active {
          a {
            background: #dcecff;
          }
        }

        &.selected {
          font-weight: bold;
        }
      }

      // &.date-pick {
      //     max-height: 306px;
      // }
    }

    .date-pick {
      max-height: 306px;
      margin: 0;

      li {
        padding: 0;
        position: relative;

        a {
          padding: 10px 0 !important;
          text-align: center;
        }
      }
    }

    ul::-webkit-scrollbar,
    .auto-complete-box::-webkit-scrollbar,
    .pop::-webkit-scrollbar {
      width: 6px;
    }

    ulr::-webkit-scrollbar-track,
    .auto-complete-box::-webkit-scrollbar-track,
    .pop::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent;
    }

    ul::-webkit-scrollbar-thumb,
    .auto-complete-box::-webkit-scrollbar-thumb,
    .pop::-webkit-scrollbar-thumb {
      background-color: lightgrey;
      outline: none;
      border-radius: 10px;
      height: 50px;
    }
  }

  .month_btn .date-pick {
    //     display: grid !important;
    //    // grid-template-columns: 50% 50%;
    //    grid-template-rows: repeat(6, 1fr);
    //     grid-auto-flow: column;
    //     grid-auto-columns: 50% 50%;
    li a {
      padding: 10px 0 !important;
      text-align: center;
    }

    li {
      padding: 0px;

      &:nth-child(odd) {
        border-right: 1px solid #e4e4e4;
      }
    }
  }

  .options input {
    display: none;
  }

  .custom-options .options {
    margin-right: 15px;
  }

  .options-group > *:last-child {
    margin-right: 0;
  }

  // .options-group.custom-options {
  //     padding-top: 15px;
  // }
  .options-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .custom-options .options label {
    border: 1px solid #6f7689;
    padding: 15px 50px;
    border-radius: 5px;
    text-transform: uppercase;

    height: 51px;
    font-weight: 900;
    font-size: 15px;

    @include max-375 {
      padding: 18px 40px;
    }
  }

  .no-add-driver {
    height: auto !important;
  }

  .custom-options .options label:before,
  .custom-options .options label:after {
    visibility: hidden;
  }

  .custom-options .options input[type="radio"]:checked ~ label {
    color: #fff;
    background: #1966ff;
    border: 1px solid #1966ff;
    border-radius: 3px;
  }

  //LESS TEHN 768
  @media (max-width: 767px) {
    //#RADIO BUTTONS
    .options-group.custom-options {
      padding-top: 0 !important;

      .options {
        top: 0 !important;
      }

      label {
        margin-bottom: 0 !important;
      }
    }

    //MULTI SELECT BOX
    .select-box-group {
      .select-box {
        label {
          &[for="select-box3"] {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .registration-search {
    background-image: url("../../img/board.svg"), url("../../img/icon-search.svg");
    background-color: #fff;
    background-repeat: repeat-y, no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    font-family: uknumber;
    font-size: 2em;
    text-transform: uppercase;
    background-size: 28px, 15px;
    background-position: left center, 95% center;
    // line-height: 65px;
    color: #182546;

    &:focus {
      border: none;
      outline: none;
      box-shadow: 4px 14px 32px 0 #4160842e;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: #a6aec2;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a6aec2;
    }

    &::placeholder {
      color: #a6aec2;
    }
  }

  .load {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/spinner-preloader.svg");
  }

  .cancel {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/cancel.svg");
  }

  .checked {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/checked.svg");
  }

  .loading-status {
    background-image: url("../../img/spinner-preloader.svg");
  }

  .success-status {
    background-image: url("../../img/checked.svg");
  }

  .error-status {
    background-image: url("../../img/cancel.svg");
  }

  ul.auto-complete-box {
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    width: 100%;
    -webkit-padding-start: 0;
    border-radius: 0 0 3px 3px !important;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
    z-index: 4;
    box-sizing: border-box;
    border-top: 1px solid #dcecff;
    border-right: 5px solid white;
  }

  ul.auto-complete-box li a {
    margin: 5px;
    border-radius: 5px;
  }

  ul.auto-complete-box li a:hover {
    background: #dcecff !important;
    color: #000 !important;
    font-weight: bold !important;
  }

  .auto-complete-main {
    position: relative;
    padding-right: 5px;
  }

  ul.auto-complete-box a {
    color: #6f7689 !important;
    font-size: 15px !important;
    display: -webkit-box;
  }

  ul.auto-complete-box li a {
    padding: 5px 20px 5px 25px;
    color: #182546 !important;
  }

  ul.auto-complete-box a:hover {
    color: #182546 !important;
  }

  .auto-complete input {
    min-height: 63px;
  }
}

.error_msg {
  color: #e60808;
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: 13px;
  font-weight: 600;
}

.box-error {
  border: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;

  &--dashed {
    border-style: dashed !important;
  }
}

.box-error-left {
  border-left: 1px solid #e60808 !important;
  border-top: 1px solid #e60808 !important;
  border-bottom: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
}

.box-error-center {
  border-top: 1px solid #e60808 !important;
  border-bottom: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
}

.box-error-right {
  border-right: 1px solid #e60808 !important;
  border-top: 1px solid #e60808 !important;
  border-bottom: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
}

//INPUT SPACER
.inputs-margin-bottom-20 {
  margin-bottom: 20px;
}

.inputs-margin-bottom-md-20 {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.inputs-margin-top-20 {
  margin-top: 20px;
}

@media (max-width: 479px) {
  button.btn.btn-grey.btn-lg.btn-prev.custom-back {
    display: none;
  }
}

.vc-get-quote-form-button {
  .btn-next {
    position: relative;
    text-align: left;
    min-width: 180px;
    padding: 21px 25px;
    font-weight: 900;
    background-color: #1563ec !important;
    box-shadow: none !important;
    border: 0;
    font-size: 12px;
    position: relative;
    text-transform: uppercase;

    .btn-arrow {
      position: absolute;
      top: 45%;
      right: 25px;
      display: block;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color: #1563ec, $amount: 5) !important;
    }
  }

  .btn-grey.btn-lg {
    background-color: rgba(166, 174, 194, 0.22);
    color: #6f7689 !important;
    padding: 21px 30px;
    min-width: 170px;
    box-shadow: none !important;
    border: 0;
    font-size: 12px;
    position: relative;
    text-transform: uppercase;
    font-weight: 900;
  }

  @media (max-width: 479px) {
    .btn {
      display: block;
      min-width: 100% !important;
    }

    .btn + .btn {
      margin-top: 10px;
    }
  }
}

.usage-info-wrapper {
  margin-bottom: 30px;

  .usage-info-wrapper-title,
  .usage-info-wrapper-main {
    font-weight: bold;
    color: #3e537e;
    line-height: 24px;
  }

  .usage-info-wrapper-title {
    font-size: 14px;
  }

  .usage-info-wrapper-main {
    font-size: 20px;
    margin-top: 0;
  }
}

.mta-modal {
  .payment-modal {
    .modal-dialog,
    .modal-content {
      height: 100%;
    }

    .modal-dialog {
      height: 100%;
      max-height: 700px;
    }
  }

  .vc-acceptance-criteria .well {
    margin-bottom: 0;
    border-radius: 4px;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out 0s;
    padding: 40px;
  }

  .well a {
    font-weight: 600;
  }

  .vc-sec2-wells .well-item {
    background: #fff;
    border-radius: 4px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 15px 35px 0 rgba(0, 0, 0, 0.02);
    height: 385px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .vc-sec2-wells .well-item:after {
    content: "";
    display: block;
    width: 100%;
    height: 30px;
    background: #fff;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 3;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  }

  .vc-sec2-wells .well-item .well-item-img-wrap {
    height: 260px;
    background: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  }

  .vc-sec2-wells .well-item .well-item-img {
    display: block;
    background: #182546;
    height: 260px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  }

  .vc-sec2-wells .well-item .well-item-text {
    padding: 20px 40px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 385px;
    position: absolute;
    margin: auto;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
    z-index: 2;
    background: #fff;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .vc-sec2-wells .well-item .well-item-text .btn {
    width: 200px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 40px;
  }

  .vc-sec2-wells .well-item:hover,
  .vc-sec2-wells .well-item:focus,
  .vc-sec2-wells .well-item:active {
    box-shadow: 0 40px 60px 0 rgba(0, 0, 0, 0.05);
  }

  .vc-sec2-wells .well-item:hover:after,
  .vc-sec2-wells .well-item:focus:after,
  .vc-sec2-wells .well-item:active:after {
    opacity: 0;
  }

  .vc-sec2-wells .well-item:hover .well-item-img-wrap,
  .vc-sec2-wells .well-item:focus .well-item-img-wrap,
  .vc-sec2-wells .well-item:active .well-item-img-wrap {
    opacity: 0;
  }

  .vc-sec2-wells .well-item:hover .well-item-img,
  .vc-sec2-wells .well-item:focus .well-item-img,
  .vc-sec2-wells .well-item:active .well-item-img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .vc-sec2-wells .well-item:hover .well-item-text,
  .vc-sec2-wells .well-item:focus .well-item-text,
  .vc-sec2-wells .well-item:active .well-item-text {
    -webkit-transform: translateY(-260px);
    transform: translateY(-260px);
    border-radius: 4px;
    padding-top: 50px;
  }

  &.cc_modal .vc-acceptance-criteria .well {
    background-color: #f8f9fa;
  }

  .pop::-webkit-scrollbar {
    width: 6px;
  }

  .pop::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background: transparent;
  }

  .pop::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    outline: none;
    border-radius: 10px;
    height: 50px;
  }

  .pop {
    width: 100%;
    // height: 600px;
    border: 0;
  }

  &.modal-open .modal {
    background-color: rgba(35, 44, 66, 0.722);
    display: block;
    z-index: 10000;
  }

  @media (min-width: 768px) {
    .modal.modal-vc-your-quote .modal-dialog {
      max-width: 600px;
    }
  }

  @media (min-width: 992px) {
    .modal.modal-vc-your-quote .modal-dialog {
      width: 90%;
      max-width: 1100px;
    }
  }

  @media (min-width: 992px) {
    &.cc_modal .modal.modal-vc-your-quote .modal-dialog {
      width: 90%;
      max-width: 850px;
    }
  }

  .modal.modal-vc-your-quote .modal-body {
    padding: 0;
  }

  .modal.modal-vc-your-quote .close span {
    color: #b1b3b7;
    opacity: 1;
    font-weight: 700 !important;
    font-size: 1.5em;
  }

  .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading {
    line-height: 40px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #182546;
  }

  .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading span {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgba(25, 102, 255, 0.05);
    text-align: center;
    font-size: 13px;
    font-weight: 900;
    color: #1966ff;
    margin-right: 15px;
    position: relative;
    top: -4px;
  }

  .vc-acceptance-criteria .vc-acceptance-criteria-item {
    margin-top: 40px;
  }

  .list-dots {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .vc-acceptance-criteria .well p {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.9;
    color: #2e4468;
  }

  .pop {
    height: 600px;
    overflow-y: auto;
  }

  .list-dots li {
    font-size: 13px;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
    margin-bottom: 10px;
    letter-spacing: 0.7px;
    color: #2e4468;
  }

  .list-dots li:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #050f28;
    position: absolute;
    top: 10px;
    left: 0;
  }

  @media (min-width: 1200px) {
    .vc-acceptance-criteria .vc-acceptance-criteria-item {
      margin-top: 60px;
    }
  }

  @media (min-width: 768px) {
    .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading {
      line-height: 44px;
      margin-bottom: 30px;
      font-size: 24px;
    }

    .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading span {
      width: 44px;
      height: 44px;
      line-height: 44px;
    }
  }

  @media (max-width: 991px) {
    .modal .modal-dialog {
      margin-top: 40px !important;
    }
  }

  .modal .modal-dialog {
    margin-top: 20px;
  }

  @media (min-width: 768px) and (min-height: 700px) {
    .modal .modal-dialog {
      width: 700px;
      margin-top: 5%;
    }
  }

  @media (min-width: 992px) {
    .modal .modal-dialog {
      width: 780px;
    }

    .make_modal .modal .modal-dialog {
      width: 550px;
    }
  }

  .terms-body {
    overflow-y: hidden;
  }

  .terms-body .pop {
    padding: 1em 0 33px;
  }

  .pop {
    // height: 600px;
    overflow-y: auto;
  }

  &.cc_modal .terms-body {
    background-color: #f8f9fa;
    border-radius: 5px;
  }

  &.cc_modal .modal-content {
    border-radius: 5px !important;
  }

  .modal .modal-content .close {
    font-weight: 300;
    color: #fff;
    position: absolute;
    opacity: 0.7;
    top: -30px;
    right: 0;
    z-index: 2;
    transition: all 0.2s ease-in-out 0s;
    text-shadow: none;
  }

  .modal .modal-content .close:hover,
  .modal .modal-content .close:focus,
  .modal .modal-content .close:active {
    outline: none;
    opacity: 1;
  }

  @media (min-width: 768px) {
    .modal .modal-content .close {
      color: #444444;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .modal-header h3 {
    font-weight: 500;
    font-size: 24px;
  }

  .group {
    max-height: 507px;
    height: auto;
    overflow-y: auto;
    padding-top: 20px;
    padding: 20px 40px 0px 40px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    .well-padding {
      padding: 0px 0px !important;
    }

    .group {
      padding: 20px 20px 0px 20px;
    }
  }

  @media (max-width: 360px) {
    .cc_modal .pop_modals {
      background-color: #f8f9fa;
    }
  }

  .common-save {
    padding: 0.7em 1.5em !important;
    color: #fff;
    font-size: 16px !important;
    font-weight: 900;
    border-radius: 3px;
    text-transform: uppercase;
    background: #1563ec;
    border: none;
  }

  #addAnotherConviction {
    margin-bottom: 0px;
  }

  .right-padding-0 {
    padding-right: 0 !important;
  }
}

.mta-forms-primary {
  margin-top: 45px;
  padding-bottom: 80px;

  .vc-get-quote-heading {
    margin-bottom: 40px;
  }

  .accordion-btn {
    width: 100%;
    z-index: 2;
    background-color: #fff;
    box-shadow: none !important;
    background-position: 98% center;
    background-repeat: no-repeat;
    background-size: 25px;
    display: block;

    .name-label {
      text-transform: capitalize;
      line-height: normal;
      font-size: 24px;
      font-weight: bold;
      color: #1966ff;
      white-space: normal;
    }

    .position {
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;
    }

    .edit_driver {
      line-height: normal;
      font-size: 16px;
      text-decoration-line: underline;
      color: #1966ff;
      text-transform: capitalize;
    }

    .delete-add-driver {
      visibility: visible;
      display: inline-block;
      cursor: pointer;
      margin: 0;
      width: 16px;
      height: 16px;
      margin-right: 0;
      margin-left: 10px;
      background: url("/assets/img/delete.png") no-repeat 50%;
    }

    .btn-accordion {
      width: 100%;

      @include sm-min {
        padding: 12px 30px;
      }

      @include sm {
        padding: 12px 20px;
      }
    }
  }

  .three_grid,
  .six_grid {
    margin-left: 0;
    margin-right: 0;
  }

  .six_grid li {
    padding: 0;
  }

  .three_grid li a,
  .six_grid li a {
    padding: 10px 0 !important;
    text-align: center;
  }

  .table-claims {
    td,
    th {
      border: 0;
      padding: 0;
    }
  }

  .table-claims.form-group thead tr th {
    background: transparent;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    line-height: 26px;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #182546;
  }

  .table-claims.form-group thead {
    border-bottom: 0;
  }

  .table-claims {
    background: rgba(255, 255, 255, 0.370726);
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.0303442);
    padding: 20px;

    table {
      margin-bottom: 0;
    }
  }

  .table-claims.form-group th {
    font-size: 13px;
    font-weight: 900px;
  }

  .table-claims.form-group td {
    background: transparent;
    padding: 0 10px;
    border-bottom: none;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #182546;

    .price_claims {
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 15px;
      letter-spacing: 0.501428px;
      margin-right: 10px;
      color: #182546;
    }
  }

  td.table-action-btn span {
    width: 30px;
    height: 16px;
  }

  td.table-action-btn.action-btns-table {
    float: right;
  }

  .edit-table-btn {
    margin-right: 20px;
    line-height: 22px;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #1563ec;
  }

  .action-btns-table img {
    height: 16px;
    cursor: pointer;
  }

  .common-save {
    padding: 0.7em 1.5em !important;
    color: #fff;
    background: transparent;
    font-size: 16px !important;
    font-weight: 900;
    border-radius: 3px;
    font-family: "brandon";
    text-transform: uppercase;
    background: #1966ff;
    border: none;
  }

  font.back {
    font-size: 15px;
    color: #1966ff;
  }

  .common-save:focus {
    outline: -webkit-focus-ring-color auto 0 !important;
  }

  .save-entry {
    margin-right: 1em;
    margin-bottom: 50px;
  }

  .postal-code {
    text-transform: uppercase;
  }

  .common-status {
    background-size: 26px;
    background-position: 95% center;
    background-repeat: no-repeat;
  }

  @media (min-width: 1200px) {
    .vc-get-quote-heading {
      margin-bottom: 80px;
    }
  }

  .vc-get-quote-heading .heading1 {
    color: #182546;
    margin-bottom: 20px;
    font-size: 33px;
    font-weight: 400;
  }

  .vc-get-quote-form-item {
    margin-bottom: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(84, 107, 166, 0.1);
  }

  @media (min-width: 768px) {
    .vc-get-quote-form-item {
      padding-bottom: 20px;
      margin-bottom: 55px;
    }
  }

  @media (min-width: 992px) {
    .vc-get-quote-form-item .vc-get-quote-form-item-inner {
      max-width: 635px;
      margin: 0 auto;
    }

    .custom-inner .vc-get-quote-form-item-inner {
      max-width: 620px;
    }
  }

  .vc-get-quote-form-item:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .vc-get-quote-form-item .vc-get-quote-form-heading {
    line-height: 40px;
    margin-bottom: 40px;
    font-size: 20px;
    color: #182546;
    font-weight: 400;
  }

  .vc-get-quote-form-item .vc-get-quote-form-heading span {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgba(25, 102, 255, 0.05);
    text-align: center;
    font-size: 13px;
    font-weight: 900;
    color: #1966ff;
    margin-right: 15px;
    position: inherit;
    /*relative Rayner*/
    top: -4px;
  }

  @media (min-width: 768px) {
    .vc-get-quote-form-item .vc-get-quote-form-heading {
      line-height: 44px;
      margin-bottom: 50px;
      font-size: 24px;

      &.claims_header {
        margin-bottom: 25px !important;
      }

      label {
        margin-top: -15px;
        padding-left: 65px;
      }
    }

    .vc-get-quote-form-item .vc-get-quote-form-heading span {
      width: 44px;
      height: 44px;
      line-height: 44px;
      font-size: 15px;
    }

    .foreditdriver .vc-get-quote-form-item .vc-get-quote-form-heading span.form-info {
      width: 12px;
      height: 20px;
      line-height: 20px;
      font-size: 15px;
      background-color: transparent;
    }
  }

  @media (min-width: 480px) {
    .vc-get-quote-form-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .input-multi > * {
    margin-bottom: 10px;
  }

  @media (min-width: 500px) {
    .input-multi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
      transition: all 0.2s ease-in-out 0s;
      position: relative;
      z-index: 4;
      border-radius: 3px;
    }

    .input-multi > * {
      margin-bottom: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0;
      border: 1px solid transparent !important;
      border-radius: 0 !important;
      border-right: 1px solid rgba(166, 174, 194, 0.15) !important;
    }

    .input-multi > *:first-child {
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
    }

    .input-multi > *:last-child {
      border-right: 0 !important;
      border-top-right-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }

    .input-multi > *.fg2 {
      -webkit-box-flex: 2;
      -ms-flex: 2 1 0px;
      flex: 2 1 0;
    }

    .input-multi .form-control {
      box-shadow: none;
    }

    .input-multi .form-control:focus {
      box-shadow: none;
    }

    .input-multi .btn {
      min-width: auto !important;
      max-width: 120px;
    }
  }

  @media (min-width: 768px) {
    .multi-custom {
      width: 50%;
      display: inline-block !important;
    }

    .multi-custom .fg-half {
      width: calc(100% - 10px) !important;
      max-width: 100% !important;
    }

    .form-group-multi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .form-group-multi > div {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-right: 20px;
    }

    .form-group-multi > div:last-child {
      margin-right: 0;
    }

    .form-group-multi > div.fg2 {
      -webkit-box-flex: 1.5;
      -ms-flex: 1.5 1 0px;
      flex: 1.5 1 0;
    }
  }

  .month_btn .date-pick {
    //     display: grid !important;
    //    // grid-template-columns: 50% 50%;
    //    grid-template-rows: repeat(6, 1fr);
    //     grid-auto-flow: column;
    //     grid-auto-columns: 50% 50%;
    li a {
      padding: 10px 0 !important;
      text-align: center;
    }

    li {
      padding: 0px;

      &:nth-child(odd) {
        border-right: 1px solid #e4e4e4;
      }
    }
  }

  @media (min-width: 1200px) {
    .form-group-multi > div {
      margin-right: 40px;
    }

    .form-group-multi > div:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 768px) {
    .form-group-multi > .fg-half {
      width: 50%;
      max-width: 50%;
      padding-right: 20px;
    }
  }

  @media (min-width: 768px) {
    .form-group-multi > .fg-half.fg-right {
      padding-right: 0;
      padding-left: 20px;
      margin-left: auto;
    }
  }

  label {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #182546;
    margin-bottom: 10px;
    display: block;
    z-index: 1 !important;
    max-width: 100%;
  }

  input[type="text"].form-control,
  input[type="password"].form-control,
  input[type="email"].form-control,
  input[type="number"].form-control,
  select {
    height: 65px;
    min-height: 65px;
  }

  button.btn.btn-primary.btn-lg {
    background: #1563ec;
    box-shadow: none !important;
    padding: 16px 30px;
    min-width: 150px;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .form-control {
    color: #182546;
  }

  .form-control {
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #182546;
    border: none;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07);
    -webkit-transition: border-color ease-in-out 0s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0s, box-shadow ease-in-out 0.15s;
  }

  .form-control::-webkit-input-placeholder {
    color: #a6aec2;
  }

  .form-control:-moz-placeholder {
    color: #a6aec2;
  }

  .form-control::-moz-placeholder {
    color: #a6aec2;
  }

  .form-control:-ms-input-placeholder {
    color: #a6aec2;
  }

  .form-control:focus,
  .form-control:active {
    border: none;
    outline: none;
    box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18);
  }

  .form-control,
  .select-control {
    border-radius: 3px;
  }

  .form-control.success,
  .select-control.success {
    border: 1px solid #02cd56 !important;
    color: #02cd56 !important;
  }

  .form-control.error,
  .select-control.error {
    border: 1px solid #ed1c24 !important;
    color: #ed1c24 !important;
  }

  .form-control:disabled,
  .select-control:disabled {
    color: #a6aec2;
    background: #f1f6fa;
    box-shadow: none;
  }

  .form-control,
  select {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.2px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 768px) {
    .form-control,
    select {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media (min-width: 500px) {
    .postal-house,
    .postal-pin {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: 500px) {
    .vc-get-quote-steps-indicator li:after {
      width: 40px;
      right: -20px;
    }

    .postal-error {
      display: none;
    }

    .postal-house .error_msg,
    .postal-pin .error_msg {
      margin: 5px 0;
    }

    .vc-your-quote-more-info .vc-your-quote-more-info-top .vc-your-quote-more-info-top-number .number-item p {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .vc-get-quote-steps-indicator li:after {
      width: 15px;
      right: -7px;
    }
  }

  .form-info {
    display: inline-block;
    width: 16px;
    height: 12px;
    background: url("../../img/icon-info.svg") no-repeat center;
    background-size: 12px;
    line-height: 1;
    text-indent: -999999px;
    margin-left: 5px;
    margin-top: 4px;
    cursor: pointer;
  }

  //#HELP INFO ALIGNMENT
  .help-info-icon-alignment {
    position: relative;

    .form-info {
      position: absolute;
    }
  }

  .form-group {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    .form-group {
      margin-bottom: 50px;
    }
  }

  .tooltip-description {
    display: block !important;
  }

  .tooltip-description {
    border: 1px solid #7c89ab;
    padding: 1em;
    border-radius: 3px;
    background: #f4f9ff;
    color: #7c89ab;
    margin-top: 10px;
    display: block;
    opacity: 1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 100% !important;
    margin-left: 0 !important;
    background-color: #eff1f7;
    border-color: #d5d6de;
  }

  p.tooltip-head {
    color: #7c89ab;

    font-size: 17px;
    font-weight: 900;
  }

  p.tooltip-head span {
    height: 17px;
    margin-right: 5px;
  }

  p.toolrip-text {
    font-size: 16px;
    margin-top: 5px;
    font-weight: 400;
  }

  p.toolrip-text b {
    font-weight: 500;
    font-size: 17px;
  }

  .bootstrap-select {
    width: 100% !important;
    border-radius: 3px;
  }

  .bootstrap-select.error {
    border: 1px solid #ed1c24 !important;
    color: #ed1c24 !important;
  }

  .bootstrap-select button:before {
    content: "";
    display: block;
    pointer-events: none;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 5px solid #a6aec2;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -4px;
    z-index: 3;
  }

  .bootstrap-select button:after {
    content: "";
    display: block;
    pointer-events: none;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid #a6aec2;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 4px;
    z-index: 3;
  }

  .select-multi .bootstrap-select > .dropdown-toggle {
    padding-left: 20px !important;
  }

  .bootstrap-select > .dropdown-toggle {
    padding-right: 30px !important;
  }

  .bootstrap-select .btn.dropdown-toggle {
    min-height: 65px !important;
    background: #fff;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07);
    color: #182546;
    font-weight: 500 !important;
    font-size: 15px !important;
    text-transform: none !important;
    white-space: nowrap;
    text-align: left;
  }

  .bootstrap-select.dropup .dropdown-menu {
    top: 100% !important;
    bottom: auto !important;
    margin: 0 !important;
  }

  .bootstrap-select > .dropdown-menu {
    border: none !important;
    box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
    margin: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 1px solid #e6eff7 !important;
  }

  .bootstrap-select > .dropdown-menu li a {
    color: #182546 !important;
    padding: 5px 20px 5px 25px;
    font-size: 15px !important;
    margin: 5px;
    border-radius: 5px;
  }

  .selected a {
    background: #dcecff !important;
    font-style: normal !important;
    // line-height: normal !important;
    color: #000000 !important;
    font-weight: bold !important;
  }

  .date_picker a {
    padding: 10px 20px 10px 10px !important;
  }

  .bootstrap-select > .dropdown-menu li a:hover,
  .bootstrap-select > .dropdown-menu li a:focus {
    background: rgba(220, 236, 255, 0.4) !important;
  }

  .bootstrap-select > .dropdown-menu li a:active {
    background: #dcecff !important;
    color: #000 !important;
    font-weight: bold;
  }

  .options-group {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    .options-group {
      height: 63px;
    }
  }

  .options-group > * {
    margin-right: 40px;
    position: relative;
    top: 4px;
  }

  .options-group > *:last-child {
    margin-right: 0;
  }

  .options p {
    display: inline-block;
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #3a5273;
    margin-right: 15px;
    font-weight: 400;
  }

  .options input {
    display: none;
  }

  .options > label {
    display: inline-block;
    position: relative;
    padding-left: 17px;
    padding-bottom: 1px;
    cursor: pointer;
    color: rgba(28, 38, 68, 0.63);
    line-height: 1;
    font-size: 18px;
    letter-spacing: -0.5px;
  }

  .options > label:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    margin-top: -3px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out 0s;
  }

  .custom-options .options label {
    border: 1px solid #6f7689;
    padding: 18px 50px;
    border-radius: 5px;
    text-transform: uppercase;
    height: 51px;
    font-weight: 900;
    font-size: 15px;

    @include max-375 {
      padding: 18px 40px;
    }
  }

  .no-add-driver {
    height: auto !important;
  }

  .custom-options .options label:before,
  .custom-options .options label:after {
    visibility: hidden;
  }

  .custom-options .options input[type="radio"]:checked ~ label {
    color: #fff;
    background: #1966ff;
    border: 1px solid #1966ff;
    border-radius: 3px;
  }

  label.no-add-driver.btn.btn-outline-blue.btn-lg.add-driver.add-driver-btn {
    letter-spacing: 0.5px !important;
  }

  .no-add-driver input[type="radio"]:checked ~ label {
    color: #fff !important;
    background: #6f7689;
    border: 1px solid #6f7689;
    border-radius: 3px;
  }

  .custom-options .options {
    margin-right: 15px;
  }

  .bootstrap-select > .dropdown-toggle {
    width: 100%;
    padding-right: 25px;
    z-index: 1;
  }

  .bootstrap-select > .dropdown-toggle.bs-placeholder,
  .bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
  .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
  .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #a6aec2;
  }

  .options-group.custom-options {
    padding-top: 15px;
  }

  @media (min-width: 768px) {
    .helphiddendesk {
      display: none !important;
    }
  }

  @media (max-width: 767px) {
    .helphiddenmobile,
    .helphiddenmobiletwo {
      display: none !important;
    }

    .mobdesk {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .select-box label {
      height: 110px;
    }

    .select-box-content svg {
      height: 30px;
    }
  }

  .helphiddenmobile {
    margin-top: -26px !important;
    margin-bottom: 40px !important;
  }

  .mobdesk {
    margin-top: -26px !important;
    margin-bottom: 20px !important;
    margin-left: 1.7em;
    width: 92%;
  }

  .container-fluid {
    max-width: 1110px;
  }

  @media (min-width: 992px) {
    .form-group-btns .btn {
      min-width: 120px !important;
    }

    .tooltip-description {
      max-width: 635px;
      width: 100% !important;
      margin: 20px auto !important;
    }
  }

  .helphiddenmobile {
    margin-top: -2em !important;
    margin-bottom: 40px !important;
  }

  //#QFA
  .vc-get-quote-info-text-wrap {
    min-height: 200px;
  }

  .vc-get-quote-info-text {
    text-align: center;
  }

  .vc-get-quote-info {
    width: 300px;
    float: right;
    padding-right: 15px;
    padding-left: 15px;
  }

  .vc-get-quote-info.aos-animate {
    -webkit-transform: none !important;
    transform: none !important;
  }

  @media (max-width: 991px) {
    /*Custom-Step Counter MObile*/
    .mobile-progress-custom {
      width: 100%;
    }

    .progress-step-section .mobile-header-custom {
      display: block;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li:after,
    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li span.step-text {
      display: none;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li .step-number {
      height: 35px;
      width: 35px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li:nth-child(1) .step-number {
      position: absolute;
      z-index: 2;
      left: -30px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li:nth-child(2) .step-number {
      position: absolute;
      z-index: 1;
      left: -15px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator {
      margin-bottom: 0;
      position: relative;
      right: 20px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li .step-number {
      margin-bottom: 0;
      line-height: 35px;
    }

    .mobile-header-custom {
      display: none;
    }

    .vc-get-quote-info {
      margin-top: 60px;
      display: none;
      visibility: hidden;
    }
  }

  .vc-get-quote-info .vc-get-quote-info-affix {
    display: inline-block;
    max-width: 300px;
    min-width: 270px;
    background: #182546;
    color: #fff;
    border-radius: 3px;
  }

  .vc-get-quote-info .vc-get-quote-info-top {
    margin-left: auto;
    /*padding-top: 40px;
      padding-bottom: 45px;
      padding-left: 85px;
      padding-right: 40px;*/
    position: relative;
    background-color: #1966ff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .vc-get-quote-info .vc-get-quote-info-top img {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .vc-get-quote-info .vc-get-quote-info-top h4 {
    font-size: 9px;
    font-weight: 900;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.64);
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .vc-get-quote-info .vc-get-quote-info-top p {
    font-size: 21px;
    font-weight: 700;
    font-style: normal;
    color: #ffffff;
    line-height: 1.4;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .vc-get-quote-info .vc-get-quote-info-top p {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    .vc-get-quote-info .vc-get-quote-info-top p {
      font-size: 16px;
    }

    .innerLoaderText {
      width: 100%;
    }

    .error-page-text {
      width: 97%;
    }

    .innerLoaderText p {
      margin-top: 2em;
    }
  }

  .vc-get-quote-info .vc-get-quote-info-inner {
    border-radius: 3px;
    background-color: #222c43;
    box-shadow: 0 36px 64px 0 rgba(78, 80, 84, 0.13);
    color: #fff;
    padding: 0px 20px;
    padding-top: 0;
    height: 395px;
  }

  .vc-get-quote-info .vc-get-quote-info-text:last-child {
    margin-top: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vc-get-quote-info .vc-get-quote-info-text h3 {
    font-size: 14px;
    font-weight: 900;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
  }

  .vc-get-quote-info .vc-get-quote-info-text p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-weight: lighter;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 140px;
  }

  @media (min-width: 600px) and (max-width: 991px) {
    .vc-get-quote-info-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .vc-get-quote-info-content .vc-get-quote-progress {
      margin-right: 40px;
      width: 183px;
      min-width: 183px;
      height: 183px;
      margin-bottom: 0;
    }
  }

  .custom-vc-get-quote {
    padding: 1em;
    text-align: center;
  }

  .vc-get-quote-progress {
    /* margin: 26px auto; */
    width: 190px;
    height: 107px;
    position: relative;
    margin-top: 5px;
  }

  .vc-get-quote-progress:before {
    content: "Complete";
    font-size: 20px;
    font-weight: 600;
    color: #d5dae7;
    position: absolute;
    margin: auto;
    left: 5em;
    right: 0;
    bottom: 50px;
    text-align: left;
    margin-left: 5px;
    letter-spacing: 0.6px;
    white-space: normal;
  }

  .vc-get-quote-progress:before {
    letter-spacing: 0px;
    font-weight: 400;
  }

  .responsive > svg {
    width: 45% !important;
    height: 45% !important;
  }

  .parking-box-main,
  .van-purchased-main {
    margin-bottom: 20px;
  }

  .select-box-group label {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    .select-box-group .form-group-multi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .select-box-group .form-group-multi > div {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0;
      margin-right: 20px;
    }

    .vc-your-quote-adjustments .form-group-multi > div:last-child {
      margin-right: auto;
    }

    .select-box-group .form-group-multi > div:last-child {
      margin-right: 0;
    }

    .select-box-group .form-group-multi > div.fg2 {
      -webkit-box-flex: 1.5;
      -ms-flex: 1.5 1 0px;
      flex: 1.5 1 0;
    }
  }

  @media (min-width: 1200px) {
    .select-box-group .form-group-multi > div {
      margin-right: 20px;
    }

    .select-box-group .form-group-multi > div:last-child {
      margin-right: 0;
    }
  }

  .select-box input {
    display: none;
    visibility: hidden;
  }

  .select-box label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    padding: 10px;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.03);
    border-bottom: 6px solid #fff;
    text-align: center;
    height: 155px;
    position: relative;
  }

  .select-box label:before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    background: url("../../img/icon-check3.svg") no-repeat center;
    background-size: 22px;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.2s ease-in-out 0s;
    opacity: 0;
  }

  .select-box label:hover,
  .select-box label:focus,
  .select-box label:active {
    cursor: pointer;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07);
  }

  .select-box label .select-box-content svg {
    transition: all 0.2s ease-in-out 0s;
  }

  .select-box label .select-box-content p {
    font-size: 12px;
    font-weight: 400;
    margin-top: 20px;
  }

  .select-box input:checked + label {
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.09);
    border-bottom: 6px solid #1966ff;
    color: #050f28;
  }

  .select-box input:checked + label:before {
    opacity: 1;
  }

  .select-box input:checked + label .icon-van-parked1 g {
    fill: #050f28;
  }

  .select-box input:checked + label .icon-van-parked1 .key {
    stroke: #050f28 !important;
  }

  .select-box input:checked + label .icon-van-parked1 .circ {
    fill: #fff;
  }

  .select-box input:checked + label .icon-van-parked1 .circ rect {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked2 g {
    fill: #050f28;
  }

  .select-box input:checked + label .icon-van-parked2 .lines {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 g {
    fill: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .car-copy {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .lines {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .parking path {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .parking .p {
    stroke: none;
  }

  .parking-box .form-group,
  .van-purchased .form-group {
    margin-bottom: 0;
  }

  .parking-box-main,
  .van-purchased-main {
    margin-bottom: 20px;
  }

  .select-multi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
    transition: all 0.2s ease-in-out 0s;
    position: relative;
  }

  .select-multi > * {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-right: 5px;
    border: none;
    box-shadow: 4px 14px 32px 0 #4160842e;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #e6eff7;
    overflow: scroll;
    max-height: 306px;

    &.open {
      display: block;
    }

    ul {
      li {
        a {
          color: #182546;
          padding: 5px 20px 5px 25px;
          font-size: 15px;
          margin: 5px;
          border-radius: 5px;
          display: block;
          border-radius: 5px;
          font-weight: 500;
        }

        cursor: pointer;
        display: block;

        &.selected,
        &:hover,
        &:active {
          a {
            background: #dcecff;
          }
        }

        &.selected {
          font-weight: bold;
        }
      }

      // &.date-pick {
      //     max-height: 306px;
      // }
    }

    .date-pick {
      max-height: 306px;
      margin: 0;

      li {
        padding: 0;
        position: relative;

        a {
          padding: 10px 0 !important;
          text-align: center;
        }
      }
    }

    ul::-webkit-scrollbar,
    .auto-complete-box::-webkit-scrollbar,
    .pop::-webkit-scrollbar {
      width: 6px;
    }

    ul::-webkit-scrollbar-track,
    .auto-complete-box::-webkit-scrollbar-track,
    .pop::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent;
    }

    ul::-webkit-scrollbar-thumb,
    .auto-complete-box::-webkit-scrollbar-thumb,
    .pop::-webkit-scrollbar-thumb {
      background-color: lightgrey;
      outline: none;
      border-radius: 10px;
      height: 50px;
    }
  }

  .select-multi .bootstrap-select {
    border-right: 1px solid #e6eff7;
    position: static;
  }

  .select-multi .bootstrap-select .btn.dropdown-toggle {
    box-shadow: none !important;
    border-radius: 0;
  }

  .remove-border-right {
    border-right: none !important;
  }

  button.bradius-left.btn.dropdown-toggle.bs-placeholder.btn-default,
  button.bradius-left.btn.dropdown-toggle.btn-default {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  button.bradius-right.btn.dropdown-toggle.bs-placeholder.btn-default {
    border-top-right-radius: 3px !important;
  }

  button.bradius-right.btn.dropdown-toggle.btn-default {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .stickAssistant {
    width: 270px;
    position: fixed;
    top: 150px;
    z-index: inherit;
  }

  .progressbar-text {
    font-size: 25px !important;
    font-weight: 700;
  }

  .progressbar-text:after {
    content: "%";
  }

  @media (max-width: 767px) {
    .helphiddenmobile,
    .aboutjobhelp,
    .helphiddenmobiletwo {
      display: none !important;
    }

    .abouthelp {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .abouttrade {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .mobdesk {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .select-box label {
      height: 110px;
    }

    .select-box-content svg {
      height: 30px;
    }
  }

  .registration-search {
    background-image: url("../../img/board.svg"), url("../../img/icon-search.svg");
    background-color: #fff;
    background-repeat: repeat-y, no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    font-family: uknumber;
    font-size: 2em;
    text-transform: uppercase;
    background-size: 28px, 15px;
    background-position: left center, 95% center;
    // line-height: 65px;
    color: #182546;

    &:focus {
      border: none;
      outline: none;
      box-shadow: 4px 14px 32px 0 #4160842e;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: #a6aec2;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a6aec2;
    }

    &::placeholder {
      color: #a6aec2;
    }
  }

  .load {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/spinner-preloader.svg");
  }

  .cancel {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/cancel.svg");
  }

  .checked {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/checked.svg");
  }

  .loading-status {
    background-image: url("../../img/spinner-preloader.svg");
  }

  .success-status {
    background-image: url("../../img/checked.svg");
  }

  .error-status {
    background-image: url("../../img/cancel.svg");
  }

  .thisismyvan {
    color: #fff;
    background: #1563ec;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid #1563ec !important;
    width: 100% !important;
    padding: 21px 30px;
    min-width: 170px;
    font-weight: bold;
  }

  .thisismyvan:hover,
  .thisismyvan:active:hover,
  .thisismyvan:active,
  .thisismyvan:focus {
    background: #1563ec !important;
    color: #fff !important;
  }

  .van-active {
    background-color: #1563ec !important;
    color: #fff !important;
  }

  .van-active span:before {
    background: url("../../img/check-thick.svg") no-repeat center;
    content: "tick";
    color: transparent;
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }

  .help-alignment {
    //MULTI SELECT BOX
    @media (min-width: 768px) {
      .select-box-group {
        .tooltip-description {
          margin-top: 0 !important;
        }
      }

      #parked-overnight {
        .error_msg {
          margin-bottom: 10px !important;
          margin-top: -10px !important;
        }
      }
    }

    //LESS TEHN 768
    @media (max-width: 767px) {
      //#RADIO BUTTONS
      .options-group.custom-options {
        padding-top: 0 !important;

        .options {
          top: 0 !important;
        }

        label {
          margin-bottom: 0 !important;
        }
      }

      //MULTI SELECT BOX
      .select-box-group {
        .select-box {
          label {
            &[for="select-box3"] {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .header-help-info {
    @media (max-width: 320px) {
      margin-top: -10px !important;
    }
  }

  .vc-get-quote-form {
    float: left;
    padding-bottom: 80px;
  }

  .input-money {
    position: relative;
  }

  .input-money:before {
    content: "£";
    display: block;
    pointer-events: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(25, 102, 255, 0.05);
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    line-height: 32px;
    color: #30406a;
  }

  .input-money input {
    padding-left: 67px;
  }

  .driver-form-aligner {
    @media (min-width: 1200px) {
      margin-left: 57px;
    }
  }

  .form-preview {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 6px;

    .name-label {
      font-size: 18px;
      color: #1563ec;
      font-weight: bold;
    }

    @media (min-width: 1200px) {
      padding: 30px;
    }

    @media (max-width: 1199px) {
      padding: 20px;
    }

    @media (min-width: 576px) {
      .name-label {
        font-size: 20px !important;
      }
    }

    .position {
      color: #000 !important;
      font-size: 18px !important;
      font-style: italic;

      @media (min-width: 768px) {
        border-left: 1px solid rgba(49, 49, 49, 0.351644);
        margin-left: 8px;
        padding-left: 8px;
      }

      @media (max-width: 767px) {
        display: block;
      }
    }

    .address_marital,
    .licence_status,
    &__title {
      font-size: 18px !important;
      color: #000 !important;
      font-weight: 500 !important;
    }

    //#ACTION BUTTON
    &__action-btn {
      border: 0;
      background: transparent;
      padding: 0;
      color: #3662ff;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: -27px;
      display: block;
      margin-left: auto;

      @media (max-width: 575px) {
        font-size: 15px;
      }
    }

    &__namelist {
      margin-top: 5px;

      .name-label {
        margin-top: 0;
      }
    }
  }

  .license_address span {
    display: block;
  }

  @media (max-width: 500px) {
    .position {
      display: block;
    }

    .license_address {
      margin-top: 8px !important;
    }
  }

  @media (min-width: 1200px) {
    .container-fluid--width-xl {
      max-width: 1150px;
    }
  }

  //#primary-form
  .primary-form {
    background-color: #fff;
    border-radius: 6px;
    margin-top: 30px;

    .about-you-button {
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      padding: 30px;
    }

    @media (max-width: 1199px) {
      padding: 20px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #a2bfdf;
      padding-bottom: 30px;

      &-left {
        display: flex;
        align-items: center;
      }

      &-title {
        font-weight: bold;
        font-size: 26px;
        color: #182546;
        margin-left: 14px;
        margin-bottom: 0;
      }

      @media (max-width: 575px) {
        &-title {
          font-size: 20px;
          margin-left: 12px;
        }

        &-icon {
          max-width: 35px;
        }
      }
    }

    //#BUTTONS
    &__transparent-btn {
      border: 0;
      background-color: transparent;
      color: #3662ff;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }

    //#ELEMETS ALIGNMENTS
    @media (max-width: 1199px) and (min-width: 992px) {
      //#RADIO BUTTONS
      .custom-options .options label {
        padding: 18px 48px;
      }

      //#DATE PICKER
      .select-multi .bootstrap-select > .dropdown-toggle {
        padding-left: 12px !important;
      }

      .bootstrap-select > .dropdown-toggle {
        padding-right: 27px !important;
      }
    }

    @media (max-width: 575px) {
      //#DATE PICKER
      .select-multi .bootstrap-select > .dropdown-toggle {
        padding-left: 12px !important;
      }

      .bootstrap-select > .dropdown-toggle {
        padding-right: 27px !important;
      }
    }
  }

  .foradditionaldriver,
  .foreditdriver {
    margin-top: 61px;
  }

  .change-driver-col {
    @media (max-width: 575px) {
      padding: 0;
    }
  }

  .yourtick {
    display: inline;
  }

  .tick-radio:before,
  .tick-radio:after {
    border-radius: 3px !important;
  }

  .tick-radio {
    font-size: 25px !important;
    bottom: 2px;
  }

  .tick-radio:after {
    content: "✓" !important;
    color: #1966ff;
    transition: none !important;
    margin-top: -13px !important;
    background: transparent !important;
  }

  .cust-rad input:checked + label:before {
    background: #fff !important;
  }

  .cust-rad input:checked + label:after {
    left: 2px !important;
  }

  .options > label:after {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: #fff;
    margin-top: -3px;
    opacity: 0;
    border-radius: 50%;
    transition: all 0.2s ease-in-out 0s;
  }

  .options input:checked + label:after {
    opacity: 1;
    width: 6px;
    height: 6px;
    top: 7px;
    left: 7px;
  }

  .terms-label {
    font-size: 15px !important;
    font-weight: 500;
    margin-left: 10px;
  }

  textarea.form-control {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (min-width: 768px) {
    textarea.form-control {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .uploader-wrapper {
    &--transparent {
      #dropZone,
      .file-drop-wrapper,
      .upload-box {
        background: transparent !important;
      }
    }

    // cursor: pointer;
    #fileDrop {
      overflow: visible !important;
    }

    .file-drop-wrapper {
      padding: 0 !important;
    }

    .custom-drag {
      border: 0 !important;
    }

    file-drop {
      padding: 0 !important;
    }

    file-preview-item,
    #fileDrop,
    .custom-drag {
      max-width: 100% !important;
    }

    file-preview-item {
      margin-top: 10px;
    }

    file-preview-item {
      margin-top: 10px;
      background: rgba(255, 255, 255, 0.370726);

      .file-preview-title {
        p {
          text-align: left;
        }
      }
    }

    file-preview-container {
      background: transparent;
      cursor: default;
    }

    #dropZone {
      padding: 0px !important;
      box-shadow: 4px 6px 13px 0 rgba(66, 97, 133, 0.071);
      border-radius: 4px;
    }

    //#check icons
    .ngx-checkmark-wrapper {
      width: 28px !important;
      height: 28px !important;

      .ngx-checkmark {
        width: 100% !important;
        height: 100% !important;

        &:after {
          height: 14px !important;
          width: 7px !important;
          border-width: 0 2px 2px 0 !important;
          top: 5px !important;
          left: 11px !important;
        }
      }
    }

    //#CLOSE ICON
    .ngx-close-icon-wrapper {
      height: 28px !important;
      width: 28px !important;

      close-icon {
        .ngx-close-icon {
          width: 100% !important;
          height: 100% !important;

          &:after,
          &:before {
            top: 10px !important;
            width: 18px !important;
            left: 2px !important;
          }
        }
      }
    }

    refresh-icon {
      position: absolute;
      min-width: 150px;
      width: 100%;
      min-height: 50px;
      background: red;
      left: 39px;
      margin-right: 0 !important;
      font-family: "Brandon";
      font-weight: bold;
      padding: 0 6px;
      border-radius: 2px;
      color: #fff;

      @media (max-width: 991px) {
        left: -106px;
        top: 50px;
      }

      &:before {
        content: "Opps !";
        display: block;
        font-size: 12px;
        font-weight: bold;
      }

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid red;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 991px) {
          top: -14px;
          left: 62%;
          right: 0;
          text-align: center;
          transform: rotate(90deg);
          margin: auto;
        }
      }

      .upload-refresh-icon {
        transform: rotateZ(0deg) !important;
        font-family: "Brandon";
        font-size: 10px;
        width: 100%;
        border: 0;
        margin: 0 !important;
        color: #fff;

        &:hover {
          color: #fff;
        }

        &:before {
          content: "Something went wrong" !important;
          display: inline-block;
          transform: rotate(0);
          width: 75%;
          position: relative;
          border: 0;
          left: auto;
          right: auto;
          top: auto;
          height: 100%;
          pointer-events: none;
        }

        &:after {
          content: "Retry";
          display: inline-block;
          text-decoration: underline;
          border: 0;
        }
      }
    }

    .file-upload-error-wrapper {
      height: 100% !important;
    }

    .file-preview-thumbnail {
      margin: auto;
    }

    .file-upload-percentage-wrapper,
    .file-upload-progress-bar,
    .file-upload-progress-bar-wrapper {
      height: 100% !important;
      border-radius: 0 !important;
    }

    //#FILE UPLOAD PERCENTAGE
    .file-upload-percentage {
      padding-top: 28px !important;
      padding-right: 61px !important;

      @media (max-width: 380px) {
        padding: 42px 31% 0 0 !important;
        font-size: 16px !important;
      }
    }
  }

  //#Upload
  .upload-box {
    background: #fff;
    border-radius: 3px;
    min-height: 26px;
    height: 100%;
    max-height: 180px;
    position: relative;
  }

  .upload-box__file {
    height: 100%;
    width: 100%;
    // opacity: 0;
    display: none !important;
  }

  .upload-box__lable {
    // position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    // top: 50%;
    // transform: translateY(-50%);
    color: #a6aec2;
    font-size: 15px;
    margin-bottom: 0;
    cursor: pointer;

    &-title {
      font-size: 14px;
      letter-spacing: 0.0875px;
      color: rgba(111, 118, 137, 0.6);
    }
  }

  .upload-box__lable--bluetext {
    color: #1c64f6;
  }

  .upload-box__lable-icon {
    display: block;

    img {
      animation: upload 2s ease-in-out infinite alternate;
    }
  }

  @keyframes upload {
    0% {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
    }

    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  .upload-info__text {
    color: #a6aec2;
    font-size: 15px;
    font-weight: bold;
    margin-top: 4px;
  }

  .upload-box-help {
    margin-top: 35px;
  }

  .upload-box-help-list {
    margin-top: 13px;

    p {
      margin: 0;
    }
  }

  .file-uploading {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
      opacity: 0.9;
      background: url("../../img/loader.gif") #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 27px;
    }

    refresh-icon ~ .ngx-close-icon-wrapper {
      background: green !important;
    }
  }

  .uploader-wrapper {
    position: relative;

    .dropzoneTemplate {
      padding: 20px 0;
      cursor: pointer;
    }
  }

  //#VERIFY FILES
  .verify-filesupload {
    #fileDrop {
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }

    .file-drop-wrapper {
      margin-bottom: 25px;
    }

    file-preview-item {
      margin: 5px 0 10px;
      background: #fff;
    }
  }

  ul.auto-complete-box {
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    width: 100%;
    -webkit-padding-start: 0;
    border-radius: 0 0 3px 3px !important;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
    z-index: 4;
    box-sizing: border-box;
    border-top: 1px solid #dcecff;
    border-right: 5px solid white;
  }

  ul.auto-complete-box li a {
    margin: 5px;
    border-radius: 5px;
  }

  ul.auto-complete-box li a:hover {
    background: #dcecff !important;
    color: #000 !important;
    font-weight: bold !important;
  }

  .auto-complete-main {
    position: relative;
    padding-right: 5px;
  }

  ul.auto-complete-box a {
    color: #6f7689 !important;
    font-size: 15px !important;
    display: -webkit-box;
  }

  ul.auto-complete-box li a {
    padding: 5px 20px 5px 25px;
    color: #182546 !important;
  }

  ul.auto-complete-box a:hover {
    color: #182546 !important;
  }

  .auto-complete input {
    min-height: 63px;
  }
}

//#POLICY
.policy-card {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  margin-bottom: 24px;
  padding: 25px 25px 20px;
  cursor: pointer;
  box-shadow: 0px 2px 15px #b6d7ff;
  border-radius: 10px;
  background-color: $base-color-2;
  width: 100%;

  @include sm-min {
    max-width: calc(50% - 12px);
  }

  @media (max-width: 375px) {
    padding: 18px 15px;
  }

  &__top {
    display: flex;
  }

  &__bottom {
    // display: flex;
    // justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }

  &__icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 14px;
    position: relative;
    background: linear-gradient(225deg, #3662ff 0%, #1966ff 100%);

    &-media {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      max-height: 16px;
      max-width: 26px;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  //#TITLE
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: #26385d;
    display: flex;

    img {
      margin-left: 12px;
    }
  }

  &__sub-title {
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    color: #909eb9;
  }

  //#TEXT
  &__text {
    font-weight: 700;
    font-size: 14px;

    &-primary {
      color: #000000;
    }

    &-secondary {
      color: #a7a7a7;
    }
  }

  &__price {
    font-weight: 700;
    font-size: 20px;
    color: #3662ff;
  }

  &__progress {
    position: relative;
    margin-top: 5px;

    &:after {
      content: "";
      display: block;
      width: 100%;
      border-radius: 6px;
      height: 4px;
      background: #dedede;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &-fill {
      display: block;
      position: relative;
      z-index: 2;
      width: 0%;
      height: 11px;
      background: linear-gradient(225deg, #3662ff 0%, #1966ff 100%);
      box-shadow: 0px 2px 5px rgba(54, 98, 255, 0.652259);
      border-radius: 6px;
    }
  }

  &__name {
    font-weight: 700;

    @include md-min {
      font-size: 26px;
    }

    @include md {
      font-size: 18px;
    }
  }

  &__deatils {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    color: #000000;
  }

  &__display-no {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;
    margin-top: 3px;
    color: #000000;
  }

  &__validity {
    &-label {
      color: #909eb9;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 0.253208px;
      text-transform: uppercase;
    }

    &-date {
      font-size: 20px;
      color: #000;
      font-weight: bold;

      @include md-min {
        font-size: 20px;
      }

      @include md {
        font-size: 16px;
      }
    }
  }
}

.policy-overview-title {
  color: #949595;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;

  span {
    color: #000;
  }
}

.policies-top-blue-section {
  /* background: url('/assets/img/policies-alt/policy-blue-bar.png') no-repeat; #1966ff*/
  background-image: url("/assets/img/policies-alt/grad-bg.png");
  background-repeat: no-repeat;
  background-position: left;
  background-color: #3662ff;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 30px;
}

.policies-top-blue-section__align-middle {
  height: 100%;
  width: 100%;
  // display: table-cell;
  // vertical-align: middle;
  display: flex;
  min-height: 108px;
  flex-direction: column;
  justify-content: center;
}

.policy-staus {
  color: #fff;
  letter-spacing: 1.37561px;
  text-transform: uppercase;
  font-size: 12.6098px;
  background-repeat: no-repeat;
  padding-left: 26px;
  font-weight: 900;

  &.cancelled {
    background-image: url("/assets/img/policies-alt/cancelled.svg");
  }

  &.insured {
    background-image: url("/assets/img/policies-alt/insured.svg");
  }

  &.validating {
    background-image: url("/assets/img/policies-alt/validating.svg");
  }

  &.lapsed {
    background-image: url("/assets/img/policies-alt/lapsed.svg");
  }
}

.policies-top-blue-content {
  padding: 12px 25px;

  @media (max-width: 767px) {
    padding: 20px 20px;
  }

  @media (max-width: 360px) {
    display: block;
  }
}

.policies-top-blue__title {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.policies-top-blue__img {
  max-width: 22px;
  width: 100%;
  height: 100%;
  max-height: 16px;
}

.policies-top-blue__img-text {
  font-weight: bold;
  margin-left: 11px;
  text-transform: capitalize;
  color: #fff;
}

.button-loader,
.btn-next.button-loader {
  position: relative;
  color: transparent !important;
  opacity: 0.5;
  pointer-events: none;

  .btn-arrow {
    display: none;
  }

  .proceed-svg-hide {
    display: none;
  }

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-left-color: transparent;
    animation: loaderSpin 1.5s infinite linear;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  &--lg {
    &:before {
      width: 27px;
      height: 27px;
      border-width: 3px;
    }
  }

  .magic-button__text {
    opacity: 0;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.mta-payments {
  margin-top: 45px;
  padding-bottom: 180px;

  .hidden-xs {
    @include xs {
      display: none !important;
    }
  }

  .hidden {
    display: none !important;
  }

  //#RADIO BTNS
  .payable-payment-radio {
    .confirm-radio {
      font-style: normal;
      font-weight: 600;

      label {
        padding-top: 6px;
        padding-left: 40px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          margin-top: -3px;
          box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.051);
          transition: all 0.2s ease-in-out 0s;
          border: 4px solid #dee7f1;
          border-radius: 50% !important;
        }
      }

      input:checked + label:before {
        background-color: #02cd56 !important;
        color: #fff;
      }

      .tick-radio:after {
        color: #fff !important;
        top: 15px !important;
        left: 7px !important;
      }
    }

    .radio-tickmark {
      position: relative;

      &:after {
        content: url("/assets/img/icons/radio-tick.svg");
        display: block;
        position: absolute;
        left: 7px;
        top: -1px;
        transition: all 0.2s ease-in-out 0s;
      }

      &:before {
        height: 28px !important;
        width: 28px !important;
      }
    }
  }

  //#PAYABLE USER DEATILS SECTION
  .payabel-details-section {
    padding: 14px 20px;
    background-color: #f6faff;
    border-top: 1px dashed #aec6e8;
    border-bottom: 1px dashed #aec6e8;
    position: relative;

    &:after,
    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      height: 25px;
      width: 13px;
      top: -15px;
      background: #f8f9fa;
    }

    &:after {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      right: 0;
    }

    &:before {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      left: 0;
    }
  }

  //#PAYABLE PAGES
  $primary-border: 1px dashed #aec6e8;

  .pre-payment-titles-wrappers {
    margin-bottom: 39px;
  }

  .pre-payment-main-title {
    font-size: 36px;
    line-height: 42px;
    font-weight: bold;
    color: #3e537e;
    margin: 0;
    align-items: center;

    @media (max-width: 475px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  /* ====================
    #AUTO COLLAPSE LIST
  ======================= */
  .auto-collapse-box {
    border-radius: 4px;
    align-items: center;
    transition: height 4s linear;
    height: auto;
    margin-bottom: 12px;

    &.show-collapse {
      height: auto;
      box-shadow: 0px 2px 15px #b6d7ff;

      .auto-collaspe-collapsed-section {
        display: block;
      }
    }
  }

  .auto-collapse-heading-wrapper {
    margin-left: 25px;
    align-items: center;

    @media (max-width: 767px) {
      margin-left: 23px;
    }
  }

  .auto-collapse-heading {
    font-size: 18px;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    font-weight: bold;
    color: #353a41;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 16px;
      letter-spacing: normal;
    }
  }

  .auto-collapse-right-side .auto-collapse-heading {
    color: #353a41;
  }

  .auto-collapse-visable-section {
    background-color: #fff;
    padding: 17px 20px;
    align-items: center;
  }

  .auto-collaspe-collapsed-section {
    display: none;
    background-color: #f6faff;
    padding: 0px 20px 24px;
    border-top: $primary-border;

    &.auto-collaspe-collapsed-section--accordion {
      padding: 0;
      background-color: #fff;
    }
  }

  .auto-collapse-right-side,
  .auto-collapse-left-side {
    align-items: center;
  }

  .auto-collaspe-collapsed__content {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 13px;
    }

    &.auto-collaspe-collapsed__content--accordion {
      align-items: center;
      padding: 16px 20px;

      @media (min-width: 768px) {
        padding-top: 13px;
      }
    }
  }

  .auto-collaspe-collapsed__content-text {
    color: #909eb9;
    font-size: 14px;
  }

  //#accordion
  .accordion-collapse-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height, opacity 200ms ease-in;
    background-color: #f6faff;
  }

  .accordion-collapse-open {
    ~ .accordion-collapse-content {
      max-height: 100%;
      overflow: visible;
      opacity: 1;
      transition: max-height, opacity 200ms ease-in;
      padding: 10px 20px;

      @media (max-width: 575px) {
        padding: 10px 20px;
      }
    }

    .collapse-icon {
      transform: rotate(-180deg);
      transition: transform 300ms linear;
    }
  }

  .add-on-plains__sub-text {
    font-size: 14px;
    color: #909eb9;
    margin-top: 3px;

    @media (min-width: 575px) {
      min-width: 371px;
      padding-right: 25px;
      width: 100%;
      text-align: justify;
    }
  }

  .add-on-plains {
    align-items: center;
  }

  .add-on-plains-left-side {
    width: 87%;

    @media (min-width: 748px) {
      align-items: center;
    }

    /*     @media (max-width:1199px) and (min-width:992px){
        display: block;
      } */
    // @media (max-width:747px) and (min-width:576px){
    //   display: block;
    //   width: 80%;
    // }
    @media (max-width: 747px) {
      flex-direction: column-reverse;
      width: 80%;
    }
  }

  //RAYZ CSS
  //#Variables for component
  $font-family: "Brandon";

  /*=================================
    #ADDONS COMPONENT: LEFT SECTION - CONFIRMATION
  =================================*/
  $addon-padding: 50px;

  .addons-component {
    background-color: #fff;
    margin-top: 20px;
    padding: 30px 24px 22px;

    &__top-section {
      border-bottom: 1px dashed #aec6e8;
      padding-bottom: 25px;
    }

    &__bottom-section {
      padding: 28px 26px;
      background: #f6faff;
      border-radius: 4px;
      margin-top: 15px;
    }
  }

  //#Fonts and text: Top Section
  .addons-component {
    &__top-section {
      h3 {
        font-size: 18px;
        letter-spacing: 1.125px;
        text-transform: uppercase;
        color: #3e537e;
        font-family: $font-family;
        font-weight: 500;
        margin-bottom: 30px;
      }

      p {
        font-size: 14px;
        color: #909eb9;
        font-weight: $font-family;
        line-height: 18px;
      }
    }
  }

  //#Circular Radio
  .mr-30 {
    margin-right: 30px;
  }

  .confirm-radio {
    font-style: normal;
    font-weight: 600;
    font-family: $font-family;

    label {
      padding-top: 6px;
      padding-left: 40px;

      &::before {
        height: 36px;
        width: 36px;
        border: 4px solid #dee7f1;
        border-radius: 50% !important;
      }
    }

    input:checked + label:before {
      background-color: #02cd56 !important;
      color: #fff;
    }

    .tick-radio:after {
      color: #fff !important;
      top: 15px !important;
      left: 7px !important;
    }
  }

  .form-group--text-right {
    text-align: right;
  }

  .confirm-fg {
    .form-group {
      margin-bottom: 0;

      .label-agree {
        margin-bottom: 0;
      }
    }
  }

  /*=================================
    #PAYABLE BOX: RIGHT SECTION
  =================================*/
  $payable-padding: 20px;

  .payable-amt__box {
    background-color: #fff;
    border-radius: 0 0 7px 7px;

    p + p {
      margin-top: 0;
    }
  }

  .payable-amt__title-box {
    padding: $payable-padding;
    text-align: center;
    padding-bottom: 0;

    h3 {
      margin-bottom: 0;
      font-family: $font-family;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  /*===============================
        #Staus Divider Line
  ===============================*/
  $size: 15px;

  //#Payable Values
  .payable-amt__values {
    padding: $payable-padding;
    padding-top: 0;

    @media (max-width: 500px) {
      &--padding-bottom {
        padding-bottom: 0;
      }
    }
  }

  .payable-amt__rows {
    margin-bottom: 20px;

    @media (min-width: 992px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .payable-abt__values-label {
    font-family: $font-family;
    font-size: 16px;
    line-height: 27px;
    color: #3e537e;
    mix-blend-mode: normal;
    opacity: 0.75;
    font-weight: 600;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 13px;
    }

    &--sm {
      font-size: 14px;
    }
  }

  .payable-amt__values-value {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    text-align: right;
    text-transform: uppercase;
    color: #3e537e;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 22px;
    }

    &--font-18 {
      font-size: 18px;
    }

    &--sm {
      font-size: 16px;
    }
  }

  //#Addon List
  .payable-amt__addons-section {
    margin-top: 30px;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .payable-amt__addons-box {
    max-height: 150px;
    height: 100%;
    overflow-y: auto;

    .payable-amt__addons-list {
      margin-bottom: 9px;

      /* &:last-child {
        margin-bottom: 0;
      } */
    }
  }

  .payable-amt__addons-box {
    padding: $payable-padding;
    border: 1px dashed #aec6e8;
    background-color: rgba(255, 224, 196, 0.0784313725490196);
  }

  .payable-amt__addons-img-container {
    width: 35px;
    height: 35px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      max-height: 33px;
      display: block;
    }
  }

  .payable-amt__addons-text-container {
    p {
      @extend .payable-abt__values-label;
      font-weight: 500;
    }
  }

  //#total-payable-button
  .total-payable-btn {
    padding: 20px;
    border: 0;
    background: none;
    background-color: #1865ff;

    @media (min-width: 992px) {
      background-color: #f6fafe;
    }

    border-radius: 0 0 7px 7px;
    width: 100%;
    max-width: 100%;
    box-shadow: none;

    @media (min-width: 992px) {
      pointer-events: none;
    }

    &--padding-bottom {
      @media (max-width: 500px) {
        padding-bottom: 10px;
      }
    }

    &--radious-0 {
      border-radius: 0;
    }

    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
    }

    &__label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 27px;
      /* identical to box height, or 169% */
      text-transform: uppercase;
      color: #182546;

      @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 14px;
      }

      @media (max-width: 991px) {
        color: #fff;
      }
    }

    &__value {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
      text-align: right;
      text-transform: uppercase;
      color: #182546;

      @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 19px;
      }

      @media (max-width: 991px) {
        color: #fff;
      }

      @media (max-width: 500px) {
        font-size: 19px;
      }
    }
  }

  //#CART BAR
  .cart-menu {
    background: #1865ff;
    box-shadow: 0px 9px 10px rgba(93, 128, 188, 0.296875);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;

    @media (min-width: 992px) {
      display: none;
    }

    .cart-menu-wrapper {
      display: flex;
      align-content: center;
      padding: 18px 12px;
      max-width: 500px;
      justify-content: space-between;
      margin: auto;
    }

    .cart-text {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      margin: 0;

      @media (max-width: 500px) {
        font-size: 13px;
      }
    }

    .cart-pipe {
      padding: 0 14px;
    }

    .view-cart-button {
      svg {
        @media (max-width: 500px) {
          height: 20px;
        }
      }
    }

    .view-cart-text {
      padding-right: 13px;
    }
  }

  .agree-radio-buttons {
    @media (max-width: 467px) {
      display: block;

      .form-group--text-right {
        text-align: left;
        text-align: left;
        margin-top: 12px;
      }
    }

    display: flex;
    justify-content: space-between;
  }

  //#POP UP
  .payable-amt-section--hiiden-mobile {
    max-width: 360px;
    width: 100%;

    @media (max-width: 1199px) and (min-width: 992px) {
      max-width: 293px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .cart-modal-dilog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 66px;
    margin-bottom: 0 !important;
    min-height: auto;
  }

  .modal-total-payable-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
  }

  // .cart-pop-up-body {
  //     height: calc(100vh - 200px);
  // }

  .cart-pop-up-content {
    background: #fff;
  }

  .add-on-buttons {
    button {
      box-shadow: none !important;
    }

    .custom-back {
      @media (max-width: 479px) {
        display: inline-block !important;
      }
    }

    @media (max-width: 479px) {
      display: flex;
      flex-direction: column;

      button {
        &:first-child {
          order: 1;
        }
      }
    }
  }

  .bottom-menu {
    display: none;
  }

  .payable-payment-radio {
    margin-bottom: 14px;
    margin-top: 20px;
  }

  .cart-modal-dilog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 66px;
    margin-bottom: 0 !important;
    min-height: auto;
  }

  // .cart-pop-up-body {
  //     height: calc(100vh - 142px);
  // }

  .payable-payment-radio {
    .confirm-radio {
      font-size: 16px;
      color: rgba(62, 83, 126, 0.75);
    }
  }

  .add-on-buttons {
    margin-bottom: 50px;
  }

  .payabelSticky {
    position: sticky;
    top: 121px;
    margin-bottom: 20px;

    @media (max-width: 1199px) and (min-width: 992px) {
      top: 105px;
    }
  }

  .doYouConfirm {
    max-width: 168px;
    text-align: left;
  }

  .add-on-row {
    display: flex;
    flex-wrap: wrap;

    &:after,
    &:before {
      display: none;
    }
  }

  .add-on-price {
    margin-top: 15px;
  }

  .payable-payment-radio {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 300px) {
      max-width: 300px;
    }
  }

  //#ADDONS VIEW MORE
  .view-more {
    border: 0;
    padding: 0;
    background: transparent;
    margin-top: 8px;
    font-weight: 600;
    display: block;
    color: #3e537e;
    font-size: 15px;

    @media (min-width: 748px) {
      display: none;
    }
  }

  .payable-payment-radio--center {
    justify-content: center;
  }

  .accordion-collapse-content-body {
    padding-bottom: 30px;
    padding-top: 28px;
    border-bottom: $primary-border;

    &:last-child {
      border-bottom: 0;
    }
  }

  .add-on-plains__sub-text--desk {
    @media (max-width: 747px) {
      display: none;
    }
  }

  .add-on-plains__sub-text--mobile {
    @media (min-width: 748px) {
      display: none;
    }
  }

  .total-payable-btn--deposit {
    padding-top: 0;
  }

  .total-payable-btn--total-monthly {
    padding-bottom: 10px;
  }

  .addons-component__inputs-wrapper {
    margin-top: 25px;
  }

  .addons-component__inputs-box {
    @media (min-width: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      margin-top: 6px;
      margin-bottom: 6px;

      .error_msg {
        margin-bottom: 10px;
      }
    }

    .addons-component__input {
      border: 1px solid #d1deeb;

      &--padding {
        padding: 19px;
      }

      &--email,
      &--phone {
        font-family: "Brandon", sans-serif;
        font-size: 18px;
      }

      &--phone {
        padding-left: 92px;
      }

      .input-mobile-number {
        width: 67px;
        border-right: 1px solid #d1deeb;
        font-size: 18px;
      }
    }
  }

  //#AGREEMENT
  .addons-aggrement-wrapper {
    @media (min-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 767px) {
      margin-top: 35px;
    }
  }

  .aggrement-op-up-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .addons-payble-close-btn {
    width: 100%;
    text-align: right;
    opacity: 1;
    font-size: 35px;
    color: #fff;
  }

  //#AUTO COLLAPSE
  .auto-collapse-box {
    @media (max-width: 991px) {
      .auto-collapse-icon {
        display: none;
      }

      .auto-collapse-heading-wrapper {
        margin-left: 0;
      }
    }

    //#COLLAPSE ICON
    .collapse-icon {
      margin-left: 20px;

      @media (max-width: 767px) {
        margin-left: 17px;
      }

      transition: transform 300ms linear;
    }

    //#COLLAPSE TOGGLE
    &.show-collapse {
      //#COLLAPSE ICON
      .collapse-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .payabel-details {
    &__name {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      color: #3e537e;
    }

    &__vech-name {
      color: #3e537e;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .payable-deatils-text {
    font-size: 22px;
    text-transform: uppercase;
    color: #3e537e;
    font-weight: bold;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 18px;
    }

    @media (max-width: 991px) {
      font-size: 18px;
    }

    &-sub {
      font-size: 17px;

      @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 14px;
      }

      @media (max-width: 991px) {
        font-size: 15px;
      }
    }
  }

  .payable-label {
    color: rgba(62, 83, 126, 0.75);
    line-height: 17px;
    font-weight: 500;
    font-size: 14px;

    @media (max-width: 991px) {
      font-size: 13px;
    }

    &--margin {
      @media (min-width: 992px) {
        margin-top: 15px;
        margin-bottom: 22px;
      }

      @media (max-width: 991px) {
        margin-bottom: 12px;
      }
    }

    &--letterspacing {
      letter-spacing: 1px;
    }
  }

  .payable-vehicals-details {
    padding: 14px 20px;
  }

  .payable-deatils__col {
    margin-bottom: 20px;

    &-top {
      @media (min-width: 992px) {
        margin-bottom: 30px;
      }

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  //#BUTTON
  .payable-button {
    background-color: #1865ff;
    width: 100%;
    padding: 18px 30px;
    border-radius: 0;

    &__text {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
    }

    &__icon {
      position: relative;
      transition: left 300ms linear;
    }

    &:hover,
    &:focus,
    &:active {
      &__icon {
        left: 9px;
      }
    }
  }

  //#PAYABLE FOOTER
  .payable-footer {
    border-top: 1px dashed #aec6e8;

    &__content {
      border-radius: 4px;
      padding: 28px 30px;

      @media (min-width: 992px) {
        background-color: #3e537e;
      }

      @media (max-width: 991px) {
        padding: 15px 0;
        border-bottom: 1px dashed #576c8a;
        margin-left: 15px;
        margin-right: 15px;
      }

      &-wrapper {
        margin-top: 14px;

        @media (max-width: 991px) {
          background-color: #3e537e;
        }
      }

      &-text {
        font-size: 26px;
        font-weight: 600;
        color: #fff;
        margin: 0;

        @media (max-width: 992px) and (min-width: 1199px) {
          font-size: 24px;
        }

        @media (max-width: 991px) {
          font-size: 20px;
          line-height: 23px;
        }
      }
    }

    //#QUESTION SECTION
    &__question {
      justify-content: center;
      margin-top: 23px;
      align-items: center;
      background: transparent;
      border: 0;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 991px) {
        padding: 20px 0;
        margin-top: 0;
      }

      &-text {
        color: #3e537e;
        font-size: 14px;
        margin: 0;
        margin-left: 10px;
        font-weight: 600;
        text-decoration: underline;
      }

      @media (max-width: 991px) {
        &-md-white {
          .payable-footer__question-text {
            color: #fff;
          }

          .payable-footer__question-icon {
            path {
              fill: #fff;
            }
          }
        }
      }

      //#PAYABLE FOOTER
      &--pop-up {
        border-top: 1px dashed #aec6e8;
      }
    }
  }

  //#POP UP
  .paybale-popup__header {
    padding: 14px 20px;

    // border-bottom: 1px dashed #AEC6E8;
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &-text {
        font-size: 18px;
        text-transform: uppercase;
        color: #3e537e;
        font-weight: 600;
        margin-bottom: 0;
        margin-left: 15px;
      }
    }
  }

  //#PAYABLE MODELS BUTTON
  .payment-button-wrapper--payable {
    margin: 0 auto !important;
    max-width: 360px;

    .payment-button {
      border: 2px solid #fff;
      background: transparent;
      color: #fff;
      border-radius: 3px;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      padding: 9px 15px;
      width: calc(50% - 8px);
      max-width: 160px;
    }

    &.single-button {
      justify-content: center;

      .payment-button {
        max-width: 299px;
        width: 100%;
      }
    }
  }

  .payment-buttons__container {
    padding: 22px 15px;
    position: fixed;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #1966ff;
  }

  .payment-modal {
    background-color: #232c42b8;
    display: block;
    z-index: 1920;
  }
}

body.blueGradBg {
  background: url("/assets/img/blue-bg.svg") no-repeat;
  background-size: cover;
}

.site-box {
  padding: 20px 22px;
}

.site-box--rounded {
  border-radius: 10px;
}

.primary-box {
  @extend .site-box;

  @media (max-width: 360px) {
    padding: 20px 13px;
  }

  background-color: #fff;
}

.secondary-box {
  @extend .site-box;
  background-color: #f6faff;
}

.primary-box-title {
  font-size: 30px;
  color: #3e537e;
  font-weight: bold;
  margin-bottom: 0;

  @media (max-width: 575px) {
    font-size: 21px;
  }
}

.primary-box-title-icons {
  margin-right: 17px;
  align-items: center;
  display: flex;

  @media (max-width: 575px) {
    max-width: 36px;
    margin-right: 13px;

    svg {
      width: 100%;
    }
  }
}

.primary-box-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.primary-box-title-sub {
  font-size: 12px;
  color: #909eb9;
}

.primary-box-bottom-buttons {
  margin-top: 21px;

  @media (max-width: 479px) {
    .custom-back {
      display: inline-block !important;
    }
  }
}

.primary-box-bottom-buttons--right {
  @media (min-width: 480px) {
    display: flex;
    justify-content: flex-end;
  }
}

//INPUTS
.update-reg-input-fields {
  border: 1px solid #f3f5f7 !important;
}

.primary-box--bottom-padding {
  padding-bottom: 30px;

  @media (max-width: 360px) {
    padding: 20px 13px;
  }
}

@media (max-width: 479px) {
  .cancelation-buttons .custom-back {
    display: inline-block !important;
  }
}

.confirm-vehicle-options {
  padding-top: 0;
  height: auto;

  .options {
    top: 0;
  }

  label {
    margin-bottom: 0;
  }
}

.effective-date-select {
  border: 1px solid #f3f5f7 !important;
  box-shadow: 4px 6px 13px rgba(65, 96, 132, 0.0745301);
}

.mta-forms {
  margin-top: 45px;
  padding-bottom: 180px;

  .table-claims {
    td,
    th {
      border: 0;
      padding: 0;
    }
  }

  .table-claims.form-group thead tr th {
    background: transparent;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    line-height: 26px;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #182546;
  }

  .table-claims.form-group thead {
    border-bottom: 0;
  }

  .table-claims {
    background: rgba(255, 255, 255, 0.370726);
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.0303442);
    padding: 20px;

    table {
      margin-bottom: 0;
    }
  }

  .table-claims.form-group th {
    font-size: 13px;
    font-weight: 900px;
  }

  .table-claims.form-group td {
    background: transparent;
    padding: 0 10px;
    border-bottom: none;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #182546;

    .price_claims {
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 15px;
      letter-spacing: 0.501428px;
      margin-right: 10px;
      color: #182546;
    }
  }

  td.table-action-btn span {
    width: 30px;
    height: 16px;
  }

  td.table-action-btn.action-btns-table {
    float: right;
  }

  .edit-table-btn {
    margin-right: 20px;
    line-height: 22px;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #1563ec;
  }

  .action-btns-table img {
    height: 16px;
    cursor: pointer;
  }

  .white-date-select {
    border: 1px solid #f3f5f7;
    box-shadow: 4px 6px 13px rgba(65, 96, 132, 0.0745301);
    display: flex;
    position: relative;

    > * {
      flex: 1;
    }

    .bootstrap-select .btn.dropdown-toggle {
      min-height: 63px;
      padding-left: 20px;
      padding-right: 30px;
      box-shadow: none !important;
      border-radius: 0;

      &:before {
        content: "";
        display: block;
        pointer-events: none;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 5px solid #a6aec2;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -4px;
        z-index: 3;
      }

      &:after {
        content: "";
        display: block;
        pointer-events: none;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid #a6aec2;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: 4px;
        z-index: 3;
      }
    }

    .bootstrap-select {
      border-right: 1px solid #e6eff7;
      width: 100%;
      position: static;
    }

    .filter-option {
      text-align: left;
      float: left;
      width: 100%;
      font-weight: 500;
    }

    .remove-border-right {
      border-right: none !important;
    }
  }

  .white-select-box {
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.071);
    border-radius: 3px;
    padding: 17px !important;
    border: 1px solid #f3f5f7;

    min-height: 65px;
    background: #fff;
    color: #182546;
    font-weight: 500;
    font-size: 15px;
    text-transform: none;
    white-space: nowrap;
    text-align: left;

    &:after {
      content: "";
      background-image: url("../../img/dropdown-icon-solid.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border: 0;
      padding: 7px;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(180deg);
      transition: transform 300ms linear;
      position: absolute;
      right: 20px;
      width: 0;
      height: 0;
      display: block;
      pointer-events: none;
    }

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(0deg) !important;
      }
    }

    &--border {
      border: 1px solid #f3f5f7 !important;
    }

    .filter-option {
      text-align: left;
    }

    &-wrapper {
      width: 100%;
    }

    &-label {
      margin-bottom: 10px;
    }
  }

  .white-auto-complete {
    .white-select-box {
      font-size: 15px;
      font-weight: 500;
    }

    .auto-complete-box {
      li {
        a {
          font-weight: 500;
        }
      }
    }
  }

  .bs-placeholder {
    color: #a6aec2;
  }

  .font-bold {
    font-weight: bold;
  }

  .color-dark-gray {
    color: #3e537e;
  }

  .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-right: 5px;
    border: none;
    box-shadow: 4px 14px 32px 0 #4160842e;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #e6eff7;
    overflow: scroll;
    max-height: 306px;

    &.open {
      display: block;
    }

    ul {
      li {
        a {
          color: #182546;
          padding: 5px 20px 5px 25px;
          font-size: 15px;
          margin: 5px;
          border-radius: 5px;
          display: block;
          border-radius: 5px;
          font-weight: 500;
        }

        cursor: pointer;
        display: block;

        &.selected,
        &:hover,
        &:active {
          a {
            background: #dcecff;
          }
        }

        &.selected {
          font-weight: bold;
        }
      }

      // &.date-pick {
      //     max-height: 306px;
      // }
    }

    .date-pick {
      max-height: 306px;
      margin: 0;

      li {
        padding: 0;
        position: relative;

        a {
          padding: 10px 0 !important;
          text-align: center;
        }
      }
    }

    ul::-webkit-scrollbar,
    .auto-complete-box::-webkit-scrollbar,
    .pop::-webkit-scrollbar {
      width: 6px;
    }

    ulr::-webkit-scrollbar-track,
    .auto-complete-box::-webkit-scrollbar-track,
    .pop::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent;
    }

    ul::-webkit-scrollbar-thumb,
    .auto-complete-box::-webkit-scrollbar-thumb,
    .pop::-webkit-scrollbar-thumb {
      background-color: lightgrey;
      outline: none;
      border-radius: 10px;
      height: 50px;
    }
  }

  .month_btn .date-pick {
    //     display: grid !important;
    //    // grid-template-columns: 50% 50%;
    //    grid-template-rows: repeat(6, 1fr);
    //     grid-auto-flow: column;
    //     grid-auto-columns: 50% 50%;
    li a {
      padding: 10px 0 !important;
      text-align: center;
    }

    li {
      padding: 0px;

      &:nth-child(odd) {
        border-right: 1px solid #e4e4e4;
      }
    }
  }

  .options input {
    display: none;
  }

  .custom-options .options {
    margin-right: 15px;
  }

  .options-group > *:last-child {
    margin-right: 0;
  }

  // .options-group.custom-options {
  //     padding-top: 15px;
  // }
  .options-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .custom-options .options label {
    border: 1px solid #6f7689;
    padding: 15px 50px;
    border-radius: 5px;
    text-transform: uppercase;

    height: 51px;
    font-weight: 900;
    font-size: 15px;

    @include max-375 {
      padding: 18px 40px;
    }
  }

  .no-add-driver {
    height: auto !important;
  }

  .custom-options .options label:before,
  .custom-options .options label:after {
    visibility: hidden;
  }

  .custom-options .options input[type="radio"]:checked ~ label {
    color: #fff;
    background: #1966ff;
    border: 1px solid #1966ff;
    border-radius: 3px;
  }

  //LESS TEHN 768
  @media (max-width: 767px) {
    //#RADIO BUTTONS
    .options-group.custom-options {
      padding-top: 0 !important;

      .options {
        top: 0 !important;
      }

      label {
        margin-bottom: 0 !important;
      }
    }

    //MULTI SELECT BOX
    .select-box-group {
      .select-box {
        label {
          &[for="select-box3"] {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .registration-search {
    background-image: url("../../img/board.svg"), url("../../img/icon-search.svg");
    background-color: #fff;
    background-repeat: repeat-y, no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    font-family: uknumber;
    font-size: 2em;
    text-transform: uppercase;
    background-size: 28px, 15px;
    background-position: left center, 95% center;
    // line-height: 65px;
    color: #182546;

    &:focus {
      border: none;
      outline: none;
      box-shadow: 4px 14px 32px 0 #4160842e;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: #a6aec2;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a6aec2;
    }

    &::placeholder {
      color: #a6aec2;
    }
  }

  .load {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/spinner-preloader.svg");
  }

  .cancel {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/cancel.svg");
  }

  .checked {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/checked.svg");
  }

  .loading-status {
    background-image: url("../../img/spinner-preloader.svg");
  }

  .success-status {
    background-image: url("../../img/checked.svg");
  }

  .error-status {
    background-image: url("../../img/cancel.svg");
  }

  ul.auto-complete-box {
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    width: 100%;
    -webkit-padding-start: 0;
    border-radius: 0 0 3px 3px !important;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
    z-index: 4;
    box-sizing: border-box;
    border-top: 1px solid #dcecff;
    border-right: 5px solid white;
  }

  ul.auto-complete-box li a {
    margin: 5px;
    border-radius: 5px;
  }

  ul.auto-complete-box li a:hover {
    background: #dcecff !important;
    color: #000 !important;
    font-weight: bold !important;
  }

  .auto-complete-main {
    position: relative;
    padding-right: 5px;
  }

  ul.auto-complete-box a {
    color: #6f7689 !important;
    font-size: 15px !important;
    display: -webkit-box;
  }

  ul.auto-complete-box li a {
    padding: 5px 20px 5px 25px;
    color: #182546 !important;
  }

  ul.auto-complete-box a:hover {
    color: #182546 !important;
  }

  .auto-complete input {
    min-height: 63px;
  }
}

.error_msg {
  color: #e60808;
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: 13px;
  font-weight: 600;
}

.box-error {
  border: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;

  &--dashed {
    border-style: dashed !important;
  }
}

.box-error-left {
  border-left: 1px solid #e60808 !important;
  border-top: 1px solid #e60808 !important;
  border-bottom: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
}

.box-error-center {
  border-top: 1px solid #e60808 !important;
  border-bottom: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
}

.box-error-right {
  border-right: 1px solid #e60808 !important;
  border-top: 1px solid #e60808 !important;
  border-bottom: 1px solid #e60808 !important;
  box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
}

//INPUT SPACER
.inputs-margin-bottom-20 {
  margin-bottom: 20px;
}

.inputs-margin-bottom-md-20 {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.inputs-margin-top-20 {
  margin-top: 20px;
}

@media (max-width: 479px) {
  button.btn.btn-grey.btn-lg.btn-prev.custom-back {
    display: none;
  }
}

.vc-get-quote-form-button {
  .btn-next {
    position: relative;
    text-align: left;
    min-width: 180px;
    padding: 21px 25px;
    font-weight: 900;
    background-color: #1563ec !important;
    box-shadow: none !important;
    border: 0;
    font-size: 12px;
    position: relative;
    text-transform: uppercase;

    .btn-arrow {
      position: absolute;
      top: 45%;
      right: 25px;
      display: block;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color: #1563ec, $amount: 5) !important;
    }
  }

  .btn-grey.btn-lg {
    background-color: rgba(166, 174, 194, 0.22);
    color: #6f7689 !important;
    padding: 21px 30px;
    min-width: 170px;
    box-shadow: none !important;
    border: 0;
    font-size: 12px;
    position: relative;
    text-transform: uppercase;
    font-weight: 900;
  }

  @media (max-width: 479px) {
    .btn {
      display: block;
      min-width: 100% !important;
    }

    .btn + .btn {
      margin-top: 10px;
    }
  }
}

.usage-info-wrapper {
  margin-bottom: 30px;

  .usage-info-wrapper-title,
  .usage-info-wrapper-main {
    font-weight: bold;
    color: #3e537e;
    line-height: 24px;
  }

  .usage-info-wrapper-title {
    font-size: 14px;
  }

  .usage-info-wrapper-main {
    font-size: 20px;
    margin-top: 0;
  }
}

.mta-modal {
  .payment-modal {
    .modal-dialog,
    .modal-content {
      height: 100%;
    }

    .modal-dialog {
      height: 100%;
      max-height: 700px;
    }
  }

  .vc-acceptance-criteria .well {
    margin-bottom: 0;
    border-radius: 4px;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out 0s;
    padding: 40px;
  }

  .well a {
    font-weight: 600;
  }

  .vc-sec2-wells .well-item {
    background: #fff;
    border-radius: 4px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 15px 35px 0 rgba(0, 0, 0, 0.02);
    height: 385px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .vc-sec2-wells .well-item:after {
    content: "";
    display: block;
    width: 100%;
    height: 30px;
    background: #fff;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 3;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  }

  .vc-sec2-wells .well-item .well-item-img-wrap {
    height: 260px;
    background: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  }

  .vc-sec2-wells .well-item .well-item-img {
    display: block;
    background: #182546;
    height: 260px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
  }

  .vc-sec2-wells .well-item .well-item-text {
    padding: 20px 40px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 385px;
    position: absolute;
    margin: auto;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
    z-index: 2;
    background: #fff;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .vc-sec2-wells .well-item .well-item-text .btn {
    width: 200px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 40px;
  }

  .vc-sec2-wells .well-item:hover,
  .vc-sec2-wells .well-item:focus,
  .vc-sec2-wells .well-item:active {
    box-shadow: 0 40px 60px 0 rgba(0, 0, 0, 0.05);
  }

  .vc-sec2-wells .well-item:hover:after,
  .vc-sec2-wells .well-item:focus:after,
  .vc-sec2-wells .well-item:active:after {
    opacity: 0;
  }

  .vc-sec2-wells .well-item:hover .well-item-img-wrap,
  .vc-sec2-wells .well-item:focus .well-item-img-wrap,
  .vc-sec2-wells .well-item:active .well-item-img-wrap {
    opacity: 0;
  }

  .vc-sec2-wells .well-item:hover .well-item-img,
  .vc-sec2-wells .well-item:focus .well-item-img,
  .vc-sec2-wells .well-item:active .well-item-img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .vc-sec2-wells .well-item:hover .well-item-text,
  .vc-sec2-wells .well-item:focus .well-item-text,
  .vc-sec2-wells .well-item:active .well-item-text {
    -webkit-transform: translateY(-260px);
    transform: translateY(-260px);
    border-radius: 4px;
    padding-top: 50px;
  }

  &.cc_modal .vc-acceptance-criteria .well {
    background-color: #f8f9fa;
  }

  .pop::-webkit-scrollbar {
    width: 6px;
  }

  .pop::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background: transparent;
  }

  .pop::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    outline: none;
    border-radius: 10px;
    height: 50px;
  }

  .pop {
    width: 100%;
    // height: 600px;
    border: 0;
  }

  &.modal-open .modal {
    background-color: rgba(35, 44, 66, 0.722);
    display: block;
    z-index: 10000;
  }

  @media (min-width: 768px) {
    .modal.modal-vc-your-quote .modal-dialog {
      max-width: 600px;
    }
  }

  @media (min-width: 992px) {
    .modal.modal-vc-your-quote .modal-dialog {
      width: 90%;
      max-width: 1100px;
    }
  }

  @media (min-width: 992px) {
    &.cc_modal .modal.modal-vc-your-quote .modal-dialog {
      width: 90%;
      max-width: 850px;
    }
  }

  .modal.modal-vc-your-quote .modal-body {
    padding: 0;
  }

  .modal.modal-vc-your-quote .close span {
    color: #b1b3b7;
    opacity: 1;
    font-weight: 700 !important;
    font-size: 1.5em;
  }

  .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading {
    line-height: 40px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #182546;
  }

  .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading span {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgba(25, 102, 255, 0.05);
    text-align: center;
    font-size: 13px;
    font-weight: 900;
    color: #1966ff;
    margin-right: 15px;
    position: relative;
    top: -4px;
  }

  .vc-acceptance-criteria .vc-acceptance-criteria-item {
    margin-top: 40px;
  }

  .list-dots {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .vc-acceptance-criteria .well p {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.9;
    color: #2e4468;
  }

  .pop {
    height: 600px;
    overflow-y: auto;
  }

  .list-dots li {
    font-size: 13px;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
    margin-bottom: 10px;
    letter-spacing: 0.7px;
    color: #2e4468;
  }

  .list-dots li:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #050f28;
    position: absolute;
    top: 10px;
    left: 0;
  }

  @media (min-width: 1200px) {
    .vc-acceptance-criteria .vc-acceptance-criteria-item {
      margin-top: 60px;
    }
  }

  @media (min-width: 768px) {
    .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading {
      line-height: 44px;
      margin-bottom: 30px;
      font-size: 24px;
    }

    .vc-acceptance-criteria .vc-acceptance-criteria-item .vc-acceptance-criteria-item-heading span {
      width: 44px;
      height: 44px;
      line-height: 44px;
    }
  }

  @media (max-width: 991px) {
    .modal .modal-dialog {
      margin-top: 40px !important;
    }
  }

  .modal .modal-dialog {
    margin-top: 20px;
  }

  @media (min-width: 768px) and (min-height: 700px) {
    .modal .modal-dialog {
      width: 700px;
      margin-top: 5%;
    }
  }

  @media (min-width: 992px) {
    .modal .modal-dialog {
      width: 780px;
    }

    .make_modal .modal .modal-dialog {
      width: 550px;
    }
  }

  .terms-body {
    overflow-y: hidden;
  }

  .terms-body .pop {
    padding: 1em 0 33px;
  }

  .pop {
    // height: 600px;
    overflow-y: auto;
  }

  &.cc_modal .terms-body {
    background-color: #f8f9fa;
    border-radius: 5px;
  }

  &.cc_modal .modal-content {
    border-radius: 5px !important;
  }

  .modal .modal-content .close {
    font-weight: 300;
    color: #fff;
    position: absolute;
    opacity: 0.7;
    top: -30px;
    right: 0;
    z-index: 2;
    transition: all 0.2s ease-in-out 0s;
    text-shadow: none;
  }

  .modal .modal-content .close:hover,
  .modal .modal-content .close:focus,
  .modal .modal-content .close:active {
    outline: none;
    opacity: 1;
  }

  @media (min-width: 768px) {
    .modal .modal-content .close {
      color: #444444;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .modal-header h3 {
    font-weight: 500;
    font-size: 24px;
  }

  .group {
    max-height: 507px;
    height: auto;
    overflow-y: auto;
    padding-top: 20px;
    padding: 20px 40px 0px 40px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    .well-padding {
      padding: 0px 0px !important;
    }

    .group {
      padding: 20px 20px 0px 20px;
    }
  }

  @media (max-width: 360px) {
    .cc_modal .pop_modals {
      background-color: #f8f9fa;
    }
  }

  .common-save {
    padding: 0.7em 1.5em !important;
    color: #fff;
    font-size: 16px !important;
    font-weight: 900;
    border-radius: 3px;
    text-transform: uppercase;
    background: #1563ec;
    border: none;
  }

  #addAnotherConviction {
    margin-bottom: 0px;
  }

  .right-padding-0 {
    padding-right: 0 !important;
  }
}

.mta-forms-primary {
  margin-top: 45px;
  padding-bottom: 80px;

  .vc-get-quote-heading {
    margin-bottom: 40px;
  }

  .accordion-btn {
    width: 100%;
    z-index: 2;
    background-color: #fff;
    box-shadow: none !important;
    background-position: 98% center;
    background-repeat: no-repeat;
    background-size: 25px;
    display: block;

    .name-label {
      text-transform: capitalize;
      line-height: normal;
      font-size: 24px;
      font-weight: bold;
      color: #1966ff;
      white-space: normal;
    }

    .position {
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;
    }

    .edit_driver {
      line-height: normal;
      font-size: 16px;
      text-decoration-line: underline;
      color: #1966ff;
      text-transform: capitalize;
    }

    .delete-add-driver {
      visibility: visible;
      display: inline-block;
      cursor: pointer;
      margin: 0;
      width: 16px;
      height: 16px;
      margin-right: 0;
      margin-left: 10px;
      background: url("/assets/img/delete.png") no-repeat 50%;
    }

    .btn-accordion {
      width: 100%;

      @include sm-min {
        padding: 12px 30px;
      }

      @include sm {
        padding: 12px 20px;
      }
    }
  }

  .three_grid,
  .six_grid {
    margin-left: 0;
    margin-right: 0;
  }

  .six_grid li {
    padding: 0;
  }

  .three_grid li a,
  .six_grid li a {
    padding: 10px 0 !important;
    text-align: center;
  }

  .table-claims {
    td,
    th {
      border: 0;
      padding: 0;
    }
  }

  .table-claims.form-group thead tr th {
    background: transparent;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    line-height: 26px;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #182546;
  }

  .table-claims.form-group thead {
    border-bottom: 0;
  }

  .table-claims {
    background: rgba(255, 255, 255, 0.370726);
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.0303442);
    padding: 20px;

    table {
      margin-bottom: 0;
    }
  }

  .table-claims.form-group th {
    font-size: 13px;
    font-weight: 900px;
  }

  .table-claims.form-group td {
    background: transparent;
    padding: 0 10px;
    border-bottom: none;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #182546;

    .price_claims {
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 15px;
      letter-spacing: 0.501428px;
      margin-right: 10px;
      color: #182546;
    }
  }

  td.table-action-btn span {
    width: 30px;
    height: 16px;
  }

  td.table-action-btn.action-btns-table {
    float: right;
  }

  .edit-table-btn {
    margin-right: 20px;
    line-height: 22px;
    font-size: 15px;
    letter-spacing: 0.501428px;
    color: #1563ec;
  }

  .action-btns-table img {
    height: 16px;
    cursor: pointer;
  }

  .common-save {
    padding: 0.7em 1.5em !important;
    color: #fff;
    background: transparent;
    font-size: 16px !important;
    font-weight: 900;
    border-radius: 3px;
    font-family: "brandon";
    text-transform: uppercase;
    background: #1966ff;
    border: none;
  }

  font.back {
    font-size: 15px;
    color: #1966ff;
  }

  .common-save:focus {
    outline: -webkit-focus-ring-color auto 0 !important;
  }

  .save-entry {
    margin-right: 1em;
    margin-bottom: 50px;
  }

  .postal-code {
    text-transform: uppercase;
  }

  .common-status {
    background-size: 26px;
    background-position: 95% center;
    background-repeat: no-repeat;
  }

  @media (min-width: 1200px) {
    .vc-get-quote-heading {
      margin-bottom: 80px;
    }
  }

  .vc-get-quote-heading .heading1 {
    color: #182546;
    margin-bottom: 20px;
    font-size: 33px;
    font-weight: 400;
  }

  .vc-get-quote-form-item {
    margin-bottom: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(84, 107, 166, 0.1);
  }

  @media (min-width: 768px) {
    .vc-get-quote-form-item {
      padding-bottom: 20px;
      margin-bottom: 55px;
    }
  }

  @media (min-width: 992px) {
    .vc-get-quote-form-item .vc-get-quote-form-item-inner {
      max-width: 635px;
      margin: 0 auto;
    }

    .custom-inner .vc-get-quote-form-item-inner {
      max-width: 620px;
    }
  }

  .vc-get-quote-form-item:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .vc-get-quote-form-item .vc-get-quote-form-heading {
    line-height: 40px;
    margin-bottom: 40px;
    font-size: 20px;
    color: #182546;
    font-weight: 400;
  }

  .vc-get-quote-form-item .vc-get-quote-form-heading span {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgba(25, 102, 255, 0.05);
    text-align: center;
    font-size: 13px;
    font-weight: 900;
    color: #1966ff;
    margin-right: 15px;
    position: inherit;
    /*relative Rayner*/
    top: -4px;
  }

  @media (min-width: 768px) {
    .vc-get-quote-form-item .vc-get-quote-form-heading {
      line-height: 44px;
      margin-bottom: 50px;
      font-size: 24px;

      &.claims_header {
        margin-bottom: 25px !important;
      }

      label {
        margin-top: -15px;
        padding-left: 65px;
      }
    }

    .vc-get-quote-form-item .vc-get-quote-form-heading span {
      width: 44px;
      height: 44px;
      line-height: 44px;
      font-size: 15px;
    }

    .foreditdriver .vc-get-quote-form-item .vc-get-quote-form-heading span.form-info {
      width: 12px;
      height: 20px;
      line-height: 20px;
      font-size: 15px;
      background-color: transparent;
    }
  }

  @media (min-width: 480px) {
    .vc-get-quote-form-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  .input-multi > * {
    margin-bottom: 10px;
  }

  @media (min-width: 500px) {
    .input-multi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
      transition: all 0.2s ease-in-out 0s;
      position: relative;
      z-index: 4;
      border-radius: 3px;
    }

    .input-multi > * {
      margin-bottom: 0;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0;
      border: 1px solid transparent !important;
      border-radius: 0 !important;
      border-right: 1px solid rgba(166, 174, 194, 0.15) !important;
    }

    .input-multi > *:first-child {
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
    }

    .input-multi > *:last-child {
      border-right: 0 !important;
      border-top-right-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }

    .input-multi > *.fg2 {
      -webkit-box-flex: 2;
      -ms-flex: 2 1 0px;
      flex: 2 1 0;
    }

    .input-multi .form-control {
      box-shadow: none;
    }

    .input-multi .form-control:focus {
      box-shadow: none;
    }

    .input-multi .btn {
      min-width: auto !important;
      max-width: 120px;
    }
  }

  @media (min-width: 768px) {
    .multi-custom {
      width: 50%;
      display: inline-block !important;
    }

    .multi-custom .fg-half {
      width: calc(100% - 10px) !important;
      max-width: 100% !important;
    }

    .form-group-multi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .form-group-multi > div {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-right: 20px;
    }

    .form-group-multi > div:last-child {
      margin-right: 0;
    }

    .form-group-multi > div.fg2 {
      -webkit-box-flex: 1.5;
      -ms-flex: 1.5 1 0px;
      flex: 1.5 1 0;
    }
  }

  .month_btn .date-pick {
    //     display: grid !important;
    //    // grid-template-columns: 50% 50%;
    //    grid-template-rows: repeat(6, 1fr);
    //     grid-auto-flow: column;
    //     grid-auto-columns: 50% 50%;
    li a {
      padding: 10px 0 !important;
      text-align: center;
    }

    li {
      padding: 0px;

      &:nth-child(odd) {
        border-right: 1px solid #e4e4e4;
      }
    }
  }

  @media (min-width: 1200px) {
    .form-group-multi > div {
      margin-right: 40px;
    }

    .form-group-multi > div:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 768px) {
    .form-group-multi > .fg-half {
      width: 50%;
      max-width: 50%;
      padding-right: 20px;
    }
  }

  @media (min-width: 768px) {
    .form-group-multi > .fg-half.fg-right {
      padding-right: 0;
      padding-left: 20px;
      margin-left: auto;
    }
  }

  label {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #182546;
    margin-bottom: 10px;
    display: block;
    z-index: 1 !important;
    max-width: 100%;
  }

  input[type="text"].form-control,
  input[type="password"].form-control,
  input[type="email"].form-control,
  input[type="number"].form-control,
  select {
    height: 65px;
    min-height: 65px;
  }

  button.btn.btn-primary.btn-lg {
    background: #1563ec;
    box-shadow: none !important;
    padding: 16px 30px;
    min-width: 150px;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .form-control {
    color: #182546;
  }

  .form-control {
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #182546;
    border: none;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07);
    -webkit-transition: border-color ease-in-out 0s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0s, box-shadow ease-in-out 0.15s;
  }

  .form-control::-webkit-input-placeholder {
    color: #a6aec2;
  }

  .form-control:-moz-placeholder {
    color: #a6aec2;
  }

  .form-control::-moz-placeholder {
    color: #a6aec2;
  }

  .form-control:-ms-input-placeholder {
    color: #a6aec2;
  }

  .form-control:focus,
  .form-control:active {
    border: none;
    outline: none;
    box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18);
  }

  .form-control,
  .select-control {
    border-radius: 3px;
  }

  .form-control.success,
  .select-control.success {
    border: 1px solid #02cd56 !important;
    color: #02cd56 !important;
  }

  .form-control.error,
  .select-control.error {
    border: 1px solid #ed1c24 !important;
    color: #ed1c24 !important;
  }

  .form-control:disabled,
  .select-control:disabled {
    color: #a6aec2;
    background: #f1f6fa;
    box-shadow: none;
  }

  .form-control,
  select {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.2px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 768px) {
    .form-control,
    select {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media (min-width: 500px) {
    .postal-house,
    .postal-pin {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: 500px) {
    .vc-get-quote-steps-indicator li:after {
      width: 40px;
      right: -20px;
    }

    .postal-error {
      display: none;
    }

    .postal-house .error_msg,
    .postal-pin .error_msg {
      margin: 5px 0;
    }

    .vc-your-quote-more-info .vc-your-quote-more-info-top .vc-your-quote-more-info-top-number .number-item p {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .vc-get-quote-steps-indicator li:after {
      width: 15px;
      right: -7px;
    }
  }

  .form-info {
    display: inline-block;
    width: 16px;
    height: 12px;
    background: url("../../img/icon-info.svg") no-repeat center;
    background-size: 12px;
    line-height: 1;
    text-indent: -999999px;
    margin-left: 5px;
    margin-top: 4px;
    cursor: pointer;
  }

  //#HELP INFO ALIGNMENT
  .help-info-icon-alignment {
    position: relative;

    .form-info {
      position: absolute;
    }
  }

  .form-group {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    .form-group {
      margin-bottom: 50px;
    }
  }

  .tooltip-description {
    display: block !important;
  }

  .tooltip-description {
    border: 1px solid #7c89ab;
    padding: 1em;
    border-radius: 3px;
    background: #f4f9ff;
    color: #7c89ab;
    margin-top: 10px;
    display: block;
    opacity: 1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 100% !important;
    margin-left: 0 !important;
    background-color: #eff1f7;
    border-color: #d5d6de;
  }

  p.tooltip-head {
    color: #7c89ab;

    font-size: 17px;
    font-weight: 900;
  }

  p.tooltip-head span {
    height: 17px;
    margin-right: 5px;
  }

  p.toolrip-text {
    font-size: 16px;
    margin-top: 5px;
    font-weight: 400;
  }

  p.toolrip-text b {
    font-weight: 500;
    font-size: 17px;
  }

  .bootstrap-select {
    width: 100% !important;
    border-radius: 3px;
  }

  .bootstrap-select.error {
    border: 1px solid #ed1c24 !important;
    color: #ed1c24 !important;
  }

  .bootstrap-select button:before {
    content: "";
    display: block;
    pointer-events: none;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 5px solid #a6aec2;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -4px;
    z-index: 3;
  }

  .bootstrap-select button:after {
    content: "";
    display: block;
    pointer-events: none;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid #a6aec2;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 4px;
    z-index: 3;
  }

  .select-multi .bootstrap-select > .dropdown-toggle {
    padding-left: 20px !important;
  }

  .bootstrap-select > .dropdown-toggle {
    padding-right: 30px !important;
  }

  .bootstrap-select .btn.dropdown-toggle {
    min-height: 65px !important;
    background: #fff;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07);
    color: #182546;
    font-weight: 500 !important;
    font-size: 15px !important;
    text-transform: none !important;
    white-space: nowrap;
    text-align: left;
  }

  .bootstrap-select.dropup .dropdown-menu {
    top: 100% !important;
    bottom: auto !important;
    margin: 0 !important;
  }

  .bootstrap-select > .dropdown-menu {
    border: none !important;
    box-shadow: 4px 14px 32px 0 rgba(65, 96, 132, 0.18) !important;
    margin: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 1px solid #e6eff7 !important;
  }

  .bootstrap-select > .dropdown-menu li a {
    color: #182546 !important;
    padding: 5px 20px 5px 25px;
    font-size: 15px !important;
    margin: 5px;
    border-radius: 5px;
  }

  .selected a {
    background: #dcecff !important;
    font-style: normal !important;
    // line-height: normal !important;
    color: #000000 !important;
    font-weight: bold !important;
  }

  .date_picker a {
    padding: 10px 20px 10px 10px !important;
  }

  .bootstrap-select > .dropdown-menu li a:hover,
  .bootstrap-select > .dropdown-menu li a:focus {
    background: rgba(220, 236, 255, 0.4) !important;
  }

  .bootstrap-select > .dropdown-menu li a:active {
    background: #dcecff !important;
    color: #000 !important;
    font-weight: bold;
  }

  .options-group {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    .options-group {
      height: 63px;
    }
  }

  .options-group > * {
    margin-right: 40px;
    position: relative;
    top: 4px;
  }

  .options-group > *:last-child {
    margin-right: 0;
  }

  .options p {
    display: inline-block;
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #3a5273;
    margin-right: 15px;
    font-weight: 400;
  }

  .options input {
    display: none;
  }

  .options > label {
    display: inline-block;
    position: relative;
    padding-left: 17px;
    padding-bottom: 1px;
    cursor: pointer;
    color: rgba(28, 38, 68, 0.63);
    line-height: 1;
    font-size: 18px;
    letter-spacing: -0.5px;
  }

  .options > label:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    margin-top: -3px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out 0s;
  }

  .custom-options .options label {
    border: 1px solid #6f7689;
    padding: 18px 50px;
    border-radius: 5px;
    text-transform: uppercase;
    height: 51px;
    font-weight: 900;
    font-size: 15px;

    @include max-375 {
      padding: 18px 40px;
    }
  }

  .no-add-driver {
    height: auto !important;
  }

  .custom-options .options label:before,
  .custom-options .options label:after {
    visibility: hidden;
  }

  .custom-options .options input[type="radio"]:checked ~ label {
    color: #fff;
    background: #1966ff;
    border: 1px solid #1966ff;
    border-radius: 3px;
  }

  label.no-add-driver.btn.btn-outline-blue.btn-lg.add-driver.add-driver-btn {
    letter-spacing: 0.5px !important;
  }

  .no-add-driver input[type="radio"]:checked ~ label {
    color: #fff !important;
    background: #6f7689;
    border: 1px solid #6f7689;
    border-radius: 3px;
  }

  .custom-options .options {
    margin-right: 15px;
  }

  .bootstrap-select > .dropdown-toggle {
    width: 100%;
    padding-right: 25px;
    z-index: 1;
  }

  .bootstrap-select > .dropdown-toggle.bs-placeholder,
  .bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
  .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
  .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #a6aec2;
  }

  .options-group.custom-options {
    padding-top: 15px;
  }

  @media (min-width: 768px) {
    .helphiddendesk {
      display: none !important;
    }
  }

  @media (max-width: 767px) {
    .helphiddenmobile,
    .helphiddenmobiletwo {
      display: none !important;
    }

    .mobdesk {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .select-box label {
      height: 110px;
    }

    .select-box-content svg {
      height: 30px;
    }
  }

  .helphiddenmobile {
    margin-top: -26px !important;
    margin-bottom: 40px !important;
  }

  .mobdesk {
    margin-top: -26px !important;
    margin-bottom: 20px !important;
    margin-left: 1.7em;
    width: 92%;
  }

  .container-fluid {
    max-width: 1110px;
  }

  @media (min-width: 992px) {
    .form-group-btns .btn {
      min-width: 120px !important;
    }

    .tooltip-description {
      max-width: 635px;
      width: 100% !important;
      margin: 20px auto !important;
    }
  }

  .helphiddenmobile {
    margin-top: -2em !important;
    margin-bottom: 40px !important;
  }

  //#QFA
  .vc-get-quote-info-text-wrap {
    min-height: 200px;
  }

  .vc-get-quote-info-text {
    text-align: center;
  }

  .vc-get-quote-info {
    width: 300px;
    float: right;
    padding-right: 15px;
    padding-left: 15px;
  }

  .vc-get-quote-info.aos-animate {
    -webkit-transform: none !important;
    transform: none !important;
  }

  @media (max-width: 991px) {
    /*Custom-Step Counter MObile*/
    .mobile-progress-custom {
      width: 100%;
    }

    .progress-step-section .mobile-header-custom {
      display: block;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li:after,
    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li span.step-text {
      display: none;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li .step-number {
      height: 35px;
      width: 35px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li:nth-child(1) .step-number {
      position: absolute;
      z-index: 2;
      left: -30px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li:nth-child(2) .step-number {
      position: absolute;
      z-index: 1;
      left: -15px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator {
      margin-bottom: 0;
      position: relative;
      right: 20px;
    }

    .progress-step-section .mobile-header-custom .vc-get-quote-steps-indicator li .step-number {
      margin-bottom: 0;
      line-height: 35px;
    }

    .mobile-header-custom {
      display: none;
    }

    .vc-get-quote-info {
      margin-top: 60px;
      display: none;
      visibility: hidden;
    }
  }

  .vc-get-quote-info .vc-get-quote-info-affix {
    display: inline-block;
    max-width: 300px;
    min-width: 270px;
    background: #182546;
    color: #fff;
    border-radius: 3px;
  }

  .vc-get-quote-info .vc-get-quote-info-top {
    margin-left: auto;
    /*padding-top: 40px;
      padding-bottom: 45px;
      padding-left: 85px;
      padding-right: 40px;*/
    position: relative;
    background-color: #1966ff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .vc-get-quote-info .vc-get-quote-info-top img {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .vc-get-quote-info .vc-get-quote-info-top h4 {
    font-size: 9px;
    font-weight: 900;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.64);
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .vc-get-quote-info .vc-get-quote-info-top p {
    font-size: 21px;
    font-weight: 700;
    font-style: normal;
    color: #ffffff;
    line-height: 1.4;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .vc-get-quote-info .vc-get-quote-info-top p {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    .vc-get-quote-info .vc-get-quote-info-top p {
      font-size: 16px;
    }

    .innerLoaderText {
      width: 100%;
    }

    .error-page-text {
      width: 97%;
    }

    .innerLoaderText p {
      margin-top: 2em;
    }
  }

  .vc-get-quote-info .vc-get-quote-info-inner {
    border-radius: 3px;
    background-color: #222c43;
    box-shadow: 0 36px 64px 0 rgba(78, 80, 84, 0.13);
    color: #fff;
    padding: 0px 20px;
    padding-top: 0;
    height: 395px;
  }

  .vc-get-quote-info .vc-get-quote-info-text:last-child {
    margin-top: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vc-get-quote-info .vc-get-quote-info-text h3 {
    font-size: 14px;
    font-weight: 900;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
  }

  .vc-get-quote-info .vc-get-quote-info-text p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: 0.4px;
    color: #ffffff;
    font-weight: lighter;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 140px;
  }

  @media (min-width: 600px) and (max-width: 991px) {
    .vc-get-quote-info-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .vc-get-quote-info-content .vc-get-quote-progress {
      margin-right: 40px;
      width: 183px;
      min-width: 183px;
      height: 183px;
      margin-bottom: 0;
    }
  }

  .custom-vc-get-quote {
    padding: 1em;
    text-align: center;
  }

  .vc-get-quote-progress {
    /* margin: 26px auto; */
    width: 190px;
    height: 107px;
    position: relative;
    margin-top: 5px;
  }

  .vc-get-quote-progress:before {
    content: "Complete";
    font-size: 20px;
    font-weight: 600;
    color: #d5dae7;
    position: absolute;
    margin: auto;
    left: 5em;
    right: 0;
    bottom: 50px;
    text-align: left;
    margin-left: 5px;
    letter-spacing: 0.6px;
    white-space: normal;
  }

  .vc-get-quote-progress:before {
    letter-spacing: 0px;
    font-weight: 400;
  }

  .responsive > svg {
    width: 45% !important;
    height: 45% !important;
  }

  .parking-box-main,
  .van-purchased-main {
    margin-bottom: 20px;
  }

  .select-box-group label {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    .select-box-group .form-group-multi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .select-box-group .form-group-multi > div {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
      flex: 1 1 0;
      margin-right: 20px;
    }

    .vc-your-quote-adjustments .form-group-multi > div:last-child {
      margin-right: auto;
    }

    .select-box-group .form-group-multi > div:last-child {
      margin-right: 0;
    }

    .select-box-group .form-group-multi > div.fg2 {
      -webkit-box-flex: 1.5;
      -ms-flex: 1.5 1 0px;
      flex: 1.5 1 0;
    }
  }

  @media (min-width: 1200px) {
    .select-box-group .form-group-multi > div {
      margin-right: 20px;
    }

    .select-box-group .form-group-multi > div:last-child {
      margin-right: 0;
    }
  }

  .select-box input {
    display: none;
    visibility: hidden;
  }

  .select-box label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    padding: 10px;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.03);
    border-bottom: 6px solid #fff;
    text-align: center;
    height: 155px;
    position: relative;
  }

  .select-box label:before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    background: url("../../img/icon-check3.svg") no-repeat center;
    background-size: 22px;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.2s ease-in-out 0s;
    opacity: 0;
  }

  .select-box label:hover,
  .select-box label:focus,
  .select-box label:active {
    cursor: pointer;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07);
  }

  .select-box label .select-box-content svg {
    transition: all 0.2s ease-in-out 0s;
  }

  .select-box label .select-box-content p {
    font-size: 12px;
    font-weight: 400;
    margin-top: 20px;
  }

  .select-box input:checked + label {
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.09);
    border-bottom: 6px solid #1966ff;
    color: #050f28;
  }

  .select-box input:checked + label:before {
    opacity: 1;
  }

  .select-box input:checked + label .icon-van-parked1 g {
    fill: #050f28;
  }

  .select-box input:checked + label .icon-van-parked1 .key {
    stroke: #050f28 !important;
  }

  .select-box input:checked + label .icon-van-parked1 .circ {
    fill: #fff;
  }

  .select-box input:checked + label .icon-van-parked1 .circ rect {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked2 g {
    fill: #050f28;
  }

  .select-box input:checked + label .icon-van-parked2 .lines {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 g {
    fill: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .car-copy {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .lines {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .parking path {
    stroke: #050f28;
  }

  .select-box input:checked + label .icon-van-parked3 .parking .p {
    stroke: none;
  }

  .parking-box .form-group,
  .van-purchased .form-group {
    margin-bottom: 0;
  }

  .parking-box-main,
  .van-purchased-main {
    margin-bottom: 20px;
  }

  .select-multi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
    transition: all 0.2s ease-in-out 0s;
    position: relative;
  }

  .select-multi > * {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-right: 5px;
    border: none;
    box-shadow: 4px 14px 32px 0 #4160842e;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #e6eff7;
    overflow: scroll;
    max-height: 306px;

    &.open {
      display: block;
    }

    ul {
      li {
        a {
          color: #182546;
          padding: 5px 20px 5px 25px;
          font-size: 15px;
          margin: 5px;
          border-radius: 5px;
          display: block;
          border-radius: 5px;
          font-weight: 500;
        }

        cursor: pointer;
        display: block;

        &.selected,
        &:hover,
        &:active {
          a {
            background: #dcecff;
          }
        }

        &.selected {
          font-weight: bold;
        }
      }

      // &.date-pick {
      //     max-height: 306px;
      // }
    }

    .date-pick {
      max-height: 306px;
      margin: 0;

      li {
        padding: 0;
        position: relative;

        a {
          padding: 10px 0 !important;
          text-align: center;
        }
      }
    }

    ul::-webkit-scrollbar,
    .auto-complete-box::-webkit-scrollbar,
    .pop::-webkit-scrollbar {
      width: 6px;
    }

    ul::-webkit-scrollbar-track,
    .auto-complete-box::-webkit-scrollbar-track,
    .pop::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background: transparent;
    }

    ul::-webkit-scrollbar-thumb,
    .auto-complete-box::-webkit-scrollbar-thumb,
    .pop::-webkit-scrollbar-thumb {
      background-color: lightgrey;
      outline: none;
      border-radius: 10px;
      height: 50px;
    }
  }

  .select-multi .bootstrap-select {
    border-right: 1px solid #e6eff7;
    position: static;
  }

  .select-multi .bootstrap-select .btn.dropdown-toggle {
    box-shadow: none !important;
    border-radius: 0;
  }

  .remove-border-right {
    border-right: none !important;
  }

  button.bradius-left.btn.dropdown-toggle.bs-placeholder.btn-default,
  button.bradius-left.btn.dropdown-toggle.btn-default {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }

  button.bradius-right.btn.dropdown-toggle.bs-placeholder.btn-default {
    border-top-right-radius: 3px !important;
  }

  button.bradius-right.btn.dropdown-toggle.btn-default {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .stickAssistant {
    width: 270px;
    position: fixed;
    top: 150px;
    z-index: inherit;
  }

  .progressbar-text {
    font-size: 25px !important;
    font-weight: 700;
  }

  .progressbar-text:after {
    content: "%";
  }

  @media (max-width: 767px) {
    .helphiddenmobile,
    .aboutjobhelp,
    .helphiddenmobiletwo {
      display: none !important;
    }

    .abouthelp {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .abouttrade {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .mobdesk {
      margin-left: 0em !important;
      width: 100% !important;
    }

    .select-box label {
      height: 110px;
    }

    .select-box-content svg {
      height: 30px;
    }
  }

  .registration-search {
    background-image: url("../../img/board.svg"), url("../../img/icon-search.svg");
    background-color: #fff;
    background-repeat: repeat-y, no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    font-family: uknumber;
    font-size: 2em;
    text-transform: uppercase;
    background-size: 28px, 15px;
    background-position: left center, 95% center;
    // line-height: 65px;
    color: #182546;

    &:focus {
      border: none;
      outline: none;
      box-shadow: 4px 14px 32px 0 #4160842e;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: #a6aec2;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a6aec2;
    }

    &::placeholder {
      color: #a6aec2;
    }
  }

  .load {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/spinner-preloader.svg");
  }

  .cancel {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/cancel.svg");
  }

  .checked {
    background-size: 26px, 25px;
    background-image: url("../../img/board.svg"), url("../../img/checked.svg");
  }

  .loading-status {
    background-image: url("../../img/spinner-preloader.svg");
  }

  .success-status {
    background-image: url("../../img/checked.svg");
  }

  .error-status {
    background-image: url("../../img/cancel.svg");
  }

  .thisismyvan {
    color: #fff;
    background: #1563ec;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid #1563ec !important;
    width: 100% !important;
    padding: 21px 30px;
    min-width: 170px;
    font-weight: bold;
  }

  .thisismyvan:hover,
  .thisismyvan:active:hover,
  .thisismyvan:active,
  .thisismyvan:focus {
    background: #1563ec !important;
    color: #fff !important;
  }

  .van-active {
    background-color: #1563ec !important;
    color: #fff !important;
  }

  .van-active span:before {
    background: url("../../img/check-thick.svg") no-repeat center;
    content: "tick";
    color: transparent;
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }

  .help-alignment {
    //MULTI SELECT BOX
    @media (min-width: 768px) {
      .select-box-group {
        .tooltip-description {
          margin-top: 0 !important;
        }
      }

      #parked-overnight {
        .error_msg {
          margin-bottom: 10px !important;
          margin-top: -10px !important;
        }
      }
    }

    //LESS TEHN 768
    @media (max-width: 767px) {
      //#RADIO BUTTONS
      .options-group.custom-options {
        padding-top: 0 !important;

        .options {
          top: 0 !important;
        }

        label {
          margin-bottom: 0 !important;
        }
      }

      //MULTI SELECT BOX
      .select-box-group {
        .select-box {
          label {
            &[for="select-box3"] {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .header-help-info {
    @media (max-width: 320px) {
      margin-top: -10px !important;
    }
  }

  .vc-get-quote-form {
    float: left;
    padding-bottom: 80px;
  }

  .input-money {
    position: relative;
  }

  .input-money:before {
    content: "£";
    display: block;
    pointer-events: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(25, 102, 255, 0.05);
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    line-height: 32px;
    color: #30406a;
  }

  .input-money input {
    padding-left: 67px;
  }

  .driver-form-aligner {
    @media (min-width: 1200px) {
      margin-left: 57px;
    }
  }

  .form-preview {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 6px;

    .name-label {
      font-size: 18px;
      color: #1563ec;
      font-weight: bold;
    }

    @media (min-width: 1200px) {
      padding: 30px;
    }

    @media (max-width: 1199px) {
      padding: 20px;
    }

    @media (min-width: 576px) {
      .name-label {
        font-size: 20px !important;
      }
    }

    .position {
      color: #000 !important;
      font-size: 18px !important;
      font-style: italic;

      @media (min-width: 768px) {
        border-left: 1px solid rgba(49, 49, 49, 0.351644);
        margin-left: 8px;
        padding-left: 8px;
      }

      @media (max-width: 767px) {
        display: block;
      }
    }

    .address_marital,
    .licence_status,
    &__title {
      font-size: 18px !important;
      color: #000 !important;
      font-weight: 500 !important;
    }

    //#ACTION BUTTON
    &__action-btn {
      border: 0;
      background: transparent;
      padding: 0;
      color: #3662ff;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: -27px;
      display: block;
      margin-left: auto;

      @media (max-width: 575px) {
        font-size: 15px;
      }
    }

    &__namelist {
      margin-top: 5px;

      .name-label {
        margin-top: 0;
      }
    }
  }

  .license_address span {
    display: block;
  }

  @media (max-width: 500px) {
    .position {
      display: block;
    }

    .license_address {
      margin-top: 8px !important;
    }
  }

  @media (min-width: 1200px) {
    .container-fluid--width-xl {
      max-width: 1150px;
    }
  }

  .dotted-button {
    border: 2px dashed #1563ec;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1563ec;
    width: 100%;
    background: transparent;
    padding: 24px 12px;
  }

  //#primary-form
  .primary-form {
    background-color: #fff;
    border-radius: 6px;
    margin-top: 30px;

    .about-you-button {
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      padding: 30px;
    }

    @media (max-width: 1199px) {
      padding: 20px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #a2bfdf;
      padding-bottom: 30px;

      &-left {
        display: flex;
        align-items: center;
      }

      &-title {
        font-weight: bold;
        font-size: 26px;
        color: #182546;
        margin-left: 14px;
        margin-bottom: 0;
      }

      @media (max-width: 575px) {
        &-title {
          font-size: 20px;
          margin-left: 12px;
        }

        &-icon {
          max-width: 35px;
        }
      }
    }

    //#BUTTONS
    &__transparent-btn {
      border: 0;
      background-color: transparent;
      color: #3662ff;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }

    //#ELEMETS ALIGNMENTS
    @media (max-width: 1199px) and (min-width: 992px) {
      //#RADIO BUTTONS
      .custom-options .options label {
        padding: 18px 48px;
      }

      //#DATE PICKER
      .select-multi .bootstrap-select > .dropdown-toggle {
        padding-left: 12px !important;
      }

      .bootstrap-select > .dropdown-toggle {
        padding-right: 27px !important;
      }
    }

    @media (max-width: 575px) {
      //#DATE PICKER
      .select-multi .bootstrap-select > .dropdown-toggle {
        padding-left: 12px !important;
      }

      .bootstrap-select > .dropdown-toggle {
        padding-right: 27px !important;
      }
    }
  }

  .foradditionaldriver,
  .foreditdriver {
    margin-top: 61px;
  }

  .change-driver-col {
    @media (max-width: 575px) {
      padding: 0;
    }
  }

  .yourtick {
    display: inline;
  }

  .tick-radio:before,
  .tick-radio:after {
    border-radius: 3px !important;
  }

  .tick-radio {
    font-size: 25px !important;
    bottom: 2px;
  }

  .tick-radio:after {
    content: "✓" !important;
    color: #1966ff;
    transition: none !important;
    margin-top: -13px !important;
    background: transparent !important;
  }

  .cust-rad input:checked + label:before {
    background: #fff !important;
  }

  .cust-rad input:checked + label:after {
    left: 2px !important;
  }

  .options > label:after {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: #fff;
    margin-top: -3px;
    opacity: 0;
    border-radius: 50%;
    transition: all 0.2s ease-in-out 0s;
  }

  .options input:checked + label:after {
    opacity: 1;
    width: 6px;
    height: 6px;
    top: 7px;
    left: 7px;
  }

  .terms-label {
    font-size: 15px !important;
    font-weight: 500;
    margin-left: 10px;
  }

  textarea.form-control {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (min-width: 768px) {
    textarea.form-control {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .uploader-wrapper {
    &--transparent {
      #dropZone,
      .file-drop-wrapper,
      .upload-box {
        background: transparent !important;
      }
    }

    // cursor: pointer;
    #fileDrop {
      overflow: visible !important;
    }

    .file-drop-wrapper {
      padding: 0 !important;
    }

    .custom-drag {
      border: 0 !important;
    }

    file-drop {
      padding: 0 !important;
    }

    file-preview-item,
    #fileDrop,
    .custom-drag {
      max-width: 100% !important;
    }

    file-preview-item {
      margin-top: 10px;
    }

    file-preview-item {
      margin-top: 10px;
      background: rgba(255, 255, 255, 0.370726);

      .file-preview-title {
        p {
          text-align: left;
        }
      }
    }

    file-preview-container {
      background: transparent;
      cursor: default;
    }

    #dropZone {
      padding: 0px !important;
      box-shadow: 4px 6px 13px 0 rgba(66, 97, 133, 0.071);
      border-radius: 4px;
    }

    //#check icons
    .ngx-checkmark-wrapper {
      width: 28px !important;
      height: 28px !important;

      .ngx-checkmark {
        width: 100% !important;
        height: 100% !important;

        &:after {
          height: 14px !important;
          width: 7px !important;
          border-width: 0 2px 2px 0 !important;
          top: 5px !important;
          left: 11px !important;
        }
      }
    }

    //#CLOSE ICON
    .ngx-close-icon-wrapper {
      height: 28px !important;
      width: 28px !important;

      close-icon {
        .ngx-close-icon {
          width: 100% !important;
          height: 100% !important;

          &:after,
          &:before {
            top: 10px !important;
            width: 18px !important;
            left: 2px !important;
          }
        }
      }
    }

    refresh-icon {
      position: absolute;
      min-width: 150px;
      width: 100%;
      min-height: 50px;
      background: red;
      left: 39px;
      margin-right: 0 !important;
      font-family: "Brandon";
      font-weight: bold;
      padding: 0 6px;
      border-radius: 2px;
      color: #fff;

      @media (max-width: 991px) {
        left: -106px;
        top: 50px;
      }

      &:before {
        content: "Opps !";
        display: block;
        font-size: 12px;
        font-weight: bold;
      }

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid red;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 991px) {
          top: -14px;
          left: 62%;
          right: 0;
          text-align: center;
          transform: rotate(90deg);
          margin: auto;
        }
      }

      .upload-refresh-icon {
        transform: rotateZ(0deg) !important;
        font-family: "Brandon";
        font-size: 10px;
        width: 100%;
        border: 0;
        margin: 0 !important;
        color: #fff;

        &:hover {
          color: #fff;
        }

        &:before {
          content: "Something went wrong" !important;
          display: inline-block;
          transform: rotate(0);
          width: 75%;
          position: relative;
          border: 0;
          left: auto;
          right: auto;
          top: auto;
          height: 100%;
          pointer-events: none;
        }

        &:after {
          content: "Retry";
          display: inline-block;
          text-decoration: underline;
          border: 0;
        }
      }
    }

    .file-upload-error-wrapper {
      height: 100% !important;
    }

    .file-preview-thumbnail {
      margin: auto;
    }

    .file-upload-percentage-wrapper,
    .file-upload-progress-bar,
    .file-upload-progress-bar-wrapper {
      height: 100% !important;
      border-radius: 0 !important;
    }

    //#FILE UPLOAD PERCENTAGE
    .file-upload-percentage {
      padding-top: 28px !important;
      padding-right: 61px !important;

      @media (max-width: 380px) {
        padding: 42px 31% 0 0 !important;
        font-size: 16px !important;
      }
    }
  }

  //#Upload
  .upload-box {
    background: #fff;
    border-radius: 3px;
    min-height: 26px;
    height: 100%;
    max-height: 180px;
    position: relative;
  }

  .upload-box__file {
    height: 100%;
    width: 100%;
    // opacity: 0;
    display: none !important;
  }

  .upload-box__lable {
    // position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    // top: 50%;
    // transform: translateY(-50%);
    color: #a6aec2;
    font-size: 15px;
    margin-bottom: 0;
    cursor: pointer;

    &-title {
      font-size: 14px;
      letter-spacing: 0.0875px;
      color: rgba(111, 118, 137, 0.6);
    }
  }

  .upload-box__lable--bluetext {
    color: #1c64f6;
  }

  .upload-box__lable-icon {
    display: block;

    img {
      animation: upload 2s ease-in-out infinite alternate;
    }
  }

  @keyframes upload {
    0% {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
    }

    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  .upload-info__text {
    color: #a6aec2;
    font-size: 15px;
    font-weight: bold;
    margin-top: 4px;
  }

  .upload-box-help {
    margin-top: 35px;
  }

  .upload-box-help-list {
    margin-top: 13px;

    p {
      margin: 0;
    }
  }

  .file-uploading {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
      opacity: 0.9;
      background: url("../../img/loader.gif") #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 27px;
    }

    refresh-icon ~ .ngx-close-icon-wrapper {
      background: green !important;
    }
  }

  .uploader-wrapper {
    position: relative;

    .dropzoneTemplate {
      padding: 20px 0;
      cursor: pointer;
    }
  }

  //#VERIFY FILES
  .verify-filesupload {
    #fileDrop {
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }

    .file-drop-wrapper {
      margin-bottom: 25px;
    }

    file-preview-item {
      margin: 5px 0 10px;
      background: #fff;
    }
  }

  ul.auto-complete-box {
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    list-style-type: none;
    position: absolute;
    width: 100%;
    -webkit-padding-start: 0;
    border-radius: 0 0 3px 3px !important;
    box-shadow: 4px 6px 13px 0 rgba(65, 96, 132, 0.07) !important;
    z-index: 4;
    box-sizing: border-box;
    border-top: 1px solid #dcecff;
    border-right: 5px solid white;
  }

  ul.auto-complete-box li a {
    margin: 5px;
    border-radius: 5px;
  }

  ul.auto-complete-box li a:hover {
    background: #dcecff !important;
    color: #000 !important;
    font-weight: bold !important;
  }

  .auto-complete-main {
    position: relative;
    padding-right: 5px;
  }

  ul.auto-complete-box a {
    color: #6f7689 !important;
    font-size: 15px !important;
    display: -webkit-box;
  }

  ul.auto-complete-box li a {
    padding: 5px 20px 5px 25px;
    color: #182546 !important;
  }

  ul.auto-complete-box a:hover {
    color: #182546 !important;
  }

  .auto-complete input {
    min-height: 63px;
  }
}

//#POLICY
.policy-card {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  margin-bottom: 24px;
  padding: 25px 25px 20px;
  cursor: pointer;
  box-shadow: 0px 2px 15px #b6d7ff;
  border-radius: 10px;
  background-color: $base-color-2;
  width: 100%;

  @include sm-min {
    max-width: calc(50% - 12px);
  }

  @media (max-width: 375px) {
    padding: 18px 15px;
  }

  &__top {
    display: flex;
  }

  &__bottom {
    // display: flex;
    // justify-content: space-between;
    margin-top: 20px;
    align-items: center;
  }

  &__icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 14px;
    position: relative;
    background: linear-gradient(225deg, #3662ff 0%, #1966ff 100%);

    &-media {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      max-height: 16px;
      max-width: 26px;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  //#TITLE
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: #26385d;
    display: flex;

    img {
      margin-left: 12px;
    }
  }

  &__sub-title {
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    color: #909eb9;
  }

  //#TEXT
  &__text {
    font-weight: 700;
    font-size: 14px;

    &-primary {
      color: #000000;
    }

    &-secondary {
      color: #a7a7a7;
    }
  }

  &__price {
    font-weight: 700;
    font-size: 20px;
    color: #3662ff;
  }

  &__progress {
    position: relative;
    margin-top: 5px;

    &:after {
      content: "";
      display: block;
      width: 100%;
      border-radius: 6px;
      height: 4px;
      background: #dedede;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &-fill {
      display: block;
      position: relative;
      z-index: 2;
      width: 0%;
      height: 11px;
      background: linear-gradient(225deg, #3662ff 0%, #1966ff 100%);
      box-shadow: 0px 2px 5px rgba(54, 98, 255, 0.652259);
      border-radius: 6px;
    }
  }

  &__name {
    font-weight: 700;

    @include md-min {
      font-size: 26px;
    }

    @include md {
      font-size: 18px;
    }
  }

  &__deatils {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    color: #000000;
  }

  &__display-no {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;
    margin-top: 3px;
    color: #000000;
  }

  &__validity {
    &-label {
      color: #909eb9;
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 0.253208px;
      text-transform: uppercase;
    }

    &-date {
      font-size: 20px;
      color: #000;
      font-weight: bold;

      @include md-min {
        font-size: 20px;
      }

      @include md {
        font-size: 16px;
      }
    }
  }
}

.policy-overview-title {
  color: #949595;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;

  span {
    color: #000;
  }
}

.policies-top-blue-section {
  /* background: url('/assets/img/policies-alt/policy-blue-bar.png') no-repeat; #1966ff*/
  background-image: url("/assets/img/policies-alt/grad-bg.png");
  background-repeat: no-repeat;
  background-position: left;
  background-color: #3662ff;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 30px;
}

.policies-top-blue-section__align-middle {
  height: 100%;
  width: 100%;
  // display: table-cell;
  // vertical-align: middle;
  display: flex;
  min-height: 108px;
  flex-direction: column;
  justify-content: center;
}

.policy-staus {
  color: #fff;
  letter-spacing: 1.37561px;
  text-transform: uppercase;
  font-size: 12.6098px;
  background-repeat: no-repeat;
  padding-left: 26px;
  font-weight: 900;

  &.cancelled {
    background-image: url("/assets/img/policies-alt/cancelled.svg");
  }

  &.insured {
    background-image: url("/assets/img/policies-alt/insured.svg");
  }

  &.validating {
    background-image: url("/assets/img/policies-alt/validating.svg");
  }

  &.lapsed {
    background-image: url("/assets/img/policies-alt/lapsed.svg");
  }
}

.policies-top-blue-content {
  padding: 12px 25px;

  @media (max-width: 767px) {
    padding: 20px 20px;
  }

  @media (max-width: 360px) {
    display: block;
  }
}

.policies-top-blue__title {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.policies-top-blue__img {
  max-width: 22px;
  width: 100%;
  height: 100%;
  max-height: 16px;
}

.policies-top-blue__img-text {
  font-weight: bold;
  margin-left: 11px;
  text-transform: capitalize;
  color: #fff;
}

.button-loader,
.btn-next.button-loader {
  position: relative;
  color: transparent !important;
  opacity: 0.5;
  pointer-events: none;

  .btn-arrow {
    display: none;
  }

  .proceed-svg-hide {
    display: none;
  }

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-left-color: transparent;
    animation: loaderSpin 1.5s infinite linear;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  &--lg {
    &:before {
      width: 27px;
      height: 27px;
      border-width: 3px;
    }
  }

  .magic-button__text {
    opacity: 0;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.mta-payments {
  margin-top: 45px;
  padding-bottom: 180px;

  .hidden-xs {
    @include xs {
      display: none !important;
    }
  }

  .hidden {
    display: none !important;
  }

  //#RADIO BTNS
  .payable-payment-radio {
    .confirm-radio {
      font-style: normal;
      font-weight: 600;

      label {
        padding-top: 6px;
        padding-left: 40px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          margin-top: -3px;
          box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.051);
          transition: all 0.2s ease-in-out 0s;
          border: 4px solid #dee7f1;
          border-radius: 50% !important;
        }
      }

      input:checked + label:before {
        background-color: #02cd56 !important;
        color: #fff;
      }

      .tick-radio:after {
        color: #fff !important;
        top: 15px !important;
        left: 7px !important;
      }
    }

    .radio-tickmark {
      position: relative;

      &:after {
        content: url("/assets/img/icons/radio-tick.svg");
        display: block;
        position: absolute;
        left: 7px;
        top: -1px;
        transition: all 0.2s ease-in-out 0s;
      }

      &:before {
        height: 28px !important;
        width: 28px !important;
      }
    }
  }

  //#PAYABLE USER DEATILS SECTION
  .payabel-details-section {
    padding: 14px 20px;
    background-color: #f6faff;
    border-top: 1px dashed #aec6e8;
    border-bottom: 1px dashed #aec6e8;
    position: relative;

    &:after,
    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      height: 25px;
      width: 13px;
      top: -15px;
      background: #f8f9fa;
    }

    &:after {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      right: 0;
    }

    &:before {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      left: 0;
    }
  }

  //#PAYABLE PAGES
  $primary-border: 1px dashed #aec6e8;

  .pre-payment-titles-wrappers {
    margin-bottom: 39px;
  }

  .pre-payment-main-title {
    font-size: 36px;
    line-height: 42px;
    font-weight: bold;
    color: #3e537e;
    margin: 0;
    align-items: center;

    @media (max-width: 475px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  /* ====================
    #AUTO COLLAPSE LIST
  ======================= */
  .auto-collapse-box {
    border-radius: 4px;
    align-items: center;
    transition: height 4s linear;
    height: auto;
    margin-bottom: 12px;

    &.show-collapse {
      height: auto;
      box-shadow: 0px 2px 15px #b6d7ff;

      .auto-collaspe-collapsed-section {
        display: block;
      }
    }
  }

  .auto-collapse-heading-wrapper {
    margin-left: 25px;
    align-items: center;

    @media (max-width: 767px) {
      margin-left: 23px;
    }
  }

  .auto-collapse-heading {
    font-size: 18px;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    font-weight: bold;
    color: #353a41;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 16px;
      letter-spacing: normal;
    }
  }

  .auto-collapse-right-side .auto-collapse-heading {
    color: #353a41;
  }

  .auto-collapse-visable-section {
    background-color: #fff;
    padding: 17px 20px;
    align-items: center;
  }

  .auto-collaspe-collapsed-section {
    display: none;
    background-color: #f6faff;
    padding: 0px 20px 24px;
    border-top: $primary-border;

    &.auto-collaspe-collapsed-section--accordion {
      padding: 0;
      background-color: #fff;
    }
  }

  .auto-collapse-right-side,
  .auto-collapse-left-side {
    align-items: center;
  }

  .auto-collaspe-collapsed__content {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 13px;
    }

    &.auto-collaspe-collapsed__content--accordion {
      align-items: center;
      padding: 16px 20px;

      @media (min-width: 768px) {
        padding-top: 13px;
      }
    }
  }

  .auto-collaspe-collapsed__content-text {
    color: #909eb9;
    font-size: 14px;
  }

  //#accordion
  .accordion-collapse-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height, opacity 200ms ease-in;
    background-color: #f6faff;
  }

  .accordion-collapse-open {
    ~ .accordion-collapse-content {
      max-height: 100%;
      overflow: visible;
      opacity: 1;
      transition: max-height, opacity 200ms ease-in;
      padding: 10px 20px;

      @media (max-width: 575px) {
        padding: 10px 20px;
      }
    }

    .collapse-icon {
      transform: rotate(-180deg);
      transition: transform 300ms linear;
    }
  }

  .add-on-plains__sub-text {
    font-size: 14px;
    color: #909eb9;
    margin-top: 3px;

    @media (min-width: 575px) {
      min-width: 371px;
      padding-right: 25px;
      width: 100%;
      text-align: justify;
    }
  }

  .add-on-plains {
    align-items: center;
  }

  .add-on-plains-left-side {
    width: 87%;

    @media (min-width: 748px) {
      align-items: center;
    }

    /*     @media (max-width:1199px) and (min-width:992px){
        display: block;
      } */
    // @media (max-width:747px) and (min-width:576px){
    //   display: block;
    //   width: 80%;
    // }
    @media (max-width: 747px) {
      flex-direction: column-reverse;
      width: 80%;
    }
  }

  //RAYZ CSS
  //#Variables for component
  $font-family: "Brandon";

  /*=================================
    #ADDONS COMPONENT: LEFT SECTION - CONFIRMATION
  =================================*/
  $addon-padding: 50px;

  .addons-component {
    background-color: #fff;
    margin-top: 20px;
    padding: 30px 24px 22px;

    &__top-section {
      border-bottom: 1px dashed #aec6e8;
      padding-bottom: 25px;
    }

    &__bottom-section {
      padding: 28px 26px;
      background: #f6faff;
      border-radius: 4px;
      margin-top: 15px;
    }
  }

  //#Fonts and text: Top Section
  .addons-component {
    &__top-section {
      h3 {
        font-size: 18px;
        letter-spacing: 1.125px;
        text-transform: uppercase;
        color: #3e537e;
        font-family: $font-family;
        font-weight: 500;
        margin-bottom: 30px;
      }

      p {
        font-size: 14px;
        color: #909eb9;
        font-weight: $font-family;
        line-height: 18px;
      }
    }
  }

  //#Circular Radio
  .mr-30 {
    margin-right: 30px;
  }

  .confirm-radio {
    font-style: normal;
    font-weight: 600;
    font-family: $font-family;

    label {
      padding-top: 6px;
      padding-left: 40px;

      &::before {
        height: 36px;
        width: 36px;
        border: 4px solid #dee7f1;
        border-radius: 50% !important;
      }
    }

    input:checked + label:before {
      background-color: #02cd56 !important;
      color: #fff;
    }

    .tick-radio:after {
      color: #fff !important;
      top: 15px !important;
      left: 7px !important;
    }
  }

  .form-group--text-right {
    text-align: right;
  }

  .confirm-fg {
    .form-group {
      margin-bottom: 0;

      .label-agree {
        margin-bottom: 0;
      }
    }
  }

  /*=================================
    #PAYABLE BOX: RIGHT SECTION
  =================================*/
  $payable-padding: 20px;

  .payable-amt__box {
    background-color: #fff;
    border-radius: 0 0 7px 7px;

    p + p {
      margin-top: 0;
    }
  }

  .payable-amt__title-box {
    padding: $payable-padding;
    text-align: center;
    padding-bottom: 0;

    h3 {
      margin-bottom: 0;
      font-family: $font-family;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  /*===============================
        #Staus Divider Line
  ===============================*/
  $size: 15px;

  //#Payable Values
  .payable-amt__values {
    padding: $payable-padding;
    padding-top: 0;

    @media (max-width: 500px) {
      &--padding-bottom {
        padding-bottom: 0;
      }
    }
  }

  .payable-amt__rows {
    margin-bottom: 20px;

    @media (min-width: 992px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .payable-abt__values-label {
    font-family: $font-family;
    font-size: 16px;
    line-height: 27px;
    color: #3e537e;
    mix-blend-mode: normal;
    opacity: 0.75;
    font-weight: 600;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 13px;
    }

    &--sm {
      font-size: 14px;
    }
  }

  .payable-amt__values-value {
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    text-align: right;
    text-transform: uppercase;
    color: #3e537e;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 22px;
    }

    &--font-18 {
      font-size: 18px;
    }

    &--sm {
      font-size: 16px;
    }
  }

  //#Addon List
  .payable-amt__addons-section {
    margin-top: 30px;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .payable-amt__addons-box {
    max-height: 150px;
    height: 100%;
    overflow-y: auto;

    .payable-amt__addons-list {
      margin-bottom: 9px;

      /* &:last-child {
        margin-bottom: 0;
      } */
    }
  }

  .payable-amt__addons-box {
    padding: $payable-padding;
    border: 1px dashed #aec6e8;
    background-color: rgba(255, 224, 196, 0.0784313725490196);
  }

  .payable-amt__addons-img-container {
    width: 35px;
    height: 35px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      max-height: 33px;
      display: block;
    }
  }

  .payable-amt__addons-text-container {
    p {
      @extend .payable-abt__values-label;
      font-weight: 500;
    }
  }

  //#total-payable-button
  .total-payable-btn {
    padding: 20px;
    border: 0;
    background: none;
    background-color: #1865ff;

    @media (min-width: 992px) {
      background-color: #f6fafe;
    }

    border-radius: 0 0 7px 7px;
    width: 100%;
    max-width: 100%;
    box-shadow: none;

    @media (min-width: 992px) {
      pointer-events: none;
    }

    &--padding-bottom {
      @media (max-width: 500px) {
        padding-bottom: 10px;
      }
    }

    &--radious-0 {
      border-radius: 0;
    }

    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
    }

    &__label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 27px;
      /* identical to box height, or 169% */
      text-transform: uppercase;
      color: #182546;

      @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 14px;
      }

      @media (max-width: 991px) {
        color: #fff;
      }
    }

    &__value {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
      text-align: right;
      text-transform: uppercase;
      color: #182546;

      @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 19px;
      }

      @media (max-width: 991px) {
        color: #fff;
      }

      @media (max-width: 500px) {
        font-size: 19px;
      }
    }
  }

  //#CART BAR
  .cart-menu {
    background: #1865ff;
    box-shadow: 0px 9px 10px rgba(93, 128, 188, 0.296875);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;

    @media (min-width: 992px) {
      display: none;
    }

    .cart-menu-wrapper {
      display: flex;
      align-content: center;
      padding: 18px 12px;
      max-width: 500px;
      justify-content: space-between;
      margin: auto;
    }

    .cart-text {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      margin: 0;

      @media (max-width: 500px) {
        font-size: 13px;
      }
    }

    .cart-pipe {
      padding: 0 14px;
    }

    .view-cart-button {
      svg {
        @media (max-width: 500px) {
          height: 20px;
        }
      }
    }

    .view-cart-text {
      padding-right: 13px;
    }
  }

  .agree-radio-buttons {
    @media (max-width: 467px) {
      display: block;

      .form-group--text-right {
        text-align: left;
        text-align: left;
        margin-top: 12px;
      }
    }

    display: flex;
    justify-content: space-between;
  }

  //#POP UP
  .payable-amt-section--hiiden-mobile {
    max-width: 360px;
    width: 100%;

    @media (max-width: 1199px) and (min-width: 992px) {
      max-width: 293px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .cart-modal-dilog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 66px;
    margin-bottom: 0 !important;
    min-height: auto;
  }

  .modal-total-payable-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
  }

  // .cart-pop-up-body {
  //     height: calc(100vh - 200px);
  // }

  .cart-pop-up-content {
    background: #fff;
  }

  .add-on-buttons {
    button {
      box-shadow: none !important;
    }

    .custom-back {
      @media (max-width: 479px) {
        display: inline-block !important;
      }
    }

    @media (max-width: 479px) {
      display: flex;
      flex-direction: column;

      button {
        &:first-child {
          order: 1;
        }
      }
    }
  }

  .bottom-menu {
    display: none;
  }

  .payable-payment-radio {
    margin-bottom: 14px;
    margin-top: 20px;
  }

  .cart-modal-dilog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 66px;
    margin-bottom: 0 !important;
    min-height: auto;
  }

  // .cart-pop-up-body {
  //     height: calc(100vh - 142px);
  // }

  .payable-payment-radio {
    .confirm-radio {
      font-size: 16px;
      color: rgba(62, 83, 126, 0.75);
    }
  }

  .add-on-buttons {
    margin-bottom: 50px;
  }

  .payabelSticky {
    position: sticky;
    top: 121px;
    margin-bottom: 20px;

    @media (max-width: 1199px) and (min-width: 992px) {
      top: 105px;
    }
  }

  .doYouConfirm {
    max-width: 168px;
    text-align: left;
  }

  .add-on-row {
    display: flex;
    flex-wrap: wrap;

    &:after,
    &:before {
      display: none;
    }
  }

  .add-on-price {
    margin-top: 15px;
  }

  .payable-payment-radio {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 300px) {
      max-width: 300px;
    }
  }

  //#ADDONS VIEW MORE
  .view-more {
    border: 0;
    padding: 0;
    background: transparent;
    margin-top: 8px;
    font-weight: 600;
    display: block;
    color: #3e537e;
    font-size: 15px;

    @media (min-width: 748px) {
      display: none;
    }
  }

  .payable-payment-radio--center {
    justify-content: center;
  }

  .accordion-collapse-content-body {
    padding-bottom: 30px;
    padding-top: 28px;
    border-bottom: $primary-border;

    &:last-child {
      border-bottom: 0;
    }
  }

  .add-on-plains__sub-text--desk {
    @media (max-width: 747px) {
      display: none;
    }
  }

  .add-on-plains__sub-text--mobile {
    @media (min-width: 748px) {
      display: none;
    }
  }

  .total-payable-btn--deposit {
    padding-top: 0;
  }

  .total-payable-btn--total-monthly {
    padding-bottom: 10px;
  }

  .addons-component__inputs-wrapper {
    margin-top: 25px;
  }

  .addons-component__inputs-box {
    @media (min-width: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      margin-top: 6px;
      margin-bottom: 6px;

      .error_msg {
        margin-bottom: 10px;
      }
    }

    .addons-component__input {
      border: 1px solid #d1deeb;

      &--padding {
        padding: 19px;
      }

      &--email,
      &--phone {
        font-family: "Brandon", sans-serif;
        font-size: 18px;
      }

      &--phone {
        padding-left: 92px;
      }

      .input-mobile-number {
        width: 67px;
        border-right: 1px solid #d1deeb;
        font-size: 18px;
      }
    }
  }

  //#AGREEMENT
  .addons-aggrement-wrapper {
    @media (min-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 767px) {
      margin-top: 35px;
    }
  }

  .aggrement-op-up-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .addons-payble-close-btn {
    width: 100%;
    text-align: right;
    opacity: 1;
    font-size: 35px;
    color: #fff;
  }

  //#AUTO COLLAPSE
  .auto-collapse-box {
    @media (max-width: 991px) {
      .auto-collapse-icon {
        display: none;
      }

      .auto-collapse-heading-wrapper {
        margin-left: 0;
      }
    }

    //#COLLAPSE ICON
    .collapse-icon {
      margin-left: 20px;

      @media (max-width: 767px) {
        margin-left: 17px;
      }

      transition: transform 300ms linear;
    }

    //#COLLAPSE TOGGLE
    &.show-collapse {
      //#COLLAPSE ICON
      .collapse-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .payabel-details {
    &__name {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      color: #3e537e;
    }

    &__vech-name {
      color: #3e537e;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .payable-deatils-text {
    font-size: 22px;
    text-transform: uppercase;
    color: #3e537e;
    font-weight: bold;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 18px;
    }

    @media (max-width: 991px) {
      font-size: 18px;
    }

    &-sub {
      font-size: 17px;

      @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 14px;
      }

      @media (max-width: 991px) {
        font-size: 15px;
      }
    }
  }

  .payable-label {
    color: rgba(62, 83, 126, 0.75);
    line-height: 17px;
    font-weight: 500;
    font-size: 14px;

    @media (max-width: 991px) {
      font-size: 13px;
    }

    &--margin {
      @media (min-width: 992px) {
        margin-top: 15px;
        margin-bottom: 22px;
      }

      @media (max-width: 991px) {
        margin-bottom: 12px;
      }
    }

    &--letterspacing {
      letter-spacing: 1px;
    }
  }

  .payable-vehicals-details {
    padding: 14px 20px;
  }

  .payable-deatils__col {
    margin-bottom: 20px;

    &-top {
      @media (min-width: 992px) {
        margin-bottom: 30px;
      }

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  //#BUTTON
  .payable-button {
    background-color: #1865ff;
    width: 100%;
    padding: 18px 30px;
    border-radius: 0;

    &__text {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
    }

    &__icon {
      position: relative;
      transition: left 300ms linear;
    }

    &:hover,
    &:focus,
    &:active {
      &__icon {
        left: 9px;
      }
    }
  }

  //#PAYABLE FOOTER
  .payable-footer {
    border-top: 1px dashed #aec6e8;

    &__content {
      border-radius: 4px;
      padding: 28px 30px;

      @media (min-width: 992px) {
        background-color: #3e537e;
      }

      @media (max-width: 991px) {
        padding: 15px 0;
        border-bottom: 1px dashed #576c8a;
        margin-left: 15px;
        margin-right: 15px;
      }

      &-wrapper {
        margin-top: 14px;

        @media (max-width: 991px) {
          background-color: #3e537e;
        }
      }

      &-text {
        font-size: 26px;
        font-weight: 600;
        color: #fff;
        margin: 0;

        @media (max-width: 992px) and (min-width: 1199px) {
          font-size: 24px;
        }

        @media (max-width: 991px) {
          font-size: 20px;
          line-height: 23px;
        }
      }
    }

    //#QUESTION SECTION
    &__question {
      justify-content: center;
      margin-top: 23px;
      align-items: center;
      background: transparent;
      border: 0;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 991px) {
        padding: 20px 0;
        margin-top: 0;
      }

      &-text {
        color: #3e537e;
        font-size: 14px;
        margin: 0;
        margin-left: 10px;
        font-weight: 600;
        text-decoration: underline;
      }

      @media (max-width: 991px) {
        &-md-white {
          .payable-footer__question-text {
            color: #fff;
          }

          .payable-footer__question-icon {
            path {
              fill: #fff;
            }
          }
        }
      }

      //#PAYABLE FOOTER
      &--pop-up {
        border-top: 1px dashed #aec6e8;
      }
    }
  }

  //#POP UP
  .paybale-popup__header {
    padding: 14px 20px;

    // border-bottom: 1px dashed #AEC6E8;
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &-text {
        font-size: 18px;
        text-transform: uppercase;
        color: #3e537e;
        font-weight: 600;
        margin-bottom: 0;
        margin-left: 15px;
      }
    }
  }

  //#PAYABLE MODELS BUTTON
  .payment-button-wrapper--payable {
    margin: 0 auto !important;
    max-width: 360px;

    .payment-button {
      border: 2px solid #fff;
      background: transparent;
      color: #fff;
      border-radius: 3px;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      padding: 9px 15px;
      width: calc(50% - 8px);
      max-width: 160px;
    }

    &.single-button {
      justify-content: center;

      .payment-button {
        max-width: 299px;
        width: 100%;
      }
    }
  }

  .payment-buttons__container {
    padding: 22px 15px;
    position: fixed;
    bottom: 0;
    margin: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #1966ff;
  }

  .payment-modal {
    background-color: #232c42b8;
    display: block;
    z-index: 1920;
  }
}

body.blueGradBg {
  background: url("/assets/img/blue-bg.svg") no-repeat;
  background-size: cover;
}
