.bottom-drawer {
  color: $font-secondary;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  animation: slideUp 0.3s;

  &__header {
    box-shadow: 0px -6px 50px rgba(8, 14, 25, 0.354513);
    background-color: $primary-color-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 60px;

    &-text {
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &-close {
      background-color: transparent;
      padding: 0;
    }
  }

  &__body {
    background: #202020;
    box-shadow: 0px 6px 10px rgba(141, 150, 225, 0.0831512);
    padding: 30px 60px;

    &-text {
      font-weight: 500;
      font-size: 25px;
      line-height: 32px;
      letter-spacing: -0.675px;
    }
  }
}

.bottom-drawer-open {
  padding-bottom: 210px;
  height: auto;
}

.bordered-modal {
  padding: 30px 60px;
  background: #fff;
  max-width: 400px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #1966ff;
  border-radius: 6px;
  text-align: center;

  @media (max-width: 575px) {
    padding: 30px;
  }

  &__header {
    font-size: 18px;
    letter-spacing: 0.41434px;
    font-weight: 600;
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.276226px;
    color: #000;

    &-bottom {
      margin-top: 5px;
    }
  }

  &__button {
    width: 100%;
    padding: 15px;
    border: 0;
    margin-top: 20px;
  }
}

.mta-block-modal {
  position: fixed;
  top: 0;
  z-index: 19999;
  bottom: 0;
  margin: auto;
  background: #0d0d0dbf;
  width: 100%;
}

.swal2-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.primary-modal {
  &.swal2-popup {
    width: 90%;
    width: clamp(320px, 90%, 1110px);
    max-height: 93%;
    border-radius: 8px;
    padding: 0;
    overflow: scroll;
  }
  .swal2-html-container {
    margin: 0;
    @include md-min {
      padding: 40px 60px;
    }
    @include md {
      padding: 20px 15px;
    }
  }

  .swal2-close {
    background: url("/assets/img/icons/cross-black.svg") center 20px no-repeat !important;
    background-position: center !important;
    color: transparent !important;
    position: absolute;
    right: 30px;
    top: 18px;
    box-shadow: none !important;
  }

  .swal2-title {
    font-size: 24px;
    color: $font-primary;
    letter-spacing: 0.705882px;
    text-transform: uppercase;
    line-height: 33px;
    font-weight: 700;
    padding: 50px 30px 24px;
    border-bottom: 1px solid #eff3f7;
  }

  //#HEADER
  // &__header {
  //   margin-bottom: 60px;
  //   &-title {
  //     font-size: 24px;
  //     color: $font-primary;
  //     letter-spacing: 0.705882px;
  //     text-transform: uppercase;
  //     line-height: 33px;
  //     font-weight: 700;
  //   }
  // }
}
