.compare {
    &-wrapper {
        padding-bottom: 60px;
        display: flex;
        justify-content: space-between;
        min-width: 1000px;
        //#PRICE TABLE MODIFICATIONS
        .price-table {
            &-wrapper {
                position: relative;
                margin-bottom: 40px;
                &:after,
                &:before {
                    content: "";
                    width: 1px;
                    height: 100%;
                    background: #ffffff;
                    position: absolute;
                    right: 0;
                    opacity: 0;
                    transition: opacity 200ms linear;
                }
                @media (max-width: 1822px) {
                    &.overflowShadow {
                        &:after {
                            box-shadow: -4px -1px 20px 7px #fff;
                            height: 70px;
                            top: 0;
                            opacity: 1;
                        }
                        &:before {
                            // box-shadow: -16px 16px 20px 11px #fff;
                            bottom: 11px;
                            z-index: 2;
                            height: 52px;
                            opacity: 1;
                            background: rgb(255, 255, 255);
                            width: 40px;
                            filter: blur(6px);
                        }
                    }
                }
            }

            .table {
                margin-bottom: 0;
                td {
                    height: auto;
                }
            }
        }
    }
    &-container {
        max-width: 2000px;
        overflow-x: scroll;
    }
    &-col {
        width: 49%;
    }
}
