/************************************************************************************************************\
    FORMS FIELS
\************************************************************************************************************/
@mixin inputActive {
    border-color: #0064b2;
    background-color: $input-bg;
}

.details-form {
    @include sm-min {
        box-shadow: 0px 10px 15px #e6ebf0;
        border-radius: 4px;
        background-color: #fff;
    }
    &__wrapper {
        flex-wrap: wrap;
    }
    &__group {
        @include sm-min {
            width: 50%;
        }
        @include sm {
            width: 100%;
            margin-bottom: 11px;
            background: #fff;
            border-radius: 4px;
        }
    }
    &__input {
        border: 1px solid transparent;
        padding: 9px 13px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        &-wrapper {
            .details-form__input {
                width: 100%;
            }
            @include sm-min {
                width: 53%;
            }
            @include sm {
                width: 80%;
            }
        }
    }
    &__dropdown {
        // position: relative;
        @include sm-min {
            width: 53%;
        }
        @include sm {
            width: 80%;
        }
        .details-form__input {
            width: 100%;
        }
        .dropdown__toggle {
            margin-left: 7px;
            transform: rotate(0);
            transition: transform 200ms linear;
        }
        &.toggle {
            .dropdown__toggle {
                transform: rotate(180deg);
            }
        }
    }
    &__input input {
        border: 1px solid transparent;
        width: 100%;
        background-color: transparent;
        font-size: 16px;
        font-weight: bold;
    }
    &__label {
        min-height: 61px;
        padding: 8px 12px;
        align-items: center;
        margin-bottom: 0;
        position: relative;
        border-bottom: 1px solid #f5f6f7;
        height: 100%;

        @include sm-min {
            border-right: 1px solid #f5f6f7;
        }
        //#TEXT
        &--text {
            width: 47%;
            font-size: 12px;
            letter-spacing: 0.352941px;
            text-transform: uppercase;
            font-weight: bold;
        }

        // &.active {
        //     // box-shadow: 0px 11px 9px rgba(196, 213, 229, 0.523274);
        //     .details-form__input {
        //         @include inputActive;
        //     }
        // }
        .details-form__input:focus,
        .details-form__input:focus-within {
            @include inputActive;
        }
    }

    //#FOOTER
    &__footer {
        border-top: 1px solid #d4dce4;
        padding-top: 30px;
        margin-top: 50px;
    }
}

.payment-input {
    margin-bottom: 18px;
    &__label {
        font-size: 14px;
        color: #909eb9;
        margin-bottom: 0;
    }
    &__field {
        display: block;
        padding: 10px 14px;
        border: 1px solid #e9edf4;
        border-radius: 4px;
        background: #f8f9fa;
        font-size: 16px;
        font-weight: 500;
        color: $font-primary;
        //#SMALL INPUT FIELDS
        &--xl {
            max-width: 92px;
        }

        &::placeholder {
            color: #909eb9;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #909eb9;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #909eb9;
        }
    }
}

.confirm-radio {
    input {
        display: none;
    }
    .tick-radio {
        font-size: 14px;
        font-weight: bold;
        padding-top: 6px;
        padding-left: 40px;
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 28px;
            width: 28px;
            border: 4px solid #dee7f1;
            border-radius: 50%;
        }
    }

    input:checked + .tick-radio:before {
        background-color: #02cd56;
        color: #fff;
    }
    .tick-radio:after {
        content: url("../../img/tick-mark.svg");
        color: #fff;
        position: absolute;
        left: 7px;
        top: 4px;
        opacity: 0;
    }
    input:checked + .tick-radio:after {
        opacity: 1;
    }
}

.rounded-input {
    background-color: #f8f9fa;
    border-radius: 17px;
    border: 1px solid transparent;
    padding: 6px 14px;
    text-align: right;
    font-weight: bold;
    color: $support-font;
    font-size: 14px;
    &:hover,
    &:focus,
    &:active {
        border-color: #0064b2;
    }
    &--hover-none {
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
        }
    }
}

.license-check__label {
    line-height: 14px;
}

.input-area {
    border: 1px solid transparent;
    @include inputActive;
    padding: 8px 12px;
    width: 100%;
    min-width: 90px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    max-width: 90px;
    text-transform: capitalize;

    &::placeholder {
        color: rgba($color: #2c3a55, $alpha: 0.5);
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: rgba($color: #2c3a55, $alpha: 0.5);
    }

    &::-ms-input-placeholder {
        color: rgba($color: #2c3a55, $alpha: 0.5);
    }
}

.search {
    //#CONATINER
    &-container {
        border-radius: 16px;
        background: #f8f9fa;
        padding: 12px;
        display: table;
        max-width: 600px;
        width: 100%;
        @include sm-min {
            padding: 20px 34px;
        }
        @include md-min {
            margin: 80px auto;
        }
        @include md {
            margin: 25px auto 40px;
        }
    }

    //#LABEL
    &-label {
        font-size: 14px;
        color: $labels-font;
        letter-spacing: 0.833333px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
    &-input {
        background: transparent;
        border: 0;
        font-size: 30px;
        font-weight: bold;
        color: $font-primary;
        line-height: 1;

        @include sm {
            font-size: 20px;
        }

        //#PLACEHODLER
        &::placeholder {
            color: rgba($color: $font-primary, $alpha: 0.2);
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba($color: $font-primary, $alpha: 0.2);
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba($color: $font-primary, $alpha: 0.2);
        }
    }
}

.loading-status {
    background-image: url("/assets/img/status/ellipsis-loader.gif");
    background-size: 25px;
}

.success-status {
    background-image: url("/assets/img/status/tick.svg");
    background-size: 20px;
}

.error-status {
    background-image: url("/assets/img/status/error.svg");
    background-size: 20px;
}

.primary-loader {
    background-repeat: no-repeat;

    &.loading-status {
        background-size: 0;
        @include sm-min {
            background-position: right 28px;
        }
        @include sm {
            background-position: right 19px;
        }

        &.loading {
            background-size: 30px;
            .btn__media {
                opacity: 0;
            }
        }
    }
}
