.tab-box {
    background: #f6faff;
    box-shadow: 0px 10px 30px rgba(186, 194, 203, 0.468012);
    border-radius: 10px;
    overflow: hidden;
    &__header {
        background-color: $base-color-2;
        justify-content: space-between;
        overflow: hidden;
        &-btn {
            background-color: transparent;
            border: 0;
            &-text {
                color: #1966ff;
                font-weight: bold;
                position: relative;
                &:after {
                    content: "";
                    width: 100%;
                    height: 4px;
                    background: #1966ff;
                    border-radius: 2.5px;
                    position: absolute;
                    right: 0;
                    transition: transform 200ms linear;
                    transform: scaleX(0);
                    transform-origin: center;
                    @include xs-min {
                        bottom: -36px;
                    }
                    @include xs {
                        bottom: -25px;
                    }
                }
                @include xs-min {
                    margin-left: 12px;
                    font-size: 18px;
                }
                @include xs {
                    font-size: 12px;
                }
            }
            @include xs {
                flex-direction: column;
                align-items: center;
                display: flex;
            }
            &.active {
                .tab-box__header-btn-text {
                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }
        @include sm-min {
            padding: 25px 90px;
        }
        @include sm {
            padding-top: 25px;
            padding-bottom: 25px;
            justify-content: space-around;
            flex-wrap: nowrap;
        }
    }
    &__body {
        @include xs-min {
            padding: 30px;
        }
        @include xs {
            padding: 6px;
        }
    }
    &__button {
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0;
        background: $base-color-2;
        padding: 19px 2px;
        width: 100%;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        @include sm-min {
            max-width: calc(16.6666% - 20px);
        }
        @include sm {
            @include xs-min {
                max-width: calc(20% - 20px);
            }
        }

        @include xs {
            max-width: calc(50% - 20px);
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 5px;
            background: #1966ff;
            border-radius: 0px 0px 12px 12px;
            width: 100%;
            transform: scaleX(0);
            transition: transform 200ms linear;
            transform-origin: center;
        }

        &:hover,
        &:active,
        &:focus {
            box-shadow: 0px 2px 23px #c9daef;
            color: #000;
            &:after {
                transform: scaleX(1);
            }
        }

        //#TEXT
        &-text {
            margin-top: 12px;
            color: rgba(0, 0, 0, 0.597571);
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.215092px;
            text-transform: uppercase;
            font-weight: bold;
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.policy {
    width: 40px;
    height: 40px;
}
