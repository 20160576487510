//#Colors Base
$primary-color: #1563ec;
$primary-color-dark: #185dd7;
$font-primary: #233f6f;
$font-secondary: #fff;
$labels-font: #8a96ab;
$input-bg: #f1f4f7;
$bg-color: #f8f9fa;
$base-color-1: #36b93a;
$base-color-2: #fff;
$support-font: #11274e;
