.support-color-dark {
  color: $support-font;
}

.support-color-light {
  color: rgba($color: $support-font, $alpha: 0.5);
}

.support-box {
  overflow: hidden;
  @include md-min {
    max-width: 380px;
  }
  @include md {
    max-width: 320px;
  }

  &-wrapper {
    box-shadow: 0px 10px 15px #e6ebf0;
    border-radius: 20px;
  }

  &__header {
    background-color: $primary-color;
    padding: 30px;
    padding-bottom: 0;

    @include md {
      padding-top: 20px;
    }

    &-title,
    &-sub-text {
      color: $font-secondary;
      text-align: center;
    }

    &-title {
      font-size: 26px;
      font-weight: bold;
    }

    &-sub-text {
      font-size: 16px;
      font-weight: 500;
    }

    &-content {
      padding-bottom: 40px;

      @include xs {
        padding-bottom: 16px;
      }
    }
  }

  //#TAB MENU
  &__tab-menu {
    border: 0;
    justify-content: space-between;

    li {
      position: relative;
      padding-bottom: 10px;

      a {
        color: $font-secondary !important;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 14px;

        &:after {
          content: "";
          width: 0;
          bottom: 0;
          height: 5px;
          background-color: #3ca0ff;
          box-shadow: 0px -2px 4px rgba(11, 26, 50, 0.146088);
          border-radius: 3px 3px 0 0;
          position: absolute;
          left: 0;
          right: 0;
          transition: width 300ms linear;
        }

        &.active,
        &:hover,
        &:focus,
        &:active {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  //#TAB CONTNET
  &__body {
    &-content {
      &--dark {
        background-color: #202020;
      }

      &--light {
        background-color: $base-color-2;
      }
    }

    //#SCRIPT TAB
    &-script-content {
      padding: 25px;
      padding-bottom: 60px;
      color: $font-secondary;
      font-size: 22px;
      letter-spacing: -0.675px;
      font-weight: 500;
    }

    //#DETAILS
    .details-wrapper {
      padding: 20px;
    }
  }

  &__modal {
    &-placeholder {
      text-align: center;
      position: absolute;
      top: 0;
      background: #202020;
      left: 0;
      right: 0;
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
      &-text {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: -0.5px;
        text-transform: uppercase;
        margin-top: 12px;
      }
    }
    &-trigger {
      padding: 0;
    }
  }
}

.details-content {
  &__top {
    p {
      line-height: 36px;
    }
  }

  &__bottom {
    p {
      line-height: 31px;
    }
  }

  &__total-section {
    p {
      line-height: 34px;
    }
  }
}

.details-addons-box {
  padding: 20px;
  border: 1px dashed #aec6e8;
  border-radius: 3px;
  background-color: rgba(246, 250, 255, 0.5);
  max-height: 150px;
  height: 100%;
  overflow-y: auto;

  &-list {
    margin-bottom: 9px;
  }

  &__img {
    min-width: 53px;
    margin-right: 10px;

    img {
      max-height: 33px;
    }
  }

  &__title {
    width: 100%;
  }
}

.details-footer {
  img {
    max-height: 24px;
  }
}

//#TOOLS
.support-collapse {
  margin-bottom: 2px;

  &__btn {
    padding: 13px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: $support-font;
    font-weight: bold;
    border: 0;
    text-transform: uppercase;
    background-color: #f8f9fa;

    .dropdown-icon {
      transition: transform 400ms linear;
      transform: rotate(0deg);
    }
  }

  &__btn[aria-expanded="true"] {
    .dropdown-icon {
      transform: rotate(-180deg);
    }
  }

  &__content {
    padding: 20px;
  }
}
.enableSidebar {
  .support-box {
    position: absolute;
    top: 0;
    z-index: 1201;
    border-radius: 20px 0 0 20px;
    right: -108%;
    transition: right 400ms linear;
    height: 100%;
    width: calc(100% - 20px);

    &.slideOut {
      right: 0;
    }

    &__body {
      height: calc(100% - 140px);
      overflow-y: scroll;
      background-color: #fff;

      @media (max-width: 575px) {
        height: calc(100% - 92px);
      }

      &-content {
        height: 100%;
      }
    }

    .tab-content {
      height: 100%;
    }
  }
  .sidebar-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(43, 43, 43, 0.141);
    z-index: 1200;
    left: -100%;
    // transition: left 500ms linear;

    &.slideOut-left {
      left: 0;
    }
  }
}
