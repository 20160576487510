.auto-dropdown {
    position: absolute;
    background-color: #fff;
    top: 50px;
    width: 100%;
    ul {
        li {
            color: rgba($color: $font-primary, $alpha: 0.9);
            font-size: 12px;
            letter-spacing: 0.352941px;
            padding: 14px 20px;
            font-weight: 600;
            &:hover,
            &:active,
            &:focus,
            &.active {
                background-color: #e7eff7;
            }
        }
    }
}

//#FILTER
.dropdown-filter {
    background: $font-secondary;
    padding: 6px 0;
    position: absolute;
    width: 100%;
    color: $font-primary;
    box-shadow: -1px 12px 20px 0px rgba(8, 11, 22, 0.32);
    z-index: 2;
    max-height: 214px;
    overflow: auto;
    border-radius: 4px;
    right: 0;
    max-width: 280px;
    padding-top: 0;
    @extend .scrollbar-hidden;
    &__items-wrapper {
        li {
            padding: 4px 18px;
            font-size: 15px;
            font-weight: 400;
            border-radius: 4px;
            letter-spacing: 0.352941px;
            cursor: pointer;
            margin: 3px 0;
            text-align: left;
            line-height: 18px;
            span {
                font-size: 15px;
                letter-spacing: 0.352941px;
                line-height: 18px;
            }
        }
        &:not(.checkbox-menu) li {
            &:hover,
            &:focus,
            &.active {
                background-color: #e7eff7;
            }
        }
    }
}

.primary-dropdown {
    &__btn {
        background: $input-bg;
        border-radius: 2px;
        border: 0;
        min-width: 240px;
        text-align: left;
        padding: 10px 12px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.411765px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $font-primary;

        &:after {
            background: url("../../img/dropdown-icon-solid.svg") no-repeat;
            transform: rotate(180deg);
            transition: transform 300ms linear;
            padding: 4px 7px;
            vertical-align: middle;
            border: 0;
        }

        &[aria-expanded="true"] {
            &:after {
                transform: rotate(0deg);
            }
        }
    }
    &__menu {
        width: 100%;
        @extend .dropdown-filter;
        ul li {
            padding-top: 8px;
            padding-bottom: 8px;
            font-weight: 500;
        }
    }
}
