.page-progress{
    display: flex;
    margin-bottom: 46px;
    &__element{
&-text{
    color:$font-primary;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
        line-height: 18px;
            padding: 14px 17px;
        border-radius: 25px;
    span{
        width: 22px;
        height: 22px;
        border-radius: 22px;
        border: 2px solid rgba(151, 151, 151, 0.144067);
        margin-right: 12px;
        display: inline-block;
        text-align: center;
        font-size: 12px;
    }
}
&.active{
    .page-progress__element-text{
color:#fff;
    background: linear-gradient(180deg, #1A90FF 0%, #1563EC 100%);
        span{
background-color: $base-color-1;
border-color: #fff;
    }
    }
}

    }
}