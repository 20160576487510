.collapse-table{

    &__header,&__collapse-header{
        border: 0;
        border-radius: 2px;
        background-color: $primary-color;
        width: 100%;
        text-align: left;
    }
    &__header{
padding: 22px;
    }
    &__collapse-header{
        padding: 12px;
        .collapse-icon{
                transform: rotate(180deg);
                transition: transform 400ms linear;
            }
        &[aria-expanded="true"]{
            .collapse-icon{
                transform: rotate(0deg);
            }
        }
    }
    &__label{
        color: $labels-font;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 500;
    }
    &__body{
        &-list{
            li{
             &:before{
                 content: '';
                 width: 4px;
                 height: 4px;
                 border-radius: 50%;
                 background-color: $font-primary;
                 margin-right: 4px;
                     display: inline-block;
                         vertical-align: middle;
             }
            }
        }
        &-row{
        padding-top: 16px;
    background: $base-color-2;
    margin-left: 0;
    margin-right: 0;
    border-radius: 2px;
    border: 1px solid #E0E5EC;
    @include md-min{
        display: flex !important;
        padding-left: 22px;
        padding-right: 22px;
    }
@include md{
padding-left: 12px;
        padding-right: 12px;
}
        }
        &-item{
            @include md{
                margin-top: 2px;
            }
        }
    }
       &__col{
            padding-bottom: 15px;
            @include md-min{
                max-width: 20% !important;
            }
            &--60{
                max-width: 60%;
                flex: 0 0 60%;
            }
        }
}

.collapse-source-img{
    max-height: 25px;
}

.activity-log__table{
        min-width: 885px;
    td p,th p{
        padding-top: 12px;
        padding-bottom: 12px;
            padding-left: 20px;
    padding-right: 15px;
    border-left:1px solid rgba(34, 23, 91, 0.15);
    text-align: right;
    }
    td,th {
        &:first-child{
            p{
                border-left:0;
                text-align: left;
            }
        }
    }
            .toogle-icon{
    transform: rotate(180deg);
    transition: transform 300ms linear;
    
    &-wrapper{
margin-left: 15px;
    width: 12px;
    display: inline-block;
    }
}

    thead{
        th{
            p{
                           font-size: 10px;
letter-spacing: 0.5px;
text-transform: uppercase;
color: $labels-font;
border-left: 0;
            } 
        }
    }
    .collapse-header{
        background-color: $primary-color;
        border-top: 3px solid #fff;
        td{
            p{
                            padding-top: 15px;
        padding-bottom: 15px;
            font-size: 14px;
letter-spacing: 0.7px;
text-transform: uppercase;
color: $font-secondary;
font-weight: bold;
@include xs{
  font-size: 12px;  
}
            }
        }

        &[aria-expanded="true"]{
.toogle-icon{
    transform: rotate(0deg);
}
        }
    }
    .collapse-content{
        td{
            p{
background: #F8F9FA; 
border-bottom: 1px solid #fff;
    padding-top: 11px;
    padding-bottom: 11px;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        @include xs{
  font-size: 10px;  
}
            }
        }
    }
}