//#FONTS
/* Brandon Text */
@font-face {
  font-family: "brandon";
  src: url("../../fonts/brandon/brandontext-regular-webfont.woff2") format("woff2"),
    url("../../fonts/brandon/brandontext-regular-webfont.woff") format("woff"),
    url("../../fonts/brandon/brandontext-regular-webfont.ttf") format("truetype"),
    url("../../fonts/brandon/brandontext-regular-webfont.svg#brandon_text_regularregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "brandon";
  src: url("../../fonts/brandon/brandontext-medium-webfont.woff2") format("woff2"),
    url("../../fonts/brandon/brandontext-medium-webfont.woff") format("woff"),
    url("../../fonts/brandon/brandontext-medium-webfont.ttf") format("truetype"),
    url("../../fonts/brandon/brandontext-medium-webfont.svg#brandon_textmedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "brandon";
  src: url("../../fonts/brandon/brandontext-bold-webfont.woff2") format("woff2"),
    url("../../fonts/brandon/brandontext-bold-webfont.woff") format("woff"),
    url("../../fonts/brandon/brandontext-bold-webfont.ttf") format("truetype"),
    url("../../fonts/brandon/brandontext-bold-webfont.svg#brandon_textbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "brandon";
  src: url("../../fonts/brandon/brandontext-black-webfont.woff2") format("woff2"),
    url("../../fonts/brandon/brandontext-black-webfont.woff") format("woff"),
    url("../../fonts/brandon/brandontext-black-webfont.ttf") format("truetype"),
    url("../../fonts/brandon/brandontext-black-webfont.svg#brandon_textblack") format("svg");
  font-weight: 900;
  font-style: normal;
}

/* UK Plate */

@font-face {
  font-family: "uknumber";
  src: url("../../fonts/uknumber/UKNumberPlate.eot");
  src: url("../../fonts/uknumber/UKNumberPlate.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/uknumber/UKNumberPlate.woff2") format("woff2"),
    url("../../fonts/uknumber/UKNumberPlate.woff") format("woff"),
    url("../../fonts/uknumber/UKNumberPlate.ttf") format("truetype"),
    url("../../fonts/uknumber/UKNumberPlate.svg#UKNumberPlate") format("svg");
  font-weight: normal;
  font-style: normal;
}

//#FONT_CLASSES
.primary-title {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
}

//#FONT SIZES
.main-title {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  @include xs {
    font-size: 25px;
  }
}

.secondary-title {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

//#FONT SIZE 30
.font-size-30 {
  font-size: 30px;
}

//#FONT SIZE 26
.font-size-26 {
  font-size: 26px;

  @include sm {
    font-size: 18px;
  }
}

//#FONT SIZE 24
.font-size-24 {
  font-size: 24px;
}

//#FONT SIZE 22
.font-size-22 {
  font-size: 22px;
}

//#FONT SIZE 20
.font-size-20 {
  font-size: 20px;

  @include xs {
    font-size: 16px;
  }
}

//#FONT SIZE 18
.font-size-18 {
  font-size: 18px;
}

//#FONT SIZE 16
.font-size-16 {
  font-size: 16px;
}

//#FONT SIZE 14
.font-size-14 {
  font-size: 14px;
}

//#FONT SIZE 12
.font-size-12 {
  font-size: 12px;
}

//#FONT SIZE 10
.font-size-10 {
  font-size: 10px;
}

//#FONT WEIGHTS
.font-weight-500 {
  font-weight: 500;
}

//#LINEHEIGHT
.lh-1 {
  line-height: 1;
}

.page-header {
  &__title {
    font-size: 20px;
    letter-spacing: 0.588235px;
  }
}
