.primary-button {
   font-size: 12px;
   letter-spacing: 0.352941px;
   text-transform: uppercase;
   font-weight: bold;
   padding: 10px 16px;
   box-shadow: 0px 6px 10px #e7eaed;
   border-radius: 4px;
   box-shadow: none !important;
   &--light {
      background-color: $base-color-2;
      color: $primary-color;
      &:hover,
      &:active,
      &:focus {
         color: $primary-color;
         background-color: darken($color: $base-color-2, $amount: 5);
      }
   }
   &--dark {
      background-color: $primary-color;
      color: $font-secondary;
      &:hover,
      &:active,
      &:focus {
         color: $font-secondary;
         background-color: darken($color: $primary-color, $amount: 5);
      }
   }
   &--success {
      background-color: #02cd56;
      color: $font-secondary;
      &:hover,
      &:active,
      &:focus {
         color: $font-secondary;
         background-color: darken($color: #02cd56, $amount: 5);
      }
   }
}

.secondary-button {
   font-size: 14px;
   letter-spacing: 0.411765px;
   text-transform: uppercase;
   font-weight: bold;
   border-radius: 3px;
   min-width: 120px;
   padding: 13px 10px;

   &--dark {
      color: $font-secondary !important;
      background-color: $primary-color;
      &:hover,
      &:active,
      &:focus {
         background-color: darken($color: $primary-color, $amount: 5);
      }
   }

   &--light {
      color: #6f7689 !important;
      background: rgba(166, 174, 194, 0.221779);
      &:hover,
      &:active,
      &:focus {
         background-color: rgba(166, 174, 194, 0.421779);
      }
   }
}

@mixin add-icon {
   width: 20px;
   height: 20px;
   background-color: #3ca0ff;
   border-radius: 2px;
   border: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   &:after,
   &:before {
      content: "";
      width: 9px;
      height: 1px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
   }
   &:before {
      transform: rotate(90deg);
   }
}

.add-btn {
   border: 1px solid #d5dff1;
   border-radius: 4px;
   background-color: $base-color-2;
   display: inline-flex;
   align-items: center;
   svg {
      vertical-align: text-bottom;
   }
   &__icon {
      @include add-icon;
   }
   span {
      margin-left: 9px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      @media (max-width: 360px) {
         margin-left: 5px;
      }
   }
   &:nth-child(2) {
      margin-left: 10px;
   }
}
.add-btn-small {
   @include add-icon;
}

.action-btns {
   &__edit {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.411765px;
      text-decoration-line: underline;
      color: $font-primary;
   }
   &__delete {
      border: none;
      background-color: transparent;
   }
}

.pills-tab-menu {
   li {
      width: 100%;
      &:first-child {
         button {
            border-radius: 40px 0 0 40px;
         }
      }
      &:last-child {
         button {
            border-radius: 0 40px 40px 0;
         }
      }
   }
   button {
      width: 100%;
      border: 1px solid #e1e7ef;
      background-color: #f8f9fa;
      font-size: 12px;
      color: $support-font;
      font-weight: bold;
      padding: 12px 35px;
      &.active {
         background-color: $base-color-2;
         box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.0558348);
      }
   }
}

//#PRIMARY LINK
.primary-link {
   padding: 0;
   border: 0;
   background: none;
   border-bottom: 1px solid rgba($color: $support-font, $alpha: 0.5);
   font-size: 12px;
   line-height: 12px;
   @extend .support-color-light;
   &--black {
      color: #11274e !important;
   }
}

.back-link {
   font-size: 14px;
   color: #7285a8;
   font-weight: bold;
   display: inline-flex;
   align-items: center;
   svg {
      position: relative;
      transition: left 300ms linear;
      margin-right: 9px;
      min-width: 8px;
      left: 0;
   }
   @include xs {
      font-size: 12px;
      width: 9px;
   }
   &:hover,
   &:active,
   &:focus {
      color: $font-primary;
      text-decoration: none;
      svg {
         // margin-right: 12px;
         left: -8px;
      }
      &--small {
         font-size: 14px;
         color: #7285a8;
      }
   }
}

//#FLOATING BTN
.floating-btn {
   position: fixed;
   bottom: 30px;
   right: 15px;
   width: 60px;
   height: 60px;
   display: inline-block;
   border-radius: 50%;
   background-color: $primary-color;
   z-index: 1202;
   &__bar {
      display: block;
      width: 24px;
      height: 3px;
      background-color: #fff;
      margin: 4px auto;
      border-radius: 8px;
      transition: transform 400ms linear, opacity 400ms linear;
   }
   &.clicked {
      .floating-btn__bar {
         &:first-child {
            transform: rotate(45deg) translate(4px, 4px);
         }
         &:nth-child(2) {
            transform: translatex(-20px);
            opacity: 0;
         }
         &:last-child {
            transform: rotate(-45deg) translate(6px, -6px);
         }
      }
   }
}

//#SELECT BOX
.month-Filter__btn,
.mileageSelectBox {
   display: flex;
   align-items: center;
   justify-content: space-between;
   &:after {
      border: 0;
      padding: 4px 7px;
      vertical-align: middle;
   }
}
.mileageSelectBox {
   background: linear-gradient(180deg, #ffffff 0%, #f1f3f6 100%);
   border: 1px solid #dfe5eb;
   border-radius: 17px;
   font-size: 16px;
   font-weight: bold;
   padding: 4px 12px 4px 23px;
   width: 120px;
   &:after {
      background: url("../../img/dropdown-icon.svg") no-repeat;
   }
}

.month-Filter {
   &__btn {
      border: 0.8px solid #e1e1e1;
      border-radius: 4px;
      background-color: $base-color-2;
      padding: 7px 13px;
      font-weight: 500;
      font-size: 14px;
      @include xs {
         padding: 7px 7px;
      }
      &:after {
         background: url("../../img/dropdown-icon-solid.svg") no-repeat;
         transform: rotate(180deg);
         transition: transform 300ms linear;
      }

      &[aria-expanded="true"] {
         &:after {
            transform: rotate(0deg);
         }
      }
   }
}

.arrow-btns {
   border-radius: 3px;
   padding: 12px;
   text-transform: uppercase;
   font-weight: bold;
   padding-left: 20px;
   padding-right: 20px;
   &--left {
      color: #6f7689;
      background-color: rgba(166, 174, 194, 0.221779);
      span {
         transition: left 300ms linear;
         position: relative;
      }
      &:hover,
      &:active,
      &:focus {
         color: #6f7689;
         span {
            right: 5px;
         }
      }
   }
   &--right {
      background-color: #02cd56;
      color: #fff;
      span {
         transition: left 300ms linear;
         position: relative;
      }
      &:hover,
      &:active,
      &:focus {
         color: #fff;
         span {
            left: 5px;
         }
      }
   }
}

.graph-type-filter {
   &__btn {
      font-size: 10px;
      color: #8a96ab;
      border-radius: 20px;
      font-weight: 500;
      border: 0;
      padding: 4px 6px;
      min-width: 79px;
      background-color: transparent;
      margin-right: 4px;
      @include xs {
         min-width: 60px;
      }
      &:last-child {
         margin-right: 0;
      }
      &.active,
      &:hover,
      &:focus,
      &:active {
         color: $primary-color;
         background-color: $bg-color;
      }
   }
}

.rounded-dropdown-box {
   .dropdown-menu {
      padding: 0;
      width: 100%;
      min-width: 100%;
      border-radius: 8px;
      margin-top: 8px;
      ul {
         margin: 2px;
         li {
            padding: 4px 20px;
            border-radius: 4px;
            font-weight: 600;
            font-size: 14px;
            margin: 2px 0;
            &:hover,
            &:focus,
            &:active,
            &.active {
               background-color: $primary-color;
               color: $font-secondary;
            }
         }
      }
   }
}

.submit-button {
   font-size: 12px;
   letter-spacing: 0.352941px;
   text-transform: uppercase;
   font-weight: bold;
   padding: 10px 16px;
   box-shadow: 0px 6px 10px #e7eaed;
   border-radius: 4px;
   box-shadow: none !important;
   background-color: #02cd56;
   color: $font-secondary;
   &:hover,
   &:active,
   &:focus {
      color: $font-secondary;
      background-color: darken($color: #02cd56, $amount: 5);
   }
}

.btn-loader {
   position: relative;
   overflow: hidden;
   &:after {
      content: "";
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 2px solid $font-secondary;
      border-top-color: transparent;
      animation: rotate 500ms linear infinite;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
      transition: opacity 300ms linear, color 300ms linear;
      animation: rotate 500ms linear infinite;
   }

   &.loading {
      pointer-events: none;
      color: transparent;
      opacity: 0.8;
      &:after {
         opacity: 1;
      }
   }
}

@keyframes rotate {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

.buy-btn {
   background: #02cd56;
   border-radius: 4px;
   border: 2px solid #02cd56;
   font-weight: 900;
   font-size: 12px;
   letter-spacing: 0.692308px;
   text-transform: uppercase;
   color: $font-secondary;
   padding: 6px 2px;
   width: 60px;
   &:hover,
   &:active,
   &:focus,
   &.active {
      color: $font-secondary;
      border-color: #13a44f;
   }
}

.dotted-button {
   border: 2px dashed $primary-color;
   border-radius: 6px;
   font-size: 16px;
   font-weight: bold;
   text-transform: uppercase;
   color: $primary-color;
   width: 100%;
   background-color: transparent;
   padding: 18px 12px;
   &:hover,
   &:focus,
   &:active {
      background-color: $primary-color;
      color: $font-secondary;
   }
}
