.user-stats{
    padding: 22px 25px;
    @include xs{
        padding-left: 15px;
        padding-right: 15px;
    }
    &__img{
        &-media{
        max-width: 107px;
        height: 107px;
        background-color: rgb(228 228 228);
        border-radius: 50%;
    }
    }
    &__boxes{
        margin-top: 22px;
            max-width: 235px;
    margin-left: auto;
    margin-right: auto;
        &-col{
border: 1px solid #EAEEF2;
text-align: center;
padding-top: 12px;
padding-bottom: 12px;
        }
        &-top-value{
            line-height: 18px;
        }
        &-bottom-value{
                line-height: 20px;
            font-size: 9px;
        }
    }
}