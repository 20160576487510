.error {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: table;
    width: 100%;
    color: $font-primary;

    &-number {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
        &__text {
            font-size: 190px;
            font-weight: bold;
            line-height: 1;
            color: #0064b2;
            @include md {
                font-size: 90px;
            }
        }
        &__object {
            margin: 0 20px;
            position: relative;
            display: flex;
            align-items: center;
        }
    }
    &-wrapper {
        display: table-cell;
        vertical-align: middle;
        padding: 110px 0;
    }
    &-content {
        text-align: center;
        max-width: 412px;
        margin: auto;
    }
    &-title {
        font-weight: bold;
        font-size: 22px;
        &-main {
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 10px;
        }
    }
    &-text {
        font-size: 18px;
        margin-top: 12px;
    }

    &__btn {
        color: $font-secondary !important;
        font-weight: bold;
        font-size: 14px;
        border-radius: 2px;
        background: $primary-color;
        padding: 12px 27px;
        width: 100%;
        max-width: 163px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 25px;
    }
}

.cube-wrapper {
    &:after {
        content: "";
        width: 80px;
        height: 80px;
        background-color: rgba(0, 0, 0, 0.278);
        display: block;
        filter: blur(11px);
        z-index: 0;
        position: absolute;
        bottom: 0;
        @include md {
            width: 60px;
            height: 60px;
        }
    }
    .cube {
        transform-style: preserve-3d;
        width: 80px;
        height: 80px;
        position: relative;
        animation: spin 5s infinite linear;
        z-index: 2;
        @include md {
            width: 60px;
            height: 60px;
        }
    }
    .face {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .top {
        transform: rotateX(90deg) translateZ(40px);
        background: linear-gradient(0deg, #198ee8, #025ba1);
        @include md {
            transform: rotateX(90deg) translateZ(30px);
        }
    }
    .bottom {
        transform: rotateX(-90deg) translateZ(40px);
        background: linear-gradient(0deg, #025ba1, #198ee8);

        @include md {
            transform: rotateX(-90deg) translateZ(30px);
        }
    }

    .right {
        transform: rotateY(90deg) translateZ(40px);
        background: linear-gradient(0deg, #1b8be4, #1b8be4);
        @include md {
            transform: rotateY(90deg) translateZ(30px);
        }
    }
    .left {
        transform: rotateY(-90deg) translateZ(40px);
        background: linear-gradient(0deg, #1b8be4, #1b8be4);
        @include md {
            transform: rotateY(-90deg) translateZ(30px);
        }
    }

    .front {
        transform: rotateX(0deg) translateZ(40px);
        background: linear-gradient(0deg, #005ba1, #005ba1);
        @include md {
            transform: rotateX(0deg) translateZ(30px);
        }
    }
    .back {
        transform: rotateX(-180deg) translateZ(40px);
        background: linear-gradient(0deg, #005ba1, #005ba1);
        @include md {
            transform: rotateX(-180deg) translateZ(30px);
        }
    }

    @keyframes spin {
        from {
            transform: rotateX(0deg) rotateY(0deg);
        }
        to {
            transform: rotateX(360deg) rotateY(360deg);
        }
    }
}
