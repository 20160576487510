.nav-user{
    &-section{
        flex: none;
    }
    &__details{
        &-name{
            font-size: 16px;
            font-weight: 500;
        }
        &-name{
            font-size: 12px;
            font-weight: bold;
        }
    }
    &__logo{
        svg{
                    @include xs{
            max-width: 36px;
        }
        }
    }
}

.nav-brand{
    .site-logo{
        max-width: 125px;
    }
}

/* ====#PRIMARY_NAVBAR==== */
.primary-navbar{
    padding: 26px 0;
    box-shadow: 0px 6px 10px rgba(141, 150, 225, 0.0831512);
     @include sm{
 padding: 10px 0;
    }
}
.primary-nav{
    //#SEARCH INPUT
    &__search{
        background-color: #F8F9FA;
        border-radius: 15px;
    padding: 12px 0;
    }
    //#INPUT
    &__search{
        &-input{
background-color:transparent;
border: 0;
    width: 100%;
    font-weight: bold;
        font-size: 16px;
    letter-spacing: 0.16px;

    &::-webkit-input-placeholder { /* Edge */
  color: #ACAFB5;
}

&:-ms-input-placeholder { /* Internet Explorer */
  color: #ACAFB5;
}

&::placeholder {
  color: #ACAFB5;
}
        }
        &-right-icon,&-icon{
padding: 0 13px;
}
    }

    //#LOGO
    &__logo{
        @include sm{
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
        }
    }
}

.primary-nav__search-wrapper{
width:45%;
padding-left: 15px;
    padding-right: 15px;
}

.primary-nav-spacer{
    height:100px;
    @include sm{
height:61px;
    }
}

//#TOOGLE BTN
    .toogle-btn{
  &__bar{
    display: block;
    width: 22px;
    height: 3px;
    background-color: #233F6F;
    margin: 3px auto;
    border-radius: 24px;
    transition: transform 400ms linear,opacity 400ms linear;
  }
  &.clicked{
     .toogle-btn__bar{
        &:first-child{
           transform: rotate(45deg) translate(4px, 4px);
        }
        &:nth-child(2){
               transform: translatex(-20px);
               opacity: 0;
        }
        &:last-child{
           transform: rotate(-45deg) translate(6px, -6px);
        }
     }
  }
}

.search-dropdown-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: #1D293F, $alpha: 0.7);
    z-index: 2;
}