$colors: (#d13447, #ffbf00, #263672);
@for $i from 0 through 300 {
	$w: random(8);
	$l: random(100);
	.confetti-#{$i} {
		width: #{$w}px;
		height: #{$w * 0.4}px;
		background-color: nth($colors, random(3));
		top: -10%;
		left: unquote($l + "%");
		opacity: random() + 0.5;
		transform: rotate(#{random() * 360}deg);
		animation: drop-#{$i} unquote(4 + random() + "s") unquote(random() + "s") infinite;
	}

	@keyframes drop-#{$i} {
		100% {
			top: 110%;
			left: unquote($l + random(5) + "%");
		}
	}
}

@keyframes slideUp {
	0% {
		transform: translateY(150%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes scaleUp {
	0% {
		transform: scale(0.7);
	}
	45% {
		transform: scale(1.05);
	}
	80% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes dotsLoader {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, 0);
	}
}
